import { bytesToUtf8String } from '@samedi/crypto-js/utils'

import { toByteArray as decode64 } from 'base64-js'

import { SessionKeyCryptor } from 'services/encryption'

import PatientForm, { Attributes } from './PatientForm'

export interface Attachment {
  id: string
  file_name: string
  file_size: number
  file_type: string
}

export interface DecryptedAttributes {
  sessionKey?: Uint8Array
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  formData?: any
  attachments: Attachment[]
}

interface FormDataAsObject {
  [key: string]: string
}

export default class PatientFormDecrypted extends PatientForm {
  private decryptedAttributes: DecryptedAttributes
  private cryptor?: SessionKeyCryptor

  constructor(
    attributes: Attributes,
    decryptedAttributes: DecryptedAttributes,
    cryptor?: SessionKeyCryptor
  ) {
    super(attributes)

    this.decryptedAttributes = decryptedAttributes
    this.cryptor = cryptor
  }

  getDecryptedAttributes(): DecryptedAttributes {
    return this.decryptedAttributes
  }

  getCryptor(): SessionKeyCryptor | undefined {
    return this.cryptor
  }

  getFormDataXmlString(): string {
    const encodedFormData = this.decryptedAttributes.formData.data
    return bytesToUtf8String(decode64(encodedFormData))
  }

  getFormDataAsObject(): FormDataAsObject {
    const xmlObject = new DOMParser().parseFromString(this.getFormDataXmlString(), 'text/xml')
    return this.parseFormDataXmlIntoObject(xmlObject)
  }

  private parseFormDataXmlIntoObject(xmlObject: Document): FormDataAsObject {
    const formDataAsObject: FormDataAsObject = {}

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    Array.from(xmlObject.children[0].children).forEach((section: any) => {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      Array.from(section.children).forEach((control: any) => {
        const controlName = control.tagName.replace(/[-]/g, '').toLowerCase()

        // For now we consider only first level controls - that is enough for our use cases
        // If there are deeper children, we skip
        if (control.children.length > 0) {
          return
        }

        formDataAsObject[controlName] = control.innerHtml || control.textContent
      })
    })

    return formDataAsObject
  }
}
