import React from 'react'

import { CryptorWithKeys } from 'components/features/Cryptor/models/CryptorService'
import { CategoryName } from 'components/features/MedicalRecord/EntriesTable/CategoryName'
import { Description } from 'components/features/MedicalRecord/EntriesTable/Description'
import { EntryDateTime } from 'components/features/MedicalRecord/EntryDateTime'

import { DecryptedMedicalRecordEntryShare } from '../MedicalRecordEntryShare'

interface Props {
  entry: DecryptedMedicalRecordEntryShare
  cryptorWithKeys: CryptorWithKeys
}

export const EntryShareRowMobile: React.FC<Props> = ({ entry, cryptorWithKeys }) => {
  return (
    <div className='c-medical-record-entries__data c-medical-record-entries__data--shared'>
      <div>
        <CategoryName entry={entry.target} isLarge />
        <div className='c-medical-record-entries__date-icon-container c-medical-record-entries__date-icon-container--shared'>
          <EntryDateTime entryDate={entry.created_at} />
        </div>
      </div>
      <div className='c-medical-record-entries__info-container'>
        <div className='h-word-break'>
          <Description entry={entry.target} cryptorWithKeys={cryptorWithKeys} />
        </div>
        <div className='c-medical-record-entries__created-by c-medical-record-entries__created-by--mobile h-word-break'>
          {entry.target.creator_name}
          {entry.target.creator_practice && ` (${entry.target.creator_practice})`}
        </div>
      </div>
    </div>
  )
}
