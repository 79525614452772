import { Cryptor } from '@samedi/crypto-js'

import FileSaver from 'file-saver'

import decryptPatientForm from './decryptPatientForm'
import exportToDocx from './exportToDocx'

import fetchPatientForm from './fetchPatientForm'

export default async function downloadAsDocx(id: string, cryptor: Cryptor) {
  const form = await fetchPatientForm(id)
  const filename = form.getAttributes().title
  const decryptedForm = await decryptPatientForm(form, cryptor)
  const docxBlob = await exportToDocx(decryptedForm)

  FileSaver.saveAs(docxBlob, `${filename}.docx`)
}
