import { faCheck } from '@fortawesome/pro-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import classNames from 'classnames'
import React, { useCallback } from 'react'

import { t } from 'i18n'

interface Props {
  onAcceptEntry?: () => void
  isDisabled?: boolean
}

export const AcceptAction: React.FC<Props> = function ({ onAcceptEntry, isDisabled }) {
  const handleAcceptEntryClick = useCallback(() => {
    !isDisabled && onAcceptEntry && onAcceptEntry()
  }, [isDisabled, onAcceptEntry])

  return (
    <button
      onClick={handleAcceptEntryClick}
      className={classNames('s-button h-margin-right--8', { 's-button--disabled': isDisabled })}
      type='button'
    >
      <span className='h-margin-right--8'>
        <FontAwesomeIcon icon={faCheck} />
      </span>
      <span>{t('MEDICAL_RECORD/RECEIVED_ENTRIES/ACCEPT')}</span>
    </button>
  )
}
