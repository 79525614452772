import { faDownload } from '@fortawesome/pro-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import * as React from 'react'

import { humanFileSize } from 'util/humanFileSize'

export function DownloadAttachmentLink(props: {
  name: string
  size: number
  onDownloadClick?: () => void
}): JSX.Element {
  const { name, size, onDownloadClick } = props

  function handleClick(e: React.MouseEvent) {
    e.preventDefault()
    e.stopPropagation()
    onDownloadClick && onDownloadClick()
  }

  const fileSizeSuffix = size ? ` (${humanFileSize(size)})` : ''

  return (
    // eslint-disable-next-line react/jsx-no-bind
    <a className='o-clickable' onClick={(e) => handleClick(e as unknown as React.MouseEvent)}>
      <FontAwesomeIcon icon={faDownload} /> {name}
      {fileSizeSuffix}
    </a>
  )
}
