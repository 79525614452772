import { faUserDoctor } from '@fortawesome/pro-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React from 'react'

import { t } from 'i18n'

export const NoContacts: React.FC = () => (
  <div data-testid='homescreen-recent-contacts-no-contacts' className='p-4'>
    <div className='mb-4 text-center font-semibold text-grey-700'>
      {t('homescreen.recent_contacts.empty.description')}
    </div>
    <a
      className='flex justify-center rounded-md bg-light-blue py-2.5 font-bold leading-5 text-primary-600 hover:bg-light-blue-darker active:bg-light-blue-darker active:opacity-30'
      href='/searches'
      data-testid='homescreen-recent-contacts-search-new'
      title={t('homescreen.recent_contacts.empty.link')}
    >
      <FontAwesomeIcon className='h-5 w-5 pr-2' icon={faUserDoctor} />
      {t('homescreen.recent_contacts.empty.link')}
    </a>
  </div>
)
