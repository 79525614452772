/* eslint-disable react/forbid-dom-props */
/* eslint-disable react/jsx-no-bind */
import { Formik, Form } from 'formik'
import React, { useState } from 'react'

import loadFile, { FileResult } from 'components/services/loadFile'
import { FileField } from 'components/shared/FileField'
import { t } from 'i18n'

import {
  fileUploadSizeLimit,
  fileUploadSizeLimitTranslation,
  fileUploadSizeExceededTranslation
} from 'services/medicalRecordEntry/fileUploadSizeLimit'
import { trackEvent } from 'services/tracker'

import { FormButtons } from './FormButtons'

import { FileValues } from './'

interface Props {
  onSubmit: (fileEntry: FileValues) => void
  onCancel: () => void
}

interface FileFormValues {
  description: string
  file?: File
}

async function loadFileAndMetaData(values: FileFormValues): Promise<FileValues> {
  const defaultFileResult = { fileName: '', fileSize: '0', fileData: '' }
  const fileResult: FileResult = values.file ? await loadFile(values.file) : defaultFileResult

  return {
    formType: 'file',
    description: values.description,
    fileName: fileResult.fileName,
    fileSize: parseInt(fileResult.fileSize),
    fileData: fileResult.fileData,
    file: values.file || new File([], fileResult.fileName)
  }
}

export default function FileForm({ onSubmit, onCancel }: Props) {
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false)
  const initialValues = {
    description: ''
  }

  const formValidator = (values: FileFormValues) => {
    const fileSizeLimit = fileUploadSizeLimit()
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const errors: any = {}
    if (!values.file) {
      errors.file = t('formik.errors.file_missing')
    } else if (values.file.size > fileSizeLimit) {
      errors.file = fileUploadSizeExceededTranslation()
      trackEvent(
        'MHR',
        'add new file save - failed too big',
        values?.file?.type,
        values?.file?.size
      )
    }
    return errors
  }

  const handleSubmit = async (values: FileFormValues) => {
    setIsSubmitting(true)
    const fileAndMetaData: FileValues = await loadFileAndMetaData(values)
    onSubmit(fileAndMetaData)
    trackEvent('MHR', 'add new file save', values?.file?.type, fileAndMetaData.fileSize)
  }

  return (
    <Formik validate={formValidator} initialValues={initialValues} onSubmit={handleSubmit}>
      {({ setFieldValue, errors }) => (
        <Form className='h-margin-bottom--32'>
          <input type='hidden' name='form-type' value='file' />
          <div className='s-form-group s-form-group--with-columns s-form-group--full-screen'>
            <div
              className={`s-form-group__column s-form-group__column--fluid-40 ${
                errors.file ? 'error' : ''
              }`}
            >
              <FileField
                name='file'
                onChange={(e) => {
                  setFieldValue('file', e.currentTarget?.files && e.currentTarget.files[0])
                }}
              />
              {errors.file && <span className='help-block'>{errors.file}</span>}
              <div className='o-form-field-note'>{fileUploadSizeLimitTranslation()}</div>
            </div>
            <div className='s-form-group__column s-form-group__column--fluid-40'>
              <label htmlFor='file-form__description' className='o-form-label'>
                {t('medical_record.file_description')}
              </label>
              <input
                id='file-form__description'
                name='description'
                className='o-text-field'
                onChange={(e) => setFieldValue('description', e.currentTarget.value)}
              />
            </div>
          </div>
          <FormButtons
            onCancel={onCancel}
            disableSaving={isSubmitting || errors.file !== undefined}
          />
        </Form>
      )}
    </Formik>
  )
}
