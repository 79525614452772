import React from 'react'
import { CellProps } from 'react-table'

import { DecryptedMessageSummaryAttributes } from '../../models/Message'

export const CellFromTo: React.FC<CellProps<DecryptedMessageSummaryAttributes, number>> = function (
  props
) {
  const { cell } = props
  return (
    <div className='f-messages__cell f-messages__from-to-cell'>
      <div className='f-messages__overflow-guard h-word-break'>{cell.value}</div>
    </div>
  )
}
