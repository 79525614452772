import axios from 'axios'

import { PublicKey } from '.'

export default async function fetchPracticePublicKey(id: string): Promise<PublicKey> {
  const response = await axios.get(`/practices/${id}.json`)
  const publicKey: PublicKey = response.data.key_pair

  return publicKey
}
