import classNames from 'classnames'
import React from 'react'

import { t } from 'i18n'

import { DecryptedMedicalRecordEntry } from '../MedicalRecordEntry'

interface Props {
  entry: DecryptedMedicalRecordEntry
  institutionsCount: number
  onSendEntry: (entry: DecryptedMedicalRecordEntry) => void
}

export const ShareDetails: React.FC<Props> = function ({ entry, institutionsCount, onSendEntry }) {
  const sharedWithCount = entry.sent_to_institutions.length + (entry.creator_practice ? 1 : 0)

  return (
    <div>
      {sharedWithCount === 1 ? (
        <span dangerouslySetInnerHTML={{ __html: t('MEDICAL_RECORD/SHARE_DETAILS_SINGULAR') }} />
      ) : (
        <span
          dangerouslySetInnerHTML={{ __html: t('MEDICAL_RECORD/SHARE_DETAILS', sharedWithCount) }}
        />
      )}
      <ul className='c-medical-record__expanded-row-shared-with-container'>
        {entry.creator_practice && (
          <li className='c-medical-record__expanded-row-shared-with'>{entry.creator_name}</li>
        )}
        {entry.sent_to_institutions.map((institution: { id: string; name: string }) => (
          <li className='c-medical-record__expanded-row-shared-with' key={institution.id}>
            {institution.name}
          </li>
        ))}
      </ul>
      {entry.session_key && sharedWithCount < institutionsCount ? (
        <button
          className={classNames('s-button c-medical-record__expanded-row-share-btn', {
            's-button--disabled': entry.data_undecryptable
          })}
          // eslint-disable-next-line react/jsx-no-bind
          onClick={() => !entry.data_undecryptable && onSendEntry(entry)}
        >
          {sharedWithCount === 0
            ? t('MEDICAL_RECORD/SEND_TO_INSTITUTION_BUTTON')
            : t('MEDICAL_RECORD/SEND_TO_MORE_INSTITUTION_BUTTON')}
        </button>
      ) : null}
    </div>
  )
}
