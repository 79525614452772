import { sha256digest, verifyBuffer } from './subtle/signing'
import { importSigningPublicKey } from './subtle/signingKeyHandling'

export interface VerifyResult {
  keyId: string
  signedBy: string
  signedAt: Date
}

// Callback to fetch an encoded public key for signature verification.
export type GetPublicKey = (keyId: string) => Promise<string>

/**
 * Verify JWS signatures that are generated with Signer.
 */
export class Verifier {
  private keyFetcher: GetPublicKey

  private async getKey(keyId?: string): Promise<CryptoKey> {
    if (!keyId) {
      throw new Error('undefined key')
    }

    const encodedKey = await this.keyFetcher(keyId)
    const decodedKey = await importSigningPublicKey(encodedKey)

    return decodedKey
  }

  /**
   * Verify JWS signatures.
   *
   * @param keyFetcher Callback to fetch the required public key for signature verification
   */
  constructor(keyFetcher: GetPublicKey) {
    this.keyFetcher = keyFetcher
  }

  /**
   * Verify that the passed JWS signature is valid and has a payload that is a SHA-256 hash of encryptedFormData
   *
   * @param jws The signature that should be verified
   * @param encryptedFormData Data that was signed. The payload of the JWS signature must have a hash that matches this data.
   */
  public async verifySignature(jws: string, encryptedFormData: string): Promise<VerifyResult> {
    const dataDigest = new Uint8Array(await sha256digest(encryptedFormData))
    const verifyResult = await verifyBuffer(jws, (h) => this.getKey(h.kid))
    const payload = verifyResult.payload

    if (
      payload.length !== dataDigest.length ||
      !verifyResult.payload.every((v, i) => dataDigest[i] === v)
    ) {
      throw new Error('payload hash mismatch')
    }

    const name = verifyResult.protectedHeader.iss
    if (name !== '') {
      if (!name || typeof name !== 'string') {
        throw new Error('name is not present')
      }
    }

    const issuedAtUnix = verifyResult.protectedHeader.iat
    if (!issuedAtUnix || typeof issuedAtUnix !== 'number') {
      throw new Error('issued at is not present')
    }
    const issuedAt: Date = new Date(issuedAtUnix * 1000)

    const keyId = verifyResult.protectedHeader.kid
    if (!keyId) {
      throw new Error('key id is not present')
    }

    return { keyId: keyId, signedBy: name, signedAt: issuedAt }
  }
}
