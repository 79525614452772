import * as React from 'react'

import { useEffect, useState } from 'react'

import { PublicKey } from 'components/features/MedicalRecord/MedicalRecordEntry'
import { t } from 'i18n'

import { ShareSuccess } from './InstitutionSelector'

export interface ContactInstitution {
  city?: string
  descriptive_field?: string
  email?: string
  fax?: string
  id: number
  key_pair: PublicKey
  latitude?: number
  longitude?: number
  name: string
  opening_hours?: string
  phone?: string
  practice_id: string
  street?: string
  zip?: string
}

interface Props {
  institution: ContactInstitution
  isDisabled: boolean
  entryId: string | number
  shareSuccess: ShareSuccess
  onSendButtonClick: (
    institutionId: number,
    institutionPublicKey: PublicKey,
    entryId: string | number
  ) => void
}

export const InstitutionSelectorItem: React.FC<Props> = function ({
  institution,
  isDisabled,
  entryId,
  shareSuccess,
  onSendButtonClick
}) {
  const [isSharing, setIsSharing] = useState<boolean>(false)
  let buttonText = t('MEDICAL_RECORD/SHARING_WITH_SHARE')
  if (isSharing) {
    buttonText = t('MEDICAL_RECORD/SHARING_WITH_SHARING')
  } else if (isDisabled) {
    buttonText = t('MEDICAL_RECORD/SHARING_WITH_SHARED')
  }
  const handleSendButtonClick = React.useCallback(() => {
    setIsSharing(true)
    onSendButtonClick(institution.id, institution.key_pair, entryId)
  }, [entryId, institution, onSendButtonClick])

  useEffect(() => {
    if (isSharing && !shareSuccess.isSharing) {
      setIsSharing(false)
    }
  }, [shareSuccess, isSharing])

  return (
    <div className='c-send-entry-modal__institution-selector-item'>
      <div className='c-send-entry-modal__institution h-word-break h-margin-right--8'>
        {institution.name}
      </div>
      <button
        className='s-button s-button--secondary'
        disabled={isDisabled || isSharing}
        title={isDisabled ? t('MEDICAL_RECORD/SEND_ENTRY_MODAL/ALREADY_SENT') : undefined}
        onClick={handleSendButtonClick}
      >
        {buttonText}
      </button>
      {shareSuccess.isSharing && <div className='c-send-entry-modal__share-disable'></div>}
    </div>
  )
}
