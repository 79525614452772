/* eslint-disable no-restricted-globals */

import { Cryptor } from '@samedi/crypto-js'
import { utf8StringToBytes } from '@samedi/crypto-js/utils'

import ChunkedFileUploader from './ChunkedFileUploader'
import readerToStream from './readerToStream'

function readFromFileReader(file: File) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader()
    reader.onload = function (evt: ProgressEvent<FileReader>) {
      if (evt.target) {
        resolve(evt.target.result)
      } else {
        reject(new Error('Error trying to read from FileReader'))
      }
    }
    reader.readAsArrayBuffer(file)
  })
}

async function getReadableStream(file: File) {
  let reader
  const body = new Response(file).body

  if (body) {
    reader = body.getReader()
  } else {
    // Needed for IE11
    const fileContent = await readFromFileReader(file)
    reader = {
      cancel: () => Promise.resolve(),
      releaseLock: () => {},
      read: async () => {
        return {
          done: true,
          value: fileContent
        }
      }
    } as any // eslint-disable-line @typescript-eslint/no-explicit-any
  }

  return readerToStream(reader, { upload: true })
}

function encryptText(text: string, encryptionKeyId: string, cryptor: Cryptor) {
  return cryptor.symmetricEncryptBuffer(utf8StringToBytes(text), encryptionKeyId)
}

function chunkedFileUploaderFactory(file: File, percentageCallback?: (arg0: number) => void) {
  const estimatedEncryptedTotalFileSize = file.size * (1 + 0.25)

  let percentage = 0

  return new ChunkedFileUploader((encryptedChunkSize, chunkPosition) => {
    const totalChunks = estimatedEncryptedTotalFileSize / encryptedChunkSize / 10
    const currentPercentage = (chunkPosition / totalChunks) * 100

    if (currentPercentage > percentage && percentageCallback) {
      percentage = Math.round((chunkPosition / totalChunks) * 100)
      percentage > 100 ? percentageCallback(100) : percentageCallback(percentage)
    }
  })
}

export default async function uploadThroughEncryptedStream(
  file: File,
  encryptionKeyId: string,
  cryptor: Cryptor,
  percentageCallback?: (arg0: number) => void
) {
  const metaData = {
    file_name: file.name,
    file_size: file.size,
    file_type: file.type
  }
  const encryptedMetaData = await encryptText(JSON.stringify(metaData), encryptionKeyId, cryptor)
  const encryptedFileName = await encryptText(file.name, encryptionKeyId, cryptor)

  const chunkedFileUploader = chunkedFileUploaderFactory(file, percentageCallback)

  const encryptStream = cryptor.createSymmetricEncryptStream(
    encryptionKeyId,
    chunkedFileUploader.storeChunk
  )

  const readableStream = await getReadableStream(file)

  await readableStream.pipeTo(encryptStream)
  await chunkedFileUploader.finalize()

  return chunkedFileUploader.createFile(encryptedFileName, encryptedMetaData)
}
