import { CryptorWithKeys } from 'components/features/Cryptor/models/CryptorService'

import { decryptFileNames, sanitize } from 'components/features/Messaging/models/Message'

import { Message, EncryptedMessage } from '../types'

// eslint-disable-next-line @typescript-eslint/no-explicit-any
async function decryptString(encrypted_string: string, cryptor: CryptorWithKeys): Promise<any> {
  return await cryptor.cryptor.decrypt(encrypted_string).then((value) => {
    return value
  })
}

export async function decryptMessage(
  message: EncryptedMessage,
  cryptor: CryptorWithKeys
): Promise<Message> {
  try {
    let subject: string | undefined = '',
      body = ''
    if (message.encrypted_subject) {
      subject = await decryptString(message.encrypted_subject, cryptor)
    } else {
      subject = message.subject
    }

    if (message.encrypted_body) {
      const decryptedBody = await decryptString(message.encrypted_body, cryptor)
      body = sanitize(decryptedBody)
    } else {
      body = message.body && sanitize(message.body)
    }

    return {
      ...message,
      subject,
      body,
      files: message.files && (await decryptFileNames(message.files, cryptor))
    }
  } catch (error) {
    console.error(error)
    return message as unknown as Message
  }
}
