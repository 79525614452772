import { Cryptor } from '@samedi/crypto-js'
import axios from 'axios'

export default function readChunkedEncryptedFileFromURLs(
  urls: string[],
  cryptor: Cryptor
): ReadableStream<Uint8Array> {
  const stream = new ReadableStream<string>(
    {
      async pull(controller) {
        const url = urls.shift()

        if (!url) {
          controller.close()
          return
        }

        const response = await axios.get<{ data: string }>(url)
        controller.enqueue(response.data.data)
      }
    },
    new CountQueuingStrategy({ highWaterMark: 1 })
  )

  return cryptor.decryptStreamToBinary(stream)
}
