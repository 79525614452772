import { faMagnifyingGlass } from '@fortawesome/pro-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React from 'react'

import { t } from 'i18n'

import { RecentContacts } from '../RecentContacts'

export const EmptyState = () => (
  <div className='mx-auto mt-12 max-w-xl' data-testid='homescreen-welcome-empty-profile'>
    <div className='overflow-hidden rounded-lg bg-white shadow-sm'>
      <div className='mb-4 mt-6 px-4'>
        <h3 className='mb-2 text-h4 font-semibold text-grey-900'>
          {t('homescreen.empty.welcome.header')}
        </h3>
        <p className='mt-0 whitespace-pre-line text-h5'>{t('homescreen.empty.welcome.message')}</p>
      </div>
      <a
        className='flex justify-center bg-primary-600 py-4 font-bold leading-5 text-white hover:bg-navy-blue active:opacity-30'
        href='/searches'
        data-testid='homescreen-welcome-search-new'
        title={t('homescreen.empty.welcome.cta')}
      >
        <FontAwesomeIcon className='h-5 w-6 pr-2' icon={faMagnifyingGlass} />
        {t('homescreen.empty.welcome.cta')}
      </a>
    </div>
    <div className='mt-6'>
      <RecentContacts contacts={[]} />
    </div>
  </div>
)
