import { Cryptor } from '@samedi/crypto-js'
import axios from 'axios'

import { PublicKey } from '.'

/**
 * Generates a new encryption key with usage 'sharing' on platform
 *
 * @param {Cryptor} cryptor - initialized cryptor
 * @param {Object} patientPublicKey - public key of the patient
 *
 * @returns <Promise<number>> resolves to the id of created sharing key
 */
export default async function generateSharedEncryptionKey(
  cryptor: Cryptor,
  patientPublicKey: PublicKey
): Promise<string> {
  const keyTempId = await cryptor.generateEncryptionKey()
  // eslint-disable-next-line @typescript-eslint/no-use-before-define
  const keyData = await encryptKeyDataWithPublicKey(cryptor, keyTempId, patientPublicKey)
  // eslint-disable-next-line @typescript-eslint/no-use-before-define
  const savedKey = await saveEncryptionKey(keyData)

  await cryptor.addEncryptionKey(savedKey.id, savedKey.data)

  return savedKey.id
}

function encryptKeyDataWithPublicKey(
  cryptor: Cryptor,
  keyId: string,
  publicKey: any // eslint-disable-line @typescript-eslint/no-explicit-any
): Promise<string> {
  return cryptor.exportEncryptionKey(
    keyId,
    publicKey.public_exponent,
    publicKey.modulus,
    publicKey.id
  )
}

async function saveEncryptionKey(keyData: string) {
  const response = await axios.post('/encryption_keys.json', { encryption_key: { data: keyData } })

  return response.data
}
