import { IconProp } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React from 'react'

export type LinkToAllProps = {
  href: string
  title: string
  text: string
  icon: IconProp
  testID?: string
}

const LinkToAll: React.FC<LinkToAllProps> = ({ href, title, icon, text, testID }) => {
  return (
    <a
      className='flex items-center justify-center py-4 font-bold text-primary-600 hover:bg-light-blue hover:text-primary-600 active:bg-primary-600 active:text-white'
      href={href}
      data-testid={testID}
      title={title}
    >
      <FontAwesomeIcon className='h-6 w-6 pr-2' icon={icon} />
      {text}
    </a>
  )
}

export default LinkToAll
