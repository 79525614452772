import React, { useState, useEffect } from 'react'

import { NotificationBox } from '../NotificationBox'

interface Props {
  title: string
  hasInlineButton?: boolean
  flashMessageType?: string
  flashMessage?: string
  children: React.ReactNode
}

export const DashboardContainer: React.FC<Props> = ({
  title,
  hasInlineButton,
  flashMessageType,
  flashMessage,
  children
}) => {
  const [showNotificationBox, setShowNotificationBox] = useState<boolean>(false)

  useEffect(() => {
    flashMessage && flashMessageType ? setShowNotificationBox(true) : setShowNotificationBox(false)
  }, [flashMessage, flashMessageType])

  return (
    <div>
      {showNotificationBox && (
        <NotificationBox
          type={flashMessageType as string}
          message={flashMessage as string}
          // eslint-disable-next-line react/jsx-no-bind
          onClose={() => setShowNotificationBox(false)}
        />
      )}
      {hasInlineButton ? (
        <h2 className='h2--default-margin f-dashboard__header-title'>
          {title}
          {children}
        </h2>
      ) : (
        <>
          <h2 className='h2--default-margin f-dashboard__header-title'>{title}</h2>
          {children}
        </>
      )}
    </div>
  )
}
