import { CryptorWithKeys } from 'components/features/Cryptor/models/CryptorService'
import { FileValues } from 'components/features/MedicalRecord/EntryForm/index'
import { encryptAndSaveMedicalRecordTypeFile } from 'components/features/MedicalRecord/services/encryptAndSaveMedicalRecord'
import blobToB64 from 'services/blobToB64'

import decryptPatientForm from './decryptPatientForm'
import exportToDocx from './exportToDocx'
import fetchPatientForm from './fetchPatientForm'

export default async function addToMedicalRecordAsDocx(
  id: string,
  cryptorWithKeys: CryptorWithKeys,
  encryptionKeyId: string
) {
  const form = await fetchPatientForm(id)
  const formTitle = form.getAttributes().title
  const fileName = `${formTitle}.docx`
  const decryptedForm = await decryptPatientForm(form, cryptorWithKeys.cryptor)
  const docxBlob = await exportToDocx(decryptedForm)
  const base64Data = await blobToB64(docxBlob)
  const entry: FileValues = {
    formType: 'file',
    description: form.getAttributes().title,
    fileName: fileName,
    fileSize: docxBlob.size,
    fileData: base64Data,
    file: new File([docxBlob], fileName)
  }
  await encryptAndSaveMedicalRecordTypeFile(entry, encryptionKeyId, cryptorWithKeys)
}
