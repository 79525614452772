/* eslint-disable react/destructuring-assignment */
import React, { useState } from 'react'

import { t } from 'i18n'

interface FileFieldProps {
  name: string
  onChange?: (e: React.FormEvent<HTMLInputElement>) => void
}

export function FileField(props: FileFieldProps) {
  const { name } = props
  const [fileNames, setFileNames] = useState<string>('')
  const handleFileChange = (e: React.FormEvent<HTMLInputElement>) => {
    const files = e.currentTarget?.files
    const filesArray = Array.from(files || [])
    setFileNames(filesArray.map((f) => f.name).join(', '))
    props.onChange && props.onChange(e)
  }

  return (
    <div className='s-file-field'>
      <label className='s-button s-file-field__button'>
        {t('select_file')}
        <input
          name={name}
          type='file'
          className='s-file-field__input'
          // eslint-disable-next-line react/jsx-no-bind
          onChange={handleFileChange}
        />
      </label>
      <div className='s-file-field__names'>{fileNames}</div>
    </div>
  )
}
