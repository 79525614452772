import React from 'react'
import { Link } from 'react-router-dom'
import { CellProps } from 'react-table'

import { DecryptedMessageSummaryAttributes } from '../../models/Message'

export const CellSubject: React.FC<
  { folder: string } & CellProps<DecryptedMessageSummaryAttributes, string>
> = function (props) {
  const { cell, row, folder } = props
  return (
    <div className='f-messages__cell f-messages__subject-cell'>
      <div className='f-messages__sender-within-subject f-messages__overflow-guard h-word-break'>
        {folder === 'inbox' ? row.original.sender_name : row.original.receiving_practice_name}
      </div>
      <Link
        to={`/messages/${row.original.id}`}
        className='f-messages__clickable f-messages__overflow-guard h-word-break'
      >
        {cell.value}
      </Link>
    </div>
  )
}
