import React, { useCallback, useState } from 'react'

import { ConfirmationModal } from 'components/features/ConfirmationModal'
import { t } from 'i18n'

type Props = {
  entryId: string | number
  onConfirm: (entryId: string | number) => Promise<void>
  onCancel: () => void
}

export const DeleteEntryModal: React.FC<Props> = (props) => {
  const { onConfirm, onCancel, entryId } = props
  const [isDeletionInProgress, setIsDeletionInProgress] = useState<boolean>(false)
  const [isDeleteConfirmed, confirmDelete] = useState<boolean>(false)

  const onCheckboxChange = useCallback((e: React.ChangeEvent<HTMLInputElement>): void => {
    confirmDelete(e.target.checked)
  }, [])

  const onDeleteConfirm = useCallback((): void => {
    setIsDeletionInProgress(true)
    onConfirm(entryId)
  }, [entryId, onConfirm])

  return (
    <ConfirmationModal
      onCancel={onCancel}
      onConfirm={onDeleteConfirm}
      isConfirmButtonDisabled={!isDeleteConfirmed || isDeletionInProgress}
      confirmButtonLabel={t('confirmation_modal.delete')}
    >
      <>
        <div>{t('confirmation_modal.medical_record.delete.notice')}</div>
        <div className='t-checkbox-with-inline-label'>
          <label className='checkbox'>
            <input
              type='checkbox'
              onChange={onCheckboxChange}
              data-testid='c-medical-record-delete-entry-modal__checkbox'
              checked={isDeleteConfirmed}
            />
            {t('confirmation_modal.medical_record.delete.checkbox_label')}
          </label>
        </div>
        <div className='h-margin-bottom--24'>
          {t('confirmation_modal.medical_record.delete.disclaimer')}
        </div>
      </>
    </ConfirmationModal>
  )
}
