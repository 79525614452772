import { faChevronUp } from '@fortawesome/pro-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { useEffect, useState } from 'react'

import { CryptorWithKeys } from 'components/features/Cryptor/models/CryptorService'
import { t } from 'i18n'

import { EntryDateTime } from '../EntryDateTime'
import { DecryptedMedicalRecordEntry } from '../MedicalRecordEntry'
import { ContactInstitution } from '../SendEntryModal/InstitutionSelectorItem'
import { fetch as fetchContactPractices } from '../services/SendEntryModal'

import { CategoryIcon, CategoryText } from './CategoryName'
import { DeleteIcon } from './DeleteIcon'
import { Description } from './Description'
import { RowKeyValue, RowKeyValueData } from './RowKeyValue'
import { ShareDetails } from './ShareDetails'
import { ShareIcon } from './ShareIcon'

interface Props {
  entry: DecryptedMedicalRecordEntry
  onSendEntry: (entry: DecryptedMedicalRecordEntry) => void
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  toggleExpanded: () => any
  cryptorWithKeys: CryptorWithKeys
  onDeleteEntry: (entryId: string | number) => void
}

export function ExpandedRow({
  entry,
  onSendEntry,
  toggleExpanded,
  cryptorWithKeys,
  onDeleteEntry
}: Props) {
  const [contactInstitutionsCount, setContactInstitutionsCount] = useState<number>(0)
  const data: RowKeyValueData[] = [
    {
      key: t('MEDICAL_RECORD/DATE_ADDED_TO_ENTRY'),
      value: <EntryDateTime entryDate={entry.created_at} />
    },
    {
      key: t('MEDICAL_RECORD/MEASUREMENT_OF_DATE'),
      value: <EntryDateTime entryDate={entry.entry_date} entryTime={entry.entry_time as string} />
    },
    {
      key: t('MEDICAL_RECORD/ADDED_BY'),
      value: !entry.created_by_doctor ? t('MEDICAL_RECORD/ME') : entry.creator_name
    },
    {
      key: t('MEDICAL_RECORD/SHARE_STATUS_FOR_ENTRY'),
      value: (
        <ShareDetails
          entry={entry}
          institutionsCount={contactInstitutionsCount}
          onSendEntry={onSendEntry}
        />
      )
    }
  ]

  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/no-use-before-define
    loadContactInstitutions()
  }, [])

  const loadContactInstitutions = async () => {
    const data: ContactInstitution[] = await fetchContactPractices()
    setContactInstitutionsCount(data.length)
  }

  return (
    <div className='c-medical-record-entries__expanded-row-container'>
      <div className='c-medical-record-entries__expanded-row-action-icons'>
        <ShareIcon entry={entry} onSendEntry={onSendEntry} />
        <span
          // eslint-disable-next-line react/jsx-no-bind
          onClick={() => toggleExpanded()}
          className='o-clickable  c-medical-record-entries__icon c-medical-record-entries__expand-collapse-icon'
        >
          <FontAwesomeIcon icon={faChevronUp} />
        </span>
        <DeleteIcon entryId={entry.id} onDeleteEntry={onDeleteEntry} />
      </div>
      <header className='c-medical-record-entries__expanded-row-header'>
        <h4 className='c-medical-record-entries__category-icon-container'>
          <CategoryIcon entry={entry} />
        </h4>
        <div className='c-medical-record-entries__category-description-container h-word-break'>
          <h4>
            <CategoryText entry={entry} />
          </h4>
          <h5 className='h5--default-margin h5--alternative'>
            <Description entry={entry} cryptorWithKeys={cryptorWithKeys} />
          </h5>
        </div>
      </header>
      <RowKeyValue data={data} />
    </div>
  )
}
