import { faCircleExclamation } from '@fortawesome/pro-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React from 'react'

import Modal from 'components/shared/Modal/Modal'
import { t } from 'i18n'

export type EmailUnverifiedProps = { email: string; onClose: () => void }

export const EmailUnverified: React.FC<EmailUnverifiedProps> = ({ email, onClose }) => {
  return (
    <Modal open onClose={onClose} data-testid='2fa-email-unverified'>
      <Modal.Title>
        <FontAwesomeIcon icon={faCircleExclamation} className='h-5 w-5 pr-2 text-error-700' />
        <span className='text-error-700'>{t('settings.2fa.not_verified_modal.title')}</span>
      </Modal.Title>
      <Modal.Body>
        <p
          className='mt-2 whitespace-pre-line'
          dangerouslySetInnerHTML={{
            __html: t('settings.2fa.not_verified_modal.content', email)
          }}
        />
      </Modal.Body>
      <div className='mt-4 flex flex-col border-t border-grey-200 px-3 pt-4 text-center'>
        <a
          data-testid='resend-email-verification'
          className='w-full rounded-lg bg-primary-600 py-3.5 font-bold leading-5 text-white hover:bg-secondary-500 active:bg-navy-blue'
          href='/user/activate'
        >
          {t('settings.2fa.not_verified_modal.send_link_again')}
        </a>
        <a
          data-testid='redirect-to-user-settings'
          className='mt-2 w-full rounded-lg bg-light-blue py-3.5 font-bold leading-5 text-primary-600 hover:bg-light-blue-darker active:bg-secondary-500'
          href='/settings/user_data'
        >
          {t('settings.2fa.not_verified_modal.change_email')}
        </a>
        <Modal.PlainButton onClick={onClose}>
          {t('settings.2fa.not_verified_modal.cancel')}
        </Modal.PlainButton>
      </div>
    </Modal>
  )
}
