import { Cryptor } from '@samedi/crypto-js'

import {
  MedicalRecordEntry,
  DecryptedMedicalRecordEntry,
  decryptMedicalRecordEntry
} from '../MedicalRecordEntry'

export interface MedicalRecordEntryShare {
  id: string | number
  created_at: string
  target: MedicalRecordEntry
}

export interface DecryptedMedicalRecordEntryShare extends MedicalRecordEntryShare {
  target: DecryptedMedicalRecordEntry
}

export async function decryptEntryShare(
  share: MedicalRecordEntryShare,
  cryptor: Cryptor
): Promise<DecryptedMedicalRecordEntryShare> {
  return {
    id: share.id,
    created_at: share.created_at,
    target: await decryptMedicalRecordEntry(share.target, cryptor)
  }
}
