import axios from 'axios'

import { ContactInstitution } from '../SendEntryModal/InstitutionSelectorItem'

/**
 * Fetches contact institutions
 */
export async function fetch(): Promise<ContactInstitution[]> {
  const response = await axios.get<ContactInstitution[]>('/medical_record/contact_practices.json')
  return response.data
}
