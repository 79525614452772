import axios, { AxiosResponse } from 'axios'

import { CryptorWithKeys } from 'components/features/Cryptor/models/CryptorService'

import {
  ContactWithEncryptedMessage,
  ContactWithMessage,
  Message,
  EncryptedMessage
} from '../types'

import { decryptMessage } from './decryptMessage'

const decryptContactMessages =
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  (cryptor: CryptorWithKeys) => (response: AxiosResponse<ContactWithEncryptedMessage[], any>) =>
    Promise.all(
      response.data.map(async (contact) => ({
        ...contact,
        latest_message:
          contact?.latest_message && (await decryptMessage(contact?.latest_message, cryptor))
      }))
    )

const decryptMessages =
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  (cryptor: CryptorWithKeys) => (response: AxiosResponse<EncryptedMessage[], any>) =>
    Promise.all(response.data.map((msg) => decryptMessage(msg, cryptor)))

export const fetchContacts = (cryptor: CryptorWithKeys) => (): Promise<ContactWithMessage[]> =>
  axios
    .get<ContactWithEncryptedMessage[]>(`/api/messages/v3/contacts`)
    .then(decryptContactMessages(cryptor))

export const fetchContactMessages =
  (cryptor: CryptorWithKeys, practice_id?: string) => (): Promise<Message[]> =>
    axios
      .get<EncryptedMessage[]>(`/api/messages/v3/contacts/${practice_id}/messages`)
      .then(decryptMessages(cryptor))
