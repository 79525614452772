/* eslint-disable @typescript-eslint/no-use-before-define */
import { faDownload, faFilePdf, faImage, faPaperclip } from '@fortawesome/pro-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { useContext } from 'react'
import styled from 'styled-components'

import { AttachmentAttributes, FileAttributes } from 'components/features/Conversations/types'
import { fetchDecryptedFileStream } from 'components/features/Conversations/utils/fetchDecryptedFileStream'
import { CryptorContext } from 'components/features/Cryptor/CryptorContext'

import { colors } from './styles'

// types
type Styles = Partial<{
  roundedBottom: boolean
}>

type FileType = 'pdf' | 'image' | 'default'

export type File = {
  id: string
  name: string
  size?: string
  type?: FileType
}

type FileAttachmentItemProps = Omit<File, 'id'> & {
  roundedBottom?: boolean
  onClick: () => void
}

export type FileAttachmentsProps = {
  messageID: string
  attachments?: AttachmentAttributes[]
  files?: FileAttributes[]
}

// components
export const FileAttachments: React.FC<FileAttachmentsProps> = function ({
  messageID,
  attachments = [],
  files = []
}) {
  const cryptorWithKeys = useContext(CryptorContext)

  if (attachments.length === 0 && files.length === 0) {
    return null
  }

  return (
    <div>
      {files.map((file, index, list) => {
        const downloadFile = () => {
          fetchDecryptedFileStream(messageID, file, cryptorWithKeys.cryptor)
        }
        return (
          <FileAttachmentItem
            key={file.id}
            name={file.name}
            roundedBottom={isLastFile(list, index) && !attachments?.length}
            // eslint-disable-next-line react/jsx-no-bind
            onClick={downloadFile}
          />
        )
      })}
      {attachments.map((attachment, index, list) => {
        const downloadFile = () => {
          window.location.href = `/messages/${messageID}/attachments/${attachment.id}`
        }
        return (
          <FileAttachmentItem
            key={attachment.id}
            name={attachment.filename}
            roundedBottom={isLastFile(list, index)}
            // eslint-disable-next-line react/jsx-no-bind
            onClick={downloadFile}
            size={attachment.filesize_string}
          />
        )
      })}
    </div>
  )
}

export const FileAttachmentItem: React.FC<FileAttachmentItemProps> = function ({
  name,
  size,
  roundedBottom = false,
  onClick
}) {
  const isFileDecryptable = !!name
  const filetype = isFileDecryptable ? getFileType(name) : 'default'
  const fileName = name || 'encrypted attachment'

  return (
    <Container roundedBottom={roundedBottom}>
      <ContentContainer onClick={onClick}>
        <Content>
          <TypeIconContainer>
            <FontAwesomeIcon icon={getFileTypeIcon(filetype)} color={colors.grey4} />
          </TypeIconContainer>
          <TextContainer>
            <Name data-testid='message-card-attachment'>{fileName}</Name>
            {size && <Size>{size}</Size>}
          </TextContainer>
          <DownloadIconContainer>
            {isFileDecryptable && <FontAwesomeIcon icon={faDownload} color={colors.grey4} />}
          </DownloadIconContainer>
        </Content>
      </ContentContainer>
    </Container>
  )
}

// styles
const getBorderBottom = ({ roundedBottom }: Styles) => {
  if (!roundedBottom) return ''

  return `
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
  `
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  background-color: ${colors.white};
  border-top: 1px solid ${colors.grey6};
  cursor: pointer;
  ${getBorderBottom}

  &:hover {
    background-color: ${colors.grey11};
  }

  &:active {
    background-color: ${colors.blue2};
  }
`

const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
`

const Content = styled.div`
  display: flex;
  flex-direction: row;
  min-height: 40px;
  justify-content: start;
  align-items: center;
  padding: 8px 16px;
`

const TypeIconContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: ${colors.grey11};
  width: 40px;
  height: 40px;
  border-radius: 100%;
  margin-right: 12px;
`

const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
`

const DownloadIconContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-end;
  width: 40px;
`

const Name = styled.span`
  font-weight: 600;
  font-size: 18px;
  line-height: 24px;
  letter-spacing: -0.01em;
  color: ${colors.grey4};
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
  overflow: hidden;
`

const Size = styled.span`
  font-weight: 400;
  font-size: 13px;
  line-height: 16px;
  color: ${colors.grey8};
`

// utils
const isLastFile = (size: File[] | AttachmentAttributes[], index: number) =>
  size.length - 1 === index

function getFileTypeIcon(type: FileType) {
  switch (type) {
    case 'pdf':
      return faFilePdf

    case 'image':
      return faImage

    default:
      return faPaperclip
  }
}

function getFileType(filename: string): FileType {
  const extension = filename.split('.').pop()
  switch (extension) {
    case 'pdf':
      return 'pdf'

    case 'png':
    case 'jpg':
    case 'jpeg':
    case 'gif':
    case 'bmp':
      return 'image'

    default:
      return 'default'
  }
}
