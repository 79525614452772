import { useQuery } from '@tanstack/react-query'
import React, { useEffect, useState, useCallback } from 'react'

import PlainButton from 'components/shared/Buttons/PlainButton'
import Modal from 'components/shared/Modal/Modal'
import { Spinner } from 'components/shared/Spinner/Spinner'
import { t } from 'i18n'

import { FormsToFill } from '../FormsToFill'

import { RecentContacts } from '../RecentContacts'

import { UpcomingAppointment } from '../UpcomingAppointment'

import { EmptyState } from './EmptyState'
import { EncryptedSegment } from './EncryptedSegment'
import { fetchInitialData, createTwoFactorModalSession } from './api'
import { BasicDashboard } from './types'

type TwoFactorInfoModalProps = {
  onClose: () => void
}

type DashboardContainerProps = {
  isModalClosed: boolean
  onModalClose: () => void
  children: JSX.Element
}

const isEmptyState = (dashboardData: BasicDashboard) =>
  !dashboardData.containsEncryptedData &&
  !dashboardData.upcomingAppointment &&
  dashboardData.formsToFill.length === 0 &&
  dashboardData.recentContacts.length === 0

export const TwoFactorInfoModal = ({ onClose }: TwoFactorInfoModalProps) => {
  return (
    <Modal open onClose={onClose} data-testid='two-factor-info-modal'>
      <Modal.Title>{t('homescreen.2FA.modal.title')}</Modal.Title>
      <Modal.Body>{t('homescreen.2FA.modal.body')}</Modal.Body>
      <div className='flex gap-5 px-3'>
        <PlainButton onClick={onClose} data-testid='two-factor-info-modal-close-btn'>
          {t('homescreen.2FA.modal.button_label.skip')}
        </PlainButton>
        <a
          className='btn-primary'
          href='/settings/second_factor'
          data-testid='two-factor-info-modal-link-to-settings'
        >
          {t('homescreen.2FA.modal.button_label.to_settings')}
        </a>
      </div>
    </Modal>
  )
}

const DashboardContainer = ({ isModalClosed, onModalClose, children }: DashboardContainerProps) => (
  <>
    {!isModalClosed && <TwoFactorInfoModal onClose={onModalClose} />}
    {children}
  </>
)

export const Dashboard = () => {
  const { isLoading, isError, data } = useQuery(['homescreen_initial'], fetchInitialData)
  const [isModalClosed, setIsModalClosed] = useState(true)

  useEffect(() => {
    if (!isLoading && data) {
      setIsModalClosed(data.isTwoFactorModalClosed)
    }
  }, [isLoading, data])

  const handleModalClose = useCallback(async () => {
    try {
      await createTwoFactorModalSession()
    } finally {
      setIsModalClosed(true)
    }
  }, [])

  if (isLoading || isError) {
    return (
      <div data-testid='homescreen-loading' className='flex flex-col items-center py-12'>
        <Spinner className='min-h-[48px] min-w-[48px]' />
        {isError && (
          <div data-testid='homescreen-timeout-error' className='pt-4 font-semibold text-error-600'>
            {t('generic.request_user_to_reload')}
          </div>
        )}
      </div>
    )
  }

  if (!data || isEmptyState(data)) {
    return (
      <DashboardContainer isModalClosed={isModalClosed} onModalClose={handleModalClose}>
        <EmptyState />
      </DashboardContainer>
    )
  }

  return (
    <DashboardContainer isModalClosed={isModalClosed} onModalClose={handleModalClose}>
      <div
        className='flex flex-wrap gap-6 md:flex-nowrap md:gap-8'
        data-testid='homescreen-dashboard-loaded'
      >
        <div className='w-1/2 min-w-[320px] max-w-lg grow md:order-2'>
          <UpcomingAppointment appointment={data.upcomingAppointment} />
        </div>
        <div className='flex w-1/2 min-w-[320px] max-w-lg grow flex-col gap-6 md:order-1'>
          <RecentContacts contacts={data.recentContacts} />
          {data.formsToFill.length > 0 && <FormsToFill forms={data.formsToFill} />}
          {data.containsEncryptedData && <EncryptedSegment />}
        </div>
      </div>
    </DashboardContainer>
  )
}
