import { faEye, faEyeSlash } from '@fortawesome/pro-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { InputHTMLAttributes, useState } from 'react'

import { t } from 'i18n'

type Props = InputHTMLAttributes<HTMLInputElement>

export const PasswordInput: React.FC<Props> = function (props) {
  const [isVisible, setVisibility] = useState<boolean>(false)
  const fieldType = isVisible ? 'text' : 'password'
  const ariaLabel = isVisible
    ? t('password.visibility_toggler.click_to_hide')
    : t('password.visibility_toggler.click_to_show')

  return (
    <div className='o-icon-wrapper'>
      <input type={fieldType} {...props} />
      <button
        type='button'
        aria-label={ariaLabel}
        className='o-icon-wrapper__icon o-clickable'
        // eslint-disable-next-line react/jsx-no-bind
        onClick={() => setVisibility(!isVisible)}
        data-testid='visibility-toggler'
      >
        <FontAwesomeIcon
          icon={isVisible ? faEye : faEyeSlash}
          data-testid='visibility-toggler-icon'
        />
      </button>
    </div>
  )
}
