import React from 'react'

import { AttachmentAttributes } from '../../models/Attachment'

export const Attachment: React.FC<{ messageID: string; attachment: AttachmentAttributes }> = ({
  messageID,
  attachment
}) => (
  <>
    <a
      key={attachment.id}
      href={`/messages/${messageID}/attachments/${attachment.id}`}
      className='f-read-message__attachment-link o-clickable'
    >
      {attachment.filename}
    </a>
    ({attachment.filesize_string})
  </>
)
