import { Cryptor } from '@samedi/crypto-js'
import axios from 'axios'

import {
  MedicalRecordEntry,
  DecryptedMedicalRecordEntry,
  decryptMedicalRecordEntry
} from '../MedicalRecordEntry'

export function sortDecryptedEntries(decryptedEntries: DecryptedMedicalRecordEntry[]) {
  decryptedEntries.sort((a, b): number => {
    const dateA = new Date(a.created_at).getTime()
    const dateB = new Date(b.created_at).getTime()

    return dateA <= dateB ? 1 : -1
  })
}

async function decryptAll(entries: MedicalRecordEntry[], cryptor: Cryptor) {
  const decryptedEntries: DecryptedMedicalRecordEntry[] = []

  for (const entry of entries) {
    decryptedEntries.push(
      await decryptMedicalRecordEntry(entry, cryptor).catch(
        (err) =>
          ({
            ...entry,
            description: `ERR: ${err}`
          }) as DecryptedMedicalRecordEntry
      )
    )
  }

  sortDecryptedEntries(decryptedEntries)
  return decryptedEntries
}

/**
 * Fetches and decrypts medical record entries
 *
 * @param cryptor Cryptor instance for decrypting the entries
 */
export async function fetch(cryptor: Cryptor) {
  const response = await axios.get<MedicalRecordEntry[]>('/api/medical_record/v1/entries.json')
  return decryptAll(response.data, cryptor)
}

/**
 * Fetches a medical record entry with specified id
 *
 * @param id medical record entry id
 */
export async function fetchById(id: string | number) {
  const response = await axios.get<MedicalRecordEntry>(`/api/medical_record/v1/entries/${id}.json`)
  return response.data
}
