/* eslint-disable @typescript-eslint/no-use-before-define */
import { QueryClient } from '@tanstack/react-query'
import axios from 'axios'

import { Message } from '../types'

export async function markMessagesRead(
  practiceId: string,
  messages: Message[] = [],
  queryClient: QueryClient
): Promise<void> {
  const unreadMessages = messages?.filter((m: Message) => !m.is_read)

  if (unreadMessages.length > 0) {
    markReadViaApi(practiceId, queryClient)
  }
}

async function markReadViaApi(practiceId: string, queryClient: QueryClient): Promise<void> {
  await axios.patch(`/api/messages/v2/messages/mark_read.json?practice_id=${practiceId}`)
  queryClient.invalidateQueries(['messages', practiceId])
  queryClient.invalidateQueries(['contacts'])
}
