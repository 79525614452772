import { faArrowLeft, faArrowRight } from '@fortawesome/pro-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import classNames from 'classnames'
import React from 'react'
import { Link } from 'react-router-dom'

interface Props {
  currentPage: number
  totalPages: number
  onClick: () => void
}

interface PaginationNode {
  type: 'page' | 'separator'
  number: number
}

/**
 * Generates data necessary to render paginatio nodes (pages and separators)
 *
 * Pagination should behave the same as will_paginate gem with windows size 0
 * @param currentPage
 * @param totalPages
 */
function paginationNodesData(currentPage: number, totalPages: number): PaginationNode[] {
  const nodes: PaginationNode[] = []
  const pushPage = (number: number) => nodes.push({ type: 'page', number })
  const pushSeparator = (number: number) => nodes.push({ type: 'separator', number })

  pushPage(1)

  // If current pages is near beginning, we show all page links up to the current page
  if (currentPage < 4) {
    for (let page = 2; page < currentPage; page++) {
      pushPage(page)
    }
  } else {
    pushSeparator(1)
  }

  if (currentPage !== 1 && currentPage !== totalPages) {
    pushPage(currentPage)
  }

  // If current page is near end, we show all page links from current page to last page
  if (currentPage >= totalPages - 2) {
    for (let page = currentPage + 1; page < totalPages; page++) {
      pushPage(page)
    }
  } else {
    pushSeparator(2)
  }
  pushPage(totalPages)

  return nodes
}

export const Pagination: React.FC<Props> = ({ currentPage, totalPages, onClick }) => {
  const nextPage = totalPages > currentPage ? currentPage + 1 : null
  const previousPage =
    currentPage > 1 ? (currentPage <= totalPages ? currentPage - 1 : totalPages) : null
  const paginationNodes = paginationNodesData(currentPage, totalPages)

  return (
    <div className='pagination'>
      {(previousPage || nextPage) && (
        <ul className='pagination'>
          <li className={`${classNames('prev previous_page', { disabled: !previousPage })}`}>
            <a
              rel='prev'
              {...(previousPage && { href: `?page=${previousPage}`, onClick: onClick })}
            >
              <FontAwesomeIcon icon={faArrowLeft} />
            </a>
          </li>
          {paginationNodes.map((node) =>
            node.type === 'page' ? (
              <li
                key={`page-${node.number}`}
                className={classNames({
                  active: node.number === currentPage
                })}
              >
                <Link to={`?page=${node.number}`} onClick={onClick}>
                  {node.number}
                </Link>
              </li>
            ) : (
              <li key={`separator-${node.number}`} className='disabled'>
                <a>...</a>
              </li>
            )
          )}
          <li className={`${classNames('next next_page', { disabled: !nextPage })}`}>
            <a rel='next' {...(nextPage && { href: `?page=${nextPage}`, onClick: onClick })}>
              <FontAwesomeIcon icon={faArrowRight} />
            </a>
          </li>
        </ul>
      )}
    </div>
  )
}
