import { faLock, faUserDoctor } from '@fortawesome/pro-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import * as React from 'react'

import { DecryptedMedicalRecordEntry } from '../MedicalRecordEntry'
export interface Props {
  entry: DecryptedMedicalRecordEntry
}

export const SentToInstitutionsList: React.FC<Props> = function ({ entry }) {
  /** if entry was created by doctor, we add that institution */
  const institutionsCount = entry.sent_to_institutions.length + (entry.creator_practice ? 1 : 0)

  return (
    <span>
      {institutionsCount > 0 && (
        <>
          <span className='c-medical-record-entries__share-status-icon'>
            <FontAwesomeIcon icon={faUserDoctor} />
          </span>
          <span>{institutionsCount}</span>
        </>
      )}
      {!institutionsCount && (
        <span className='c-medical-record-entries__share-status-icon'>
          <FontAwesomeIcon icon={faLock} />
        </span>
      )}
    </span>
  )
}
