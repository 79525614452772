import classNames from 'classnames'
import React from 'react'

interface FormGroupProps {
  hasErrors?: boolean
}

export const FormGroup: React.FC<FormGroupProps> = ({ hasErrors, children }) => (
  <div className={classNames('s-form-group', { error: hasErrors })}>{children}</div>
)
