import {
  faEllipsisH,
  faEnvelope,
  faEnvelopeOpen,
  faReply,
  faTrashCan
} from '@fortawesome/pro-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useQueryClient, useMutation } from '@tanstack/react-query'
import classNames from 'classnames'

import React, { useState } from 'react'

import { CellProps } from 'react-table'

import { ConfirmationModal } from 'components/features/ConfirmationModal'
import { dateFormat, t } from 'i18n'

import { ComposeLink } from '../../ComposeLink'
import {
  DecryptedMessageSummaryAttributes,
  deleteMessage,
  setMessageReadStatus
} from '../../models/Message'

export const CellActions: React.FC<
  { folder: string } & CellProps<DecryptedMessageSummaryAttributes, string>
> = function (props) {
  const { folder, row } = props
  const [showConfirmationDialog, setShowConfirmationDialog] = useState<boolean>(false)
  const [isCollapsed, setIsCollapsed] = useState<boolean>(true)
  const containerClassNames = classNames('f-messages__cell f-messages__actions-cell', {
    'is-collapsed': isCollapsed
  })

  const queryClient = useQueryClient()

  const { mutate: toggleRead } = useMutation(
    (message: DecryptedMessageSummaryAttributes) =>
      setMessageReadStatus(message.id, !message.is_read),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(['messages'])
      }
    }
  )

  const { mutate: confirmMessageDeletion } = useMutation(
    async (message: DecryptedMessageSummaryAttributes) => {
      await deleteMessage(message.id)
      setShowConfirmationDialog(false)
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(['messages'])
      }
    }
  )

  return (
    <div className={containerClassNames}>
      {showConfirmationDialog && (
        <ConfirmationModal
          title={t('messages.delete_confirmation.title')}
          confirmButtonLabel={t('messages.delete_confirmation.confirm')}
          onCancel={() => setShowConfirmationDialog(false)} // eslint-disable-line react/jsx-no-bind
          onConfirm={() => confirmMessageDeletion(row.original)} // eslint-disable-line react/jsx-no-bind
        />
      )}
      {/* eslint-disable-next-line react/jsx-no-bind*/}
      <div className='f-messages__expand-actions' onClick={() => setIsCollapsed(!isCollapsed)}>
        {dateFormat(row.original.created_at, 'dateTimeDynamic')}
        <div className='f-messages__ellipsis'>
          <FontAwesomeIcon icon={faEllipsisH} />
        </div>
      </div>
      {folder === 'inbox' && (
        <>
          <ComposeLink inReplyTo={row.original} className='f-messages__action' aria-label='reply'>
            <FontAwesomeIcon icon={faReply} />
          </ComposeLink>
          <div
            className='f-messages__action'
            onClick={() => toggleRead(row.original)} // eslint-disable-line react/jsx-no-bind
            aria-label='toggle read status'
          >
            <FontAwesomeIcon icon={row.original.is_read ? faEnvelope : faEnvelopeOpen} />
          </div>
        </>
      )}
      <div
        className='f-messages__action'
        onClick={() => setShowConfirmationDialog(true)} // eslint-disable-line react/jsx-no-bind
        aria-label='delete'
      >
        <FontAwesomeIcon icon={faTrashCan} />
      </div>
    </div>
  )
}
