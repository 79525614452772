/* eslint-disable @typescript-eslint/no-use-before-define */
import React from 'react'
import styled from 'styled-components'

import { t } from 'i18n'

import { colors } from './styles'

// types
export type Styles = Partial<{
  navbarHeight: number
}>

// components
export const Template: React.FC = ({ children }) => {
  return (
    <Container>
      <Title>{t('conversation.title')}</Title>
      {children}
    </Container>
  )
}

// styles
const Container = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0;
  margin: 0;
  padding-top: 24px;
  min-height: 500px;
  // 57px is the height of .c-top-menu
  // 24px is the margin-top of .s-layout-grid_main-content
  // 12px to add a padding to the bottom
  height: calc(100vh - 57px - 24px - 12px);
`

const Title = styled.h2`
  font-weight: 600;
  font-size: 40px;
  color: ${colors.grey4};
`
