/* eslint-disable @typescript-eslint/no-use-before-define */
import React from 'react'
import styled from 'styled-components'

import instituteImg from 'images/instituteImg.png'

import { getDate } from '../utils/getDate'

// types
type Styles = Partial<{
  isHighlighted: boolean
  hasUnreadMessages: boolean
}>

export type ConversationListItemProps = {
  contactName: string | null
  latestMessageBody?: string
  latestMessageDate?: string
  unreadMessageCount?: number
  isHighlighted?: boolean
}

// components
export const ConversationListItem: React.FC<ConversationListItemProps> = ({
  contactName,
  latestMessageBody,
  latestMessageDate,
  unreadMessageCount = 0,
  isHighlighted = false
}) => {
  const hasUnreadMessages = Boolean(unreadMessageCount)
  // message body is html, so we remove all tags to display it as text
  const pureTextLatestMessageBody =
    latestMessageBody && latestMessageBody.replace(/\<br ?\/\>/g, '\n').replace(/<[^>]+>/g, '')

  return (
    <Container isHighlighted={isHighlighted} hasUnreadMessages={hasUnreadMessages}>
      <ImageContainer>
        <img src={instituteImg} />
      </ImageContainer>
      <Content data-testid='conversation-list-contact'>
        <Name data-testid='conversation-list-contact-name'>{contactName}</Name>
        {latestMessageDate && (
          <Date data-testid='conversation-list-contact-time'>{getDate(latestMessageDate)}</Date>
        )}
        {pureTextLatestMessageBody && (
          <Text
            data-testid='conversation-list-contact-message'
            hasUnreadMessages={hasUnreadMessages}
          >
            {pureTextLatestMessageBody}
          </Text>
        )}
        {hasUnreadMessages && (
          <BadgeContainer>
            <Badge className='unread-message-count'>{unreadMessageCount}</Badge>
          </BadgeContainer>
        )}
      </Content>
    </Container>
  )
}

// styles
const getTextStyle = ({ hasUnreadMessages }: Styles) => {
  if (!hasUnreadMessages) return ''

  return `
    color: var(--info-900);
    font-style: normal;
  `
}

const Container = styled.div<Styles>`
  display: flex;
  flex-direction: row;
  height: 80px;
  align-items: center;
  padding: 0 16px 0 8px;
  border-radius: 8px;
  cursor: pointer;
  border-left-width: 8px;
  border-left-style: solid;
  border-left-color: ${({ hasUnreadMessages }) =>
    hasUnreadMessages ? 'var(--sky-blue)' : 'white'};

  &:hover {
    background: #e5f4ff;
  }

  // only on large screen, the currently selected conversation gets a blue background
  // (on small screens, the list is not shown at the same time as the conversation)
  @media (min-width: 1024px) {
    ${({ isHighlighted }) => (isHighlighted ? 'cursor: initial' : 'cursor: pointer')};
    ${({ isHighlighted }) => (isHighlighted ? 'background: #e5f4ff' : '')};
  }
`

const ImageContainer = styled.div`
  padding-right: 16px;
`

const Content = styled.div`
  display: grid;
  grid-template-rows: 24px 24px;
  grid-template-columns: 5fr 1fr;
  width: 100%;
`

const Name = styled.span`
  font-weight: 600;
  font-size: 18px;
  line-height: 24px;
  color: var(--dark);
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  text-transform: capitalize;
`

const Text = styled.span`
  color: var(--info-700);
  font-style: italic;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  ${getTextStyle}
`

const Date = styled.span`
  font-size: 13px;
  color: var(--info-700);
  text-align: right;
  padding-left: 10px;
`

const BadgeContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-self: center;
  justify-self: flex-end;
  height: 16px;
  min-width: 16px;
  border-radius: 9999px;
  background-color: var(--sky-blue);
  justify-content: flex-end;
  align-items: center;
`
const Badge = styled.span`
  font-weight: 700;
  font-size: 11px;
  line-height: 16px;
  color: white;
  padding: 0 4px;
  white-space: nowrap;
  overflow: hidden;
  max-width: 34px;
  text-overflow: ellipsis;
`
