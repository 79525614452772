// Converts a Blob data to Base64.
export default function blobToB64(blob: Blob): Promise<string> {
  return new Promise((resolve, reject) => {
    if (!(blob instanceof Blob)) {
      reject(new Error(`expected an instance of Blob, but got ${blob}`))
    }

    const reader = new FileReader()
    reader.readAsDataURL(blob)
    reader.onloadend = () => {
      if (!reader.result) {
        return reject(new Error('could not read blob data'))
      }

      if (!(typeof reader.result === 'string')) {
        return reject(new Error('could not read blob data as string'))
      }

      resolve(reader.result.split(',')[1])
    }
  })
}
