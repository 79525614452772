import { faXmark } from '@fortawesome/pro-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { useCallback, useContext } from 'react'

import { CryptorContext } from 'components/features/Cryptor/CryptorContext'
import { t } from 'i18n'

import { FileAttributes, fetchDecryptedFileStream } from '../../models/File'

export const EncryptedFile: React.FC<{
  messageID: string
  file: FileAttributes
}> = function ({ messageID, file }) {
  const cryptorWithKeys = useContext(CryptorContext)
  const downloadFile = useCallback(() => {
    fetchDecryptedFileStream(messageID, file, cryptorWithKeys.cryptor)
  }, [cryptorWithKeys.cryptor, file, messageID])

  if (!file.decryptionSucceded) {
    return (
      <span className='f-read-message__attachment-error'>
        <FontAwesomeIcon className='f-read-message__attachment-error-icon' icon={faXmark} />
        {t('messages.unable_to_decrypt')}
      </span>
    )
  }

  return (
    <button
      key={file.id}
      onClick={downloadFile}
      className='f-read-message__attachment-button o-clickable'
    >
      {file.name}
    </button>
  )
}
