/* eslint-disable react/forbid-dom-props */
import { faPlus } from '@fortawesome/pro-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import classNames from 'classnames'
import React from 'react'

import { FormType } from 'components/features/MedicalRecord/EntryForm'
import { t } from 'i18n'

import { trackEvent } from 'services/tracker'

interface Props {
  onShowStandardForm: () => void
  onShowFileForm: () => void
  currentFormShowing?: FormType | undefined
}

export default function Header({ onShowStandardForm, onShowFileForm, currentFormShowing }: Props) {
  const isTextFormButtonDisabled = currentFormShowing === 'text'
  const isFileFormButtonDisabled = currentFormShowing === 'file'

  const handleClickShowFileForm = () => {
    onShowFileForm()
    trackEvent('MHR', 'add new file clicked')
  }

  return (
    <div>
      <p className='s-button-group'>
        <a
          className={classNames('s-button s-button-group__button', {
            's-button--disabled': isTextFormButtonDisabled
          })}
          data-testid='text-form-button'
          onClick={!isTextFormButtonDisabled ? onShowStandardForm : undefined}
        >
          <span className='s-button__icon'>
            <FontAwesomeIcon icon={faPlus} />
          </span>
          {t('MEDICAL_RECORD/NEW_DATA_ENTRY')}{' '}
        </a>
        <a
          className={classNames('s-button s-button-group__button', {
            's-button--disabled': isFileFormButtonDisabled
          })}
          data-testid='file-form-button'
          onClick={!isFileFormButtonDisabled ? handleClickShowFileForm : undefined}
        >
          <span className='s-button__icon'>
            <FontAwesomeIcon icon={faPlus} />
          </span>
          {t('medical_record/new_file')}{' '}
        </a>
      </p>
    </div>
  )
}
