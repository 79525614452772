import { faShareNodes } from '@fortawesome/pro-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React from 'react'

import { t } from 'i18n'

import { DecryptedMedicalRecordEntry } from '../MedicalRecordEntry'

interface Props {
  entry: DecryptedMedicalRecordEntry
  onSendEntry: (entry: DecryptedMedicalRecordEntry) => void
}

export const ShareIcon: React.FC<Props> = function ({ entry, onSendEntry }) {
  if (!entry.session_key) {
    return <span></span>
  }

  return (
    <span
      className='o-clickable c-medical-record-entries__icon c-medical-record-entries__share-icon'
      title={t('MEDICAL_RECORD/SEND_TO_INSTITUTION_TITLE')}
      // eslint-disable-next-line react/jsx-no-bind
      onClick={(e) => {
        // to prevent the whole row click expansion
        e.stopPropagation()
        !entry.data_undecryptable && onSendEntry(entry)
      }}
    >
      <FontAwesomeIcon icon={faShareNodes} />
    </span>
  )
}
