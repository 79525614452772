import classNames from 'classnames'
import React from 'react'

import { useTable, useExpanded } from 'react-table'

import { FormsColumn } from 'components/features/Forms/FormsTable'
import { MedicalRecordEntryColumns } from 'components/features/MedicalRecord/MedicalRecordEntry'

interface Props {
  columns: MedicalRecordEntryColumns[] | FormsColumn[]
  data: object[]
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  renderRowSubComponent?: ({ row }: any) => JSX.Element
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  isRowDisabled?: (rowOriginal: any) => boolean | undefined
  noHeader?: boolean
  defaultFont?: boolean
  minimumPadding?: boolean
  wholeRowClickable?: boolean
  noColorSwitch?: boolean
  hasMarginBottom?: boolean
}

export function Table({
  columns,
  data,
  renderRowSubComponent,
  noHeader,
  defaultFont,
  minimumPadding,
  wholeRowClickable,
  noColorSwitch,
  hasMarginBottom,
  isRowDisabled
}: Props) {
  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } = useTable(
    {
      columns,
      data,
      autoResetExpanded: false
    } as any, // eslint-disable-line @typescript-eslint/no-explicit-any
    useExpanded
  )

  return (
    <table
      className={`o-table ${noColorSwitch ? 'o-table--no-color-switch' : ''}`}
      {...getTableProps()}
    >
      <thead
        className={`o-table__head o-table__head--collapsible ${
          noHeader ? 'o-table__head--no-header' : ''
        }`}
      >
        {/*eslint-disable-next-line @typescript-eslint/no-explicit-any*/}
        {headerGroups.map((headerGroup: any, i: number) => (
          <tr key={i} {...headerGroup.getHeaderGroupProps()}>
            {/*eslint-disable-next-line @typescript-eslint/no-explicit-any*/}
            {headerGroup.headers.map((column: any, index: number) => {
              const headerClasses = ['o-table__cell', 'o-table__cell--header']
              column.className && headerClasses.push(column.className)

              return (
                <th key={index} className={headerClasses.join(' ')} {...column.getHeaderProps()}>
                  <h6 className='o-table__header'>{column.render('Header')}</h6>
                </th>
              )
            })}
          </tr>
        ))}
      </thead>
      <tbody {...getTableBodyProps()}>
        {/*eslint-disable-next-line @typescript-eslint/no-explicit-any*/}
        {rows.map((row: any, index: number) => {
          prepareRow(row)

          const isDisabled = isRowDisabled && isRowDisabled(row.original)
          const rowClasses = classNames(
            'o-table__row',
            { 'is-disabled': isDisabled },
            { 'o-table__row--min-padding': minimumPadding },
            { 'is-expanded': row.isExpanded },
            { 'o-table__row--clickable': wholeRowClickable },
            { 'o-table__row--margin-bottom': hasMarginBottom }
          )

          return (
            <React.Fragment key={index}>
              <tr
                className={rowClasses}
                {...row.getRowProps()}
                onClick={wholeRowClickable && row.toggleRowExpanded}
              >
                {/*eslint-disable-next-line @typescript-eslint/no-explicit-any*/}
                {row.cells.map((cell: any, i: number) => {
                  const cellClasses = classNames(
                    'o-table__cell',
                    { 'o-table__cell--border-left': columns[i].borderLeft },
                    { 'o-table__cell--border-bottom': columns[i].borderBottom },
                    { 'o-secondary-text': !defaultFont },
                    { [columns[i].className as string]: columns[i].className }
                  )

                  return (
                    <td
                      key={i}
                      style={{ minWidth: columns[i].minWidth }}
                      className={cellClasses}
                      {...cell.getCellProps()}
                    >
                      {cell.render('Cell')}
                    </td>
                  )
                })}
              </tr>
              {row.isExpanded ? (
                <>
                  <tr className='o-table__spacer' />
                  <tr
                    className={classNames('o-table__row o-table__expanded-row', {
                      'is-disabled': isDisabled
                    })}
                  >
                    <td colSpan={columns.length - 1}>
                      {renderRowSubComponent && renderRowSubComponent({ row })}
                    </td>
                  </tr>
                  <tr className='o-table__spacer' />
                </>
              ) : null}
            </React.Fragment>
          )
        })}
      </tbody>
    </table>
  )
}
