import React, { useMemo, useContext } from 'react'

import { CryptorContext } from 'components/features/Cryptor/CryptorContext'
import { CategoryName } from 'components/features/MedicalRecord/EntriesTable/CategoryName'
import { MedicalRecordEntryColumns } from 'components/features/MedicalRecord/MedicalRecordEntry'
import { Table } from 'components/shared/Tables/Table'
import { dateFormat } from 'i18n'

import { DecryptedMedicalRecordEntryShare } from '../MedicalRecordEntryShare'

import { AcceptAction } from './AcceptAction'
import { Description } from './Description'
import { RejectAction } from './RejectAction'

export interface Props {
  entries: DecryptedMedicalRecordEntryShare[]
  onAcceptEntry: (entry: DecryptedMedicalRecordEntryShare) => void
  onRejectEntry: (entry: DecryptedMedicalRecordEntryShare) => void
}

export function RequestsTable({ entries, onAcceptEntry, onRejectEntry }: Props) {
  const cryptorWithKeys = useContext(CryptorContext)

  const columns: MedicalRecordEntryColumns[] = useMemo(
    () => [
      {
        id: 'name',
        accessor: (entry: DecryptedMedicalRecordEntryShare) => (
          <CategoryName entry={entry.target} isLarge />
        ),
        minWidth: 160,
        className: 'c-medical-record-entries__cell'
      },
      {
        id: 'value',
        accessor: (entry: DecryptedMedicalRecordEntryShare) => (
          <Description entry={entry} cryptorWithKeys={cryptorWithKeys} />
        ),
        minWidth: 50,
        className: 'c-medical-record-entries__cell h-word-break'
      },
      {
        id: 'created_by',
        accessor: (entry: DecryptedMedicalRecordEntryShare) =>
          entry.target.creator_practice && (
            <span className='c-medical-record-entries__created-by h-word-break'>
              {entry.target.creator_name}
              {entry.target.creator_practice && ` (${entry.target.creator_practice})`}
            </span>
          ),
        minWidth: 160,
        className: 'c-medical-record-entries__cell'
      },
      {
        id: 'created_on',
        accessor: (entry: DecryptedMedicalRecordEntryShare) => (
          <span className='o-secondary-text'>{dateFormat(entry.created_at)}</span>
        ),
        minWidth: 70,
        className: 'c-medical-record-entries__cell'
      },
      {
        id: 'actions',
        accessor: (entry: DecryptedMedicalRecordEntryShare) => (
          <>
            <AcceptAction
              isDisabled={entry.target.data_undecryptable}
              // eslint-disable-next-line react/jsx-no-bind
              onAcceptEntry={() => onAcceptEntry(entry)}
            />
            {/*eslint-disable-next-line react/jsx-no-bind*/}
            <RejectAction onRejectEntry={() => onRejectEntry(entry)} />
          </>
        ),
        minWidth: 260,
        className: 'o-table__cell--align-right'
      }
    ],
    [cryptorWithKeys, onAcceptEntry, onRejectEntry]
  )

  const data = useMemo(() => entries, [entries])

  const isRowDisabled = (entry: DecryptedMedicalRecordEntryShare): boolean | undefined =>
    entry.target?.data_undecryptable

  return (
    <div>
      <Table
        columns={columns}
        data={data}
        noHeader
        defaultFont
        minimumPadding
        noColorSwitch
        {...{ isRowDisabled }}
      />
    </div>
  )
}
