import { parseISO } from 'date-fns'

import { BasicDashboard, BasicDashboardServer } from './types'

export const transformInitialData = ({
  upcoming_appointment,
  forms,
  recent_contacts,
  contains_encrypted_data,
  two_factor_modal_closed
}: BasicDashboardServer): BasicDashboard => ({
  upcomingAppointment: upcoming_appointment
    ? {
        appointmentName: upcoming_appointment.appointment_name,
        forms: upcoming_appointment.forms.map(({ id, name, form_path }) => ({
          id,
          name,
          formPath: form_path
        })),
        location: {
          name: upcoming_appointment.location.name,
          address: upcoming_appointment.location.address,
          city: upcoming_appointment.location.city,
          zipCode: upcoming_appointment.location.zip_code
        },
        startsAt: parseISO(upcoming_appointment.starts_at),
        videoConsultationLink: upcoming_appointment.video_consultation_link
      }
    : null,
  containsEncryptedData: contains_encrypted_data,
  formsToFill: forms.map(({ id, practice_name, form_title, form_url, sent_at }) => ({
    id,
    practiceName: practice_name,
    formTitle: form_title,
    formUrl: form_url,
    sentAt: parseISO(sent_at)
  })),
  recentContacts: recent_contacts.map(({ id, booking_url, person_name, practice_name }) => ({
    id: id.toString(),
    bookingUrl: booking_url,
    personName: person_name,
    practiceName: practice_name
  })),
  isTwoFactorModalClosed: two_factor_modal_closed
})
