/* eslint-disable camelcase */
import axios from 'axios'

import { authenticityHeaders } from 'util/csrf'

export interface MedicalRecordEntry {
  type: string
  session_key: string
  encrypted_data: string
  entry_date?: string
  entry_time?: string
  permanent?: boolean
  entry_type_id?: number
  encryption_key_id?: string
  file_id?: string
}

export default async function saveMedicalRecordEntry(entry: MedicalRecordEntry) {
  return axios.post(
    '/api/medical_record/v1/entries.json',
    { entry },
    { headers: authenticityHeaders() }
  )
}
