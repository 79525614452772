import React, { ButtonHTMLAttributes, DetailedHTMLProps } from 'react'

export type BaseButtonProps = DetailedHTMLProps<
  ButtonHTMLAttributes<HTMLButtonElement>,
  HTMLButtonElement
>

const BaseButton: React.FC<BaseButtonProps> = ({ className, ...props }) => {
  return (
    <button className={`w-full rounded-lg py-3.5 font-bold leading-5 ${className}`} {...props} />
  )
}

export default BaseButton
