import { faChevronLeft } from '@fortawesome/pro-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useQuery } from '@tanstack/react-query'
import { Field, FieldProps, Form, Formik } from 'formik'
import React, { useContext, useState } from 'react'
import { Link, Navigate, useLocation } from 'react-router-dom'

import { DecryptedData } from 'components/features/Messaging/models/Message'

import { ProgressButton } from 'components/shared/Buttons'
import { Loading } from 'components/shared/StatePages/Loading'

import { t } from 'i18n'

import { CryptorContext } from '../../Cryptor/CryptorContext'

import { makeReplySubject } from '../makeReplySubject'
import { fetchContactPractices } from '../models/ContactPractice'
import { MessageFormFields, validateFields } from '../models/MessageFormFields'

import { ComposeMessageHeader } from './ComposeMessageHeader'

import { FormGroup } from './FormGroup'

import { PracticeSelectField } from './PracticeSelectField'

import { StyledErrorMessage } from './StyledErrorMessage'

import { handleFormSubmit } from './handleFormSubmit'

export interface InReplyTo {
  id: string
  sender_practice_id?: string
  sender_name: string
  decrypted_subject: DecryptedData
}

interface ComposeMessageProps {
  inReplyTo?: InReplyTo
}

export const ComposeView: React.FC<ComposeMessageProps> = function () {
  const location = useLocation()
  const { inReplyTo } = location.state as ComposeMessageProps

  // eslint-disable-next-line @typescript-eslint/no-use-before-define
  return <ComposeMessage inReplyTo={inReplyTo} />
}

export const ComposeMessage: React.FC<ComposeMessageProps> = function ({ inReplyTo }) {
  const cryptorWithKeys = useContext(CryptorContext)
  const { data: contactPractices, isLoading } = useQuery(
    ['contact_practices'],
    fetchContactPractices,
    {
      refetchOnWindowFocus: false,
      refetchInterval: false
    }
  )
  const [createdMessageId, setCreatedMessageId] = useState<string | undefined>()
  const [submitErrorMessage, setSubmitErrorMessage] = useState<string | undefined>()

  if (createdMessageId) {
    return <Navigate to={`/messages/${createdMessageId}`} />
  }

  if (isLoading) {
    return <Loading label={t('messaging.loading')} />
  }

  let subject = ''
  if (inReplyTo) {
    subject = inReplyTo.decrypted_subject.decryptionSucceded
      ? makeReplySubject(inReplyTo.decrypted_subject.value)
      : t('messages.decryption_error.subject')
  }

  const initialValues: MessageFormFields = {
    subject: subject,
    body_text: '',
    practiceId: inReplyTo ? inReplyTo.sender_practice_id : contactPractices?.defaultContactId
  }

  if (!initialValues.practiceId) {
    return (
      <>
        <ComposeMessageHeader isReply={!!inReplyTo} />
        {t('messaging.no_contact_practices')}
      </>
    )
  }

  return (
    <>
      <div className='f-messages__actions h-margin-bottom--24'>
        <div className='f-messages__back'>
          <Link to='/messages/inbox' className='o-clickable'>
            <FontAwesomeIcon icon={faChevronLeft} className='h-margin-right--8' />
            {t('messages.index.inbox')}
          </Link>
        </div>
      </div>
      <ComposeMessageHeader isReply={!!inReplyTo} />
      <Formik<MessageFormFields>
        initialValues={initialValues}
        // eslint-disable-next-line react/jsx-no-bind
        validate={(values) => {
          setSubmitErrorMessage(undefined)
          return validateFields(values)
        }}
        validateOnChange={false}
        validateOnBlur={false}
        // eslint-disable-next-line react/jsx-no-bind
        onSubmit={(values) => {
          handleFormSubmit(values, cryptorWithKeys)
            .then((createdMessageId) => setCreatedMessageId(createdMessageId))
            .catch((e) =>
              setSubmitErrorMessage(`An error occurred while sending this message: ${e}`)
            )
        }}
      >
        {({ isSubmitting, submitForm, errors, setFieldValue }) => (
          <Form className='o-form'>
            {inReplyTo ? (
              <h4>
                {t('activerecord.attributes.message.to')}: {inReplyTo.sender_name}
              </h4>
            ) : (
              <Field name='practiceId'>
                {(props: FieldProps) => (
                  <FormGroup hasErrors={!!props.meta.error}>
                    <label className='o-form-label'>
                      {t('activerecord.attributes.message.receiving_practice_name')}
                    </label>
                    <PracticeSelectField
                      practices={contactPractices}
                      fieldProps={props}
                      setFieldValue={setFieldValue}
                    />
                    <StyledErrorMessage name={props.field.name} />
                  </FormGroup>
                )}
              </Field>
            )}

            <FormGroup hasErrors={!!errors.subject}>
              <label htmlFor='subject' className='o-form-label'>
                {t('activerecord.attributes.message.subject')}
              </label>
              <Field id='subject' name='subject' className='o-text-field'></Field>
              <StyledErrorMessage name='subject' />
            </FormGroup>

            <FormGroup hasErrors={!!errors.body_text}>
              <label htmlFor='body_text' className='o-form-label'>
                {t('activerecord.attributes.message.body')}
              </label>
              <Field id='body_text' name='body_text' className='o-text-area' as='textarea'></Field>
              <StyledErrorMessage name='body_text' />
            </FormGroup>

            <div className='s-button-group s-button-group--left-aligned'>
              <ProgressButton
                loadingState={isSubmitting}
                onClick={submitForm}
                disabled={isSubmitting}
                type='submit'
                className='s-button-group__button'
              >
                {t('messaging.send_button')}
              </ProgressButton>
              <Link
                to='/messages/inbox'
                className='s-button s-button--secondary s-button-group__button'
              >
                {t('cancel')}
              </Link>
            </div>
            {submitErrorMessage && <span className='help-block'>{submitErrorMessage}</span>}
          </Form>
        )}
      </Formik>
    </>
  )
}
