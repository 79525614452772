/* eslint-disable @typescript-eslint/no-use-before-define */
import { faXmark } from '@fortawesome/pro-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { useEffect, useState } from 'react'

import { FlashMessage } from 'app/javascript/types'
import { t } from 'i18n'
import newPatient from 'images/new-patient.svg'

import { LoginForm } from './LoginForm'

export const Login: React.FC<{}> = function () {
  return (
    <div className='h-full w-full bg-grey-150 py-2'>
      <div className='o-container o-two-column-page items-center'>
        <section className='o-two-column-page__main-content'>
          <h1>{t('loginscreen.header')}</h1>
          <p className='o-two-column-page__main-content-teaser'>{t('loginscreen.sub-header')}</p>
          <FlashMessages />
          <div id='js-login-for'>
            <LoginForm />
          </div>
        </section>
        <div className='o-two-column-page__gap'></div>
        <section className='o-two-column-page__side-content'>
          <h2 className='o-two-column-page__side-content-title'>
            {t('loginscreen.side_content_header')}
          </h2>
          <img
            alt={t('loginscreen.new_patient_image_alt_text')}
            className='c-login__side-image h-margin-bottom--24'
            src={newPatient}
          />
          <p className='o-two-column-page__main-content-teaser h-font-weight--600'>
            {t('loginscreen.side_content_sub-header')}
          </p>
          <div className='o-notch h-margin-bottom--8'></div>
          <a className='o-clickable' href='/user/new'>
            {t('loginscreen.registration_link')}
          </a>
        </section>
      </div>
    </div>
  )
}

export const FlashMessages: React.FC = () => {
  const [flashMessages, setFlashMessages] = useState<FlashMessage[]>([])

  useEffect(() => {
    setFlashMessages(window.flashMessages)
  }, [])

  const handleClose = (index: number) => () => {
    setFlashMessages((messages) => messages.slice(0, index).concat(messages.slice(index + 1)))
  }

  return (
    <div>
      {flashMessages.map(([type, content], index) => (
        <div key={index} className={`o-notification is-toggle-active o-notification--${type}`}>
          <div>
            {typeof content === 'object' ? (
              <>
                <h5 className='h5--default-margin'>{content.title}</h5>
                {content.description}
              </>
            ) : (
              content
            )}
          </div>
          <div
            className={`o-notification__close o-notification__close--${type}`}
            data-testid='close-btn'
          >
            <FontAwesomeIcon icon={faXmark} onClick={handleClose(index)} />
          </div>
        </div>
      ))}
    </div>
  )
}
