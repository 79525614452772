/* eslint-disable react/forbid-dom-props */
/* eslint-disable react/forbid-component-props */
/* eslint-disable react/jsx-no-bind */
import { parseISO, format } from 'date-fns'
import { Formik, Form, ErrorMessage } from 'formik'
import React from 'react'
import DatePicker from 'react-datepicker'

import { MedicalRecordEntryType as EntryType } from 'components/features/MedicalRecord/MedicalRecordEntry'
import { DatePickerHeader } from 'components/shared/DatePickers/DatePickerHeader'
import { SearchableSelect, SearchableSelectOption } from 'components/shared/SearchableSelect'
import { t, getCurrentLocale } from 'i18n'

import DescriptionField from './DescriptionField'
import { FormButtons } from './FormButtons'

import { TextValues, FormType } from './'

interface TextFormProps {
  entryTypes: EntryType[] | null
  onSubmit: (textEntry: TextValues) => void
  onCancel: () => void
}

export default function TextForm({ entryTypes, onSubmit, onCancel }: TextFormProps) {
  if (!entryTypes) return null

  const initialValues: TextValues = {
    formType: 'text' as FormType,
    date: format(new Date(), 'yyyy-MM-dd'),
    time: '',
    entryTypeId: '',
    description: ''
  }

  const entryTypeOptions = () => {
    const options = entryTypes.map((entryType) => ({
      value: entryType.id,
      label: t(`MEDICAL_RECORD/${entryType.category_name.toUpperCase()}`)
    }))
    return options.sort((a, b) => (a.label > b.label ? 1 : -1))
  }

  const formValidator = (values: TextValues) => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const errors: any = {}
    if (!values.entryTypeId) {
      errors.entryTypeId = `${t('MEDICAL_RECORD/TYPE')} ${t('formik.errors.empty')}`
    }
    if (!values.description) {
      errors.description = `${t('MEDICAL_RECORD/VALUE')} ${t('formik.errors.empty')}`
    }
    return errors
  }

  return (
    <Formik
      validate={formValidator}
      initialValues={initialValues}
      enableReinitialize
      validateOnChange={false}
      validateOnBlur={false}
      onSubmit={(values) => onSubmit(values)}
    >
      {({ values, setFieldValue, errors }) => (
        <Form>
          <input type='hidden' name='form-type' />
          <div className='s-form-group s-form-group--with-columns s-form-group--full-screen'>
            <div
              className={`s-form-group__column s-form-group__column--fluid-40 ${
                errors.entryTypeId ? 'error' : ''
              }`}
            >
              <label className='o-form-label'>
                <abbr title='required'>*</abbr>
                {t('MEDICAL_RECORD/TYPE')}
              </label>
              <SearchableSelect
                options={entryTypeOptions()}
                placeholder={t('registration.not_selected')}
                onChange={(e: SearchableSelectOption | null) => {
                  setFieldValue('entryTypeId', e && e.value)
                  setFieldValue('description', null)
                }}
                error={!!errors.entryTypeId}
                isClearable
              />
              {errors.entryTypeId && (
                <span className='help-block'>
                  <ErrorMessage name='entryTypeId' />
                </span>
              )}
            </div>
            {values.entryTypeId > 0 && (
              <div
                className={`s-form-group__column s-form-group__column--fluid-40 ${
                  errors.description ? 'error' : ''
                }`}
              >
                <>
                  <label className='o-form-label'>
                    <abbr title='required'>*</abbr>
                    {t('MEDICAL_RECORD/VALUE')}
                  </label>
                  <DescriptionField
                    value={values.description}
                    entryTypeCategoryName={
                      entryTypes.find((e) => e.id === values.entryTypeId)?.category_name
                    }
                    onChange={(newValue) => setFieldValue('description', newValue)}
                    error={!!errors.description}
                  />
                  {errors.description && (
                    <span className='help-block'>
                      <ErrorMessage name='description' />
                    </span>
                  )}
                </>
              </div>
            )}
          </div>

          <div className='s-form-group s-form-group--with-columns s-form-group--full-screen'>
            <div className='s-form-group__column s-form-group__column--fluid-40 t-datepicker t-datepicker--position-relative'>
              <label className='o-form-label'>{t('MEDICAL_RECORD/DATE_OF_MEASUREMENT')}</label>
              <DatePicker
                name='date'
                className='o-text-field'
                dateFormat='P'
                selected={parseISO(values.date)}
                locale={getCurrentLocale()}
                popperPlacement='bottom'
                popperModifiers={[
                  {
                    name: 'flip',
                    options: {
                      fallbackPlacements: ['bottom']
                    }
                  }
                ]}
                onChange={(date) => {
                  // Years with more than 4 digits break the `format` call
                  const maxYear = 9999
                  if (date && date instanceof Date && date.getFullYear() <= maxYear) {
                    // we need to remove the time part of date to avoid ISO or UTC parsing
                    setFieldValue('date', format(date, 'yyyy-MM-dd'))
                  }
                }}
                renderCustomHeader={({
                  date,
                  changeYear,
                  decreaseMonth,
                  increaseMonth,
                  prevMonthButtonDisabled,
                  nextMonthButtonDisabled
                }) => {
                  return (
                    <DatePickerHeader
                      date={date}
                      changeYear={changeYear}
                      decreaseMonth={decreaseMonth}
                      increaseMonth={increaseMonth}
                      prevMonthButtonDisabled={prevMonthButtonDisabled}
                      nextMonthButtonDisabled={nextMonthButtonDisabled}
                    />
                  )
                }}
              />
            </div>
            <div className='s-form-group__column s-form-group__column--fluid-40 t-datepicker t-datepicker--position-relative t-datepicker--time'>
              <label className='o-form-label'>{t('MEDICAL_RECORD/TIME_OF_MEASUREMENT')}</label>
              <DatePicker
                name='time'
                className='o-text-field'
                locale={getCurrentLocale()}
                dateFormat='p'
                timeIntervals={5}
                timeCaption={t('zeit')}
                showTimeSelect
                showTimeSelectOnly
                selected={(values.time && new Date(values.time)) || null}
                onChange={(time) => setFieldValue('time', time)}
              />
            </div>
          </div>
          <FormButtons onCancel={onCancel} />
        </Form>
      )}
    </Formik>
  )
}
