import React from 'react'

interface Props {
  label: string
  sublabel?: string
  children?: React.ReactNode
}

export const Empty: React.FC<Props> = ({ label, sublabel, children }) => {
  return (
    <div className='f-user-area-state-page'>
      <div className='f-user-area-state-page__label h-margin-bottom--24'>{label}</div>
      <div className='f-user-area-state-page__sublabel h-margin-bottom--24'>{sublabel}</div>
      {children}
    </div>
  )
}
