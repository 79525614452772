/* eslint-disable @typescript-eslint/no-use-before-define */
import { faCalendarPlus } from '@fortawesome/pro-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React from 'react'
import styled from 'styled-components'

import { t } from 'i18n'
import doctorPatientImg from 'images/doctorPatient.png'

import { Template } from './Template'
import { BaseContent, colors } from './styles'

export const NoContacts: React.FC = () => {
  return (
    <Template>
      <Content>
        <InnerContent>
          <Image src={doctorPatientImg} />
          <StrongText>{t('conversation.meet_doctor')}</StrongText>
          <Text>{t('conversation.message_doctor')}</Text>
          <Button data-testid='chat-book-appointment' href='/searches'>
            <FontAwesomeIcon icon={faCalendarPlus} />
            <ButtonText>{t('conversation.book_appointment')}</ButtonText>
          </Button>
        </InnerContent>
      </Content>
    </Template>
  )
}

// styles
// TODO: remove any after styled-compoennts have been updated to version 5.
// https://github.com/microsoft/TypeScript/issues/37597#issuecomment-628149946
// eslint-disable-next-line @typescript-eslint/no-explicit-any
const Content = styled<any>(BaseContent)`
  min-height: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 1;
`

const InnerContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 16px 0;
`

const Image = styled.img`
  width: 176px;
  height: 171px;
  margin-bottom: 12px;
`

const BaseText = styled.p`
  font-weight: 600;
  font-size: 18px;
  line-height: 24px;
  color: ${colors.grey8};
  text-align: center;
  margin-bottom: 16px;
`

// TODO: remove any after styled-compoennts have been updated to version 5.
// https://github.com/microsoft/TypeScript/issues/37597#issuecomment-628149946
// eslint-disable-next-line @typescript-eslint/no-explicit-any
const StrongText = styled<any>(BaseText)`
  width: 250px;
  margin: 0;
`

// TODO: remove any after styled-compoennts have been updated to version 5.
// https://github.com/microsoft/TypeScript/issues/37597#issuecomment-628149946
// eslint-disable-next-line @typescript-eslint/no-explicit-any
const Text = styled<any>(BaseText)`
  font-weight: 400;
  opacity: 0.5;
`

const Button = styled.a`
  display: inline-flex;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
  border: 1px solid ${colors.blue3};
  border-radius: 5px;
  padding: 0 16px;
  height: 32px;
  font-size: 14px;
  line-height: 14px;
  color: white;
  font-weight: 600;
  white-space: nowrap;
  background-color: ${colors.blue3};
  cursor: pointer;
  text-decoration: none;
  user-select: none;

  &:hover {
    border: 1px solid ${colors.blue2};
    background-color: ${colors.blue2};
  }

  &:active {
    border: 1px solid #104080;
    background-color: #104080;
  }
`

const ButtonText = styled.span`
  margin-left: 8px;
`
