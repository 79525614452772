import { useCallback, useEffect, useState } from 'react'

import { CryptorWithKeys } from 'components/features/Cryptor/models/CryptorService'
import fetchCryptorService from 'components/features/Cryptor/services/fetchCryptorService'

interface GenericResult {
  authorize: (password: string) => Promise<boolean>
  isInitialized: boolean
}

interface UnauthorizedResult extends GenericResult {
  cryptor: null
}

interface AuthorizedResult extends GenericResult {
  cryptor: CryptorWithKeys
}

export const useCrypto = (): UnauthorizedResult | AuthorizedResult => {
  const [isInitialized, setIsInitialized] = useState<boolean>(false)
  const [cryptor, setCryptor] = useState<CryptorWithKeys | null>(null)

  const authorize = useCallback(async (password) => {
    try {
      const cryptorService = await fetchCryptorService()
      const cryptor = await cryptorService.setUpFromPassword(password)
      setCryptor(cryptor)
      return cryptor !== null
    } catch {
      setCryptor(null)
      return false
    }
  }, [])

  useEffect(() => {
    const attemptLoginFromSession = async () => {
      try {
        const cryptorService = await fetchCryptorService()
        const cryptor = await cryptorService.setUpFromSession()
        setCryptor(cryptor)
        setIsInitialized(true)
      } catch {
        setCryptor(null)
        setIsInitialized(true)
      }
    }
    attemptLoginFromSession()
  }, [])

  return {
    authorize,
    isInitialized,
    cryptor
  }
}
