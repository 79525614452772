import PatientFormDecrypted from '../models/PatientFormDecrypted'

import fetchFormTypeStructure from './fetchFormTypeStructure'
import fetchPrintTemplate from './fetchPrintTemplate'

export default async function exportToDocx(decryptedForm: PatientFormDecrypted) {
  const formDataAsObject = decryptedForm.getFormDataAsObject()
  const templateContent = await fetchPrintTemplate(decryptedForm.id)
  const formTypeStructure = await fetchFormTypeStructure(decryptedForm.id)

  const templateData = {
    ...formDataAsObject,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    _sections: formTypeStructure.map((section: any) => {
      return {
        ...section,
        hasFields: section.fields.length > 0,
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        fields: section.fields.map((field: any) => {
          const sanitizedId = field.id.replace(/[-]/g, '').toLowerCase()
          return { ...field, sanitizedId, value: formDataAsObject[sanitizedId] }
        })
      }
    })
  }

  const { docxtemplater } = await import('services/docxtemplater')
  const doc = await docxtemplater(templateContent)
  doc.setData(templateData)

  try {
    // render the document (replace all occurences of {first_name} by John, {last_name} by Doe, ...)
    doc.render()
  } catch (error) {
    const e = {
      message: error.message,
      name: error.name,
      stack: error.stack,
      properties: error.properties
    }
    console.log(JSON.stringify({ error: e }))

    // The error thrown here contains additional information when logged with JSON.stringify (it contains a property object).
    throw error
  }

  return doc.getZip().generate({
    type: 'blob',
    mimeType: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document'
  })
}
