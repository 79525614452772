import { Cryptor } from '@samedi/crypto-js'

import { buildSessionKeyCryptor } from 'services/encryption'

import PatientForm, { EncryptedAttachment } from '../models/PatientForm'
import PatientFormDecrypted, { DecryptedAttributes } from '../models/PatientFormDecrypted'

/**
 * Decrypt a PatientForm and its attributes and returns the corresponding PatientFormDecrypted object.
 */
export default async function decryptPatientForm(
  patientForm: PatientForm,
  cryptor: Cryptor
): Promise<PatientFormDecrypted> {
  const decryptedAttachments = await Promise.all(
    // eslint-disable-next-line @typescript-eslint/no-use-before-define
    patientForm.attachments.map((attachment) => decryptAttachment(attachment, cryptor))
  )

  const decryptedAttributes: DecryptedAttributes = {
    attachments: decryptedAttachments
  }

  let sessionKeyCryptor
  const { sessionKey, formData } = patientForm.getAttributes()

  if (sessionKey) {
    sessionKeyCryptor = await buildSessionKeyCryptor(sessionKey as string, cryptor)

    decryptedAttributes.sessionKey = sessionKeyCryptor.getDecryptedSessionKeyBytes()
    decryptedAttributes.formData = formData && JSON.parse(await sessionKeyCryptor.decrypt(formData))
  }

  return new PatientFormDecrypted(
    patientForm.getAttributes(),
    decryptedAttributes,
    sessionKeyCryptor
  )
}

async function decryptAttachment(attachment: EncryptedAttachment, cryptor: Cryptor) {
  const metaDataJSON = await cryptor.decrypt(attachment.encrypted_meta_data)
  // Some encrypted meta data contains unescaped control characters like "\u0008" which is invalid JSON, see PAPP-2785
  const metaDataWithoutControlChars = metaDataJSON.replace(/[\u0000-\u001f]+/g, '_')
  const metaData = JSON.parse(metaDataWithoutControlChars)

  return {
    id: attachment.id,
    file_name: metaData.file_name,
    file_size: metaData.file_size,
    file_type: metaData.file_type
  }
}
