import React from 'react'

import { t } from 'i18n'

import FormsTable from './FormsTable'
import { Attributes } from './models/PatientForm'

interface Props {
  forms: Attributes[]
}

const CompletedFormsTable: React.FC<Props> = function ({ forms }: Props) {
  forms.sort((a, b): number => ((a.completedAt as string) > (b.completedAt as string) ? -1 : 1))

  return (
    <div className='f-forms'>
      <div className='h-margin-bottom--16'>
        <h4 className='h4--default-margin'>{t('forms.completed')}</h4>
      </div>
      <FormsTable forms={forms} />
    </div>
  )
}

export default CompletedFormsTable
