/* eslint-disable @typescript-eslint/no-use-before-define */
import {
  faPaperclip,
  faUniversalAccess,
  faExclamationCircle
} from '@fortawesome/pro-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import classNames from 'classnames'
import React from 'react'

import { t } from 'i18n'

import { DecryptedMedicalRecordEntry } from '../MedicalRecordEntry'

interface Props {
  entry: DecryptedMedicalRecordEntry
  isLarge?: boolean
  isClickable?: boolean
}

export const CategoryName: React.FC<Props> = function ({ entry, isLarge, isClickable }) {
  if (entry.type === 'text' && !entry.entry_type) {
    return <span></span>
  }

  const { icon, text } = getIconText(entry)

  return (
    <span className={composeClasses('name', isLarge, isClickable)}>
      <span className={composeClasses('icon', isLarge, isClickable)}>
        <FontAwesomeIcon icon={icon} />
      </span>
      <span>{text}</span>
    </span>
  )
}

export const CategoryIcon: React.FC<Props> = function ({ entry }) {
  const { icon } = getIconText(entry)

  return (
    <span className={composeClasses('name')}>
      <span className={composeClasses('icon')}>
        <FontAwesomeIcon icon={icon} />
      </span>
    </span>
  )
}

export const CategoryText: React.FC<Props> = function ({ entry }) {
  const { text } = getIconText(entry)

  return (
    <span className={composeClasses('name')}>
      <span>{text}</span>
    </span>
  )
}

export const getCategoryText = (entry: DecryptedMedicalRecordEntry) => {
  const { text } = getIconText(entry)
  return text
}

function composeClasses(type: string, isLarge?: boolean, isClickable?: boolean): string {
  const baseClass = `c-medical-record-entries__category-${type}`
  return classNames(
    baseClass,
    { [`${baseClass}--large`]: isLarge },
    { [`${baseClass}--clickable`]: isClickable }
  )
}

function getIconText(entry: DecryptedMedicalRecordEntry) {
  const icons = {
    text: faUniversalAccess,
    icd10: faUniversalAccess,
    file: faPaperclip,
    warning: faExclamationCircle
  }
  const text = {
    text: t(`MEDICAL_RECORD/${entry.entry_type?.category_name.toUpperCase()}`),
    icd10: t('MEDICAL_RECORD/DIAGNOSE'),
    file: t('MEDICAL_RECORD/FILE')
  }

  return {
    icon: entry.data_undecryptable ? icons.warning : icons[entry.type],
    text: text[entry.type]
  }
}
