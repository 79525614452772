import axios from 'axios'

export const start2FAActivation = () => axios.post('/settings/second_factor')
export const start2FADeactivation = () => axios.post('/settings/second_factor/deactivate')

export const finish2FAActivation = (otpToken: string) =>
  axios.post('/settings/second_factor', { otp_token: otpToken })

export const finish2FADeactivation = (otpToken: string) =>
  axios.post('/settings/second_factor/deactivate', { otp_token: otpToken })
