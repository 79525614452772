import React from 'react'

import { t } from 'i18n'

import { RequestsTable, Props as TableProps } from './RequestsTable/RequestsTable'
import { Props as MobileTableProps, RequestsTableMobile } from './RequestsTable/RequestsTableMobile'

interface Props {
  isMobile: boolean
}

export type ListViewProps = TableProps & MobileTableProps & Props

export const SendRequestsListView: React.FC<ListViewProps> = function ({
  entries,
  onAcceptEntry,
  onRejectEntry,
  isMobile
}) {
  return (
    <div className='h-margin-bottom--48'>
      <h4>
        {entries.length} {t('MEDICAL_RECORD/RECEIVED_ENTRIES')}
      </h4>
      {isMobile ? (
        <RequestsTableMobile
          entries={entries}
          onAcceptEntry={onAcceptEntry}
          onRejectEntry={onRejectEntry}
        />
      ) : (
        <RequestsTable
          entries={entries}
          onAcceptEntry={onAcceptEntry}
          onRejectEntry={onRejectEntry}
        />
      )}
    </div>
  )
}
