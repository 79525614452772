import React from 'react'

import { t } from 'i18n'

import { CryptorWithKeys } from '../../Cryptor/models/CryptorService'
import { EntryDateTime } from '../EntryDateTime'

import { DecryptedMedicalRecordEntry } from '../MedicalRecordEntry'

import { CategoryName } from './CategoryName'
import { Description } from './Description'
import { ExpandIcon } from './ExpandIcon'
import { SentToInstitutionsList } from './SentToInstitutionsList'

interface Props {
  entry: DecryptedMedicalRecordEntry
  cryptorWithKeys: CryptorWithKeys
  toggleExpanded: () => void
}

export const EntryRowMobile: React.FC<Props> = ({ entry, cryptorWithKeys, toggleExpanded }) => {
  return (
    <>
      <div className='c-medical-record-entries__data'>
        <div>
          <CategoryName entry={entry} isClickable />
          <div className='c-medical-record-entries__date-icon-container'>
            <EntryDateTime entryDate={entry.created_at} />
            <ExpandIcon onClick={toggleExpanded} />
          </div>
        </div>
        <div className='c-medical-record-entries__info-container'>
          <div className='h-word-break'>
            <Description entry={entry} cryptorWithKeys={cryptorWithKeys} />
          </div>
          <div className='c-medical-record-entries__creator-name h-word-break'>
            {!entry.created_by_doctor ? t('MEDICAL_RECORD/ME') : entry.creator_name}
          </div>
          <div>
            <SentToInstitutionsList entry={entry} />
          </div>
        </div>
      </div>
    </>
  )
}
