import { faTrashCan } from '@fortawesome/pro-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { MouseEvent, useCallback } from 'react'

type Props = {
  entryId: string | number
  onDeleteEntry: (entryId: string | number) => void
}

export const DeleteIcon: React.FC<Props> = ({ entryId, onDeleteEntry }) => {
  const handleClick = useCallback(
    (e: MouseEvent<HTMLSpanElement>): void => {
      // to prevent the whole row click expansion
      e.stopPropagation()
      onDeleteEntry(entryId)
    },
    [entryId, onDeleteEntry]
  )

  return (
    <span
      className='o-clickable c-medical-record-entries__icon'
      data-testid='c-medical-record-entries__delete-icon'
      onClick={handleClick}
    >
      <FontAwesomeIcon icon={faTrashCan} />
    </span>
  )
}
