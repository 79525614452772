/* eslint-disable @typescript-eslint/no-use-before-define */
import { faFileCertificate } from '@fortawesome/pro-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React from 'react'
import styled from 'styled-components'

export const SignatureInformation: React.FC<{ signature: string }> = ({ signature }) => {
  return (
    <>
      {signature && (
        <SignatureWrapper data-testid='signatureInformationComponent'>
          <FontAwesomeIcon icon={faFileCertificate} />
          {/*eslint-disable-next-line react/no-danger-with-children*/}
          <SignatureText dangerouslySetInnerHTML={{ __html: signature }}>{}</SignatureText>
        </SignatureWrapper>
      )}
    </>
  )
}

const SignatureWrapper = styled.div`
  border-radius: 4px;
  padding: 12px;
  background-color: rgba(42, 181, 102, 0.15);
  margin-bottom: 20px;
  display: flex;
  flex-direction: row;
  align-items: center;
  align-self: stretch;
  gap: 16px;

  .fa-file-certificate {
    color: #2ab566;
  }
`

const SignatureText = styled.div`
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
`
