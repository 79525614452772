import React, { useState } from 'react'

import { ConversationColumnContainer } from '../containers/ConversationColumnContainer'
import { ActiveColumn } from '../containers/ConversationsStateContainer'
import { ContactWithMessage } from '../types'

import { ConversationListColumn } from './ConversationListColumn'
import { NoContacts } from './NoContacts'
import { Template } from './Template'
import { BaseContent as Content } from './styles'

// components
export const ConversationsScreen: React.FC<{
  contacts: ContactWithMessage[]
  handleSelectConversation: (conversationId: string) => void
  handleSubmitMessage: (conversationId: string) => void
  selectedContact?: ContactWithMessage
  handleReturnToConversationList: () => void
  activeColumn: ActiveColumn
}> = function ({
  contacts,
  handleSelectConversation,
  handleSubmitMessage,
  selectedContact,
  handleReturnToConversationList,
  activeColumn
}) {
  const [unreadMessagesCount] = useState<number | undefined>(selectedContact?.unread_messages_count)

  if (contacts?.length === 0) {
    return <NoContacts />
  }

  return (
    <Template>
      <Content>
        <ConversationListColumn
          contacts={contacts}
          handleSelectConversation={handleSelectConversation}
          selectedContactId={selectedContact?.practice_id}
          isActive={activeColumn === 'conversationListColumn'}
        />
        <ConversationColumnContainer
          unreadMessagesCount={unreadMessagesCount}
          contactName={selectedContact?.practice_name}
          contactId={selectedContact?.practice_id}
          handleSubmitMessage={handleSubmitMessage}
          onReturnButtonClick={handleReturnToConversationList}
          isActive={activeColumn === 'conversationColumn'}
        />
      </Content>
    </Template>
  )
}
