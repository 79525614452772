/* eslint-disable react/forbid-dom-props */
import { useQuery } from '@tanstack/react-query'
import React from 'react'

import { Empty } from 'components/shared/StatePages'

import { t } from 'i18n'

import CompletedFormsTable from './CompletedFormsTable'
import UncompletedFormsTable from './UncompletedFormsTable'
import { Attributes } from './models/PatientForm'
import fetchAllPatientForms from './services/fetchAllPatientForms'

export default function FormsList() {
  const { data: forms } = useQuery(['allForms'], fetchAllPatientForms, {})

  const uncompletedForms: Attributes[] = []
  const completedForms: Attributes[] = []

  if (!forms) return null

  forms.forEach((form) => {
    const attributes: Attributes = form.getAttributes()
    attributes.completedAt ? completedForms.push(attributes) : uncompletedForms.push(attributes)
  })

  return (
    <>
      <h2 className='f-dashboard__header-title'>{t('patient_forms.index.title')}</h2>
      {!forms.length ? (
        <Empty
          label={t('patient_forms.index.empty_label')}
          sublabel={t('patient_forms.index.empty_sublabel')}
        />
      ) : (
        <>
          {uncompletedForms.length > 0 && <UncompletedFormsTable forms={uncompletedForms} />}
          {completedForms.length > 0 && <CompletedFormsTable forms={completedForms} />}
        </>
      )}
    </>
  )
}
