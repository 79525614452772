import { faChevronLeft, faChevronRight } from '@fortawesome/pro-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import classNames from 'classnames'
import { subYears, subDays } from 'date-fns'
import React from 'react'

import { ReactDatePickerCustomHeaderProps } from 'react-datepicker'

import { dateFormat } from 'i18n'

import { DatePickerSelectField as Select } from './DatePickerSelectField'

export type Props = Pick<
  ReactDatePickerCustomHeaderProps,
  | 'date'
  | 'changeYear'
  | 'decreaseMonth'
  | 'increaseMonth'
  | 'prevMonthButtonDisabled'
  | 'nextMonthButtonDisabled'
>

export const DatePickerHeader: React.FC<Props> = ({
  date,
  changeYear,
  decreaseMonth,
  increaseMonth,
  prevMonthButtonDisabled,
  nextMonthButtonDisabled
}) => {
  const yearsRange = () => {
    const startYear = parseInt(dateFormat(subYears(new Date(), 100), 'yyyy') as string)
    const endYear = parseInt(dateFormat(subDays(new Date(), 1), 'yyyy') as string)
    const range = []
    for (let year = startYear; year <= endYear; year++) {
      range.push({ value: year, label: year })
    }

    return range
  }

  const initialYear = {
    label: parseInt(dateFormat(date, 'yyyy') as string),
    value: parseInt(dateFormat(date, 'yyyy') as string)
  }

  return (
    <div className='react-datepicker__birthdate'>
      <div className='react-datepicker__birthmonth'>
        <div
          className={classNames('react-datepicker__birthmonth-chevron', {
            disabled: prevMonthButtonDisabled
          })}
          data-testid='datepicker-previous-month'
          onClick={!prevMonthButtonDisabled ? decreaseMonth : undefined}
        >
          <FontAwesomeIcon icon={faChevronLeft} />
        </div>
        <div className='react-datepicker__birthmonth-name' data-testid='datepicker-selected-month'>
          {dateFormat(date, 'MMMM')}
        </div>
        <div
          className={classNames('react-datepicker__birthmonth-chevron', {
            disabled: nextMonthButtonDisabled
          })}
          data-testid='datepicker-next-month'
          onClick={!nextMonthButtonDisabled ? increaseMonth : undefined}
        >
          <FontAwesomeIcon icon={faChevronRight} />
        </div>
      </div>
      <Select
        value={initialYear}
        options={yearsRange()}
        className='react-datepicker__birthyear react-datepicker-select__container'
        // eslint-disable-next-line react/jsx-no-bind
        onChange={(selectedOptions) => changeYear(selectedOptions.value)}
      />
    </div>
  )
}
