import { Cryptor } from '@samedi/crypto-js'
import { loadSessionKey } from '@samedi/crypto-js/subtle/symmetric'
import { str2ab } from '@samedi/crypto-js/utils'

import SessionKey from './SessionKey'

/**
 * Creates and initializes new random SessionKey object and returns it.
 */
export default async function decryptSessionKey(
  encryptedSessionKey: string,
  cryptor: Cryptor
): Promise<SessionKey> {
  // eslint-disable-next-line @typescript-eslint/no-use-before-define
  const sessionKeyBytes = await decryptSessionKeyToBytes(encryptedSessionKey, cryptor)
  const sessionKey = await loadSessionKey(sessionKeyBytes)

  return new SessionKey(sessionKey, sessionKeyBytes)
}

async function decryptSessionKeyToBytes(
  encryptedSessionKey: string,
  cryptor: Cryptor
): Promise<Uint8Array> {
  const decryptedSessionKey = await cryptor.decryptToBuffer(encryptedSessionKey)

  // SI-7180, we have such a weird utf-8 encoded key
  // decode bytes as utf-8
  // drop the high bytes (i think)
  if (decryptedSessionKey.byteLength > 32) {
    const decoder = new TextDecoder()
    const string = decoder.decode(decryptedSessionKey)
    return str2ab(string)
  }

  return decryptedSessionKey
}
