/* eslint-disable react/forbid-dom-props */
import React from 'react'

import { SearchableSelect, SearchableSelectOption } from 'components/shared/SearchableSelect'
import { t } from 'i18n'

type DescriptionFieldChangeEventHandler = (o: string | null) => void

interface DescriptionFieldProps {
  value: string
  entryTypeCategoryName: string | undefined
  onChange: DescriptionFieldChangeEventHandler
  error: boolean
}

export default function DescriptionField({
  value,
  entryTypeCategoryName,
  onChange,
  error
}: DescriptionFieldProps) {
  let options: SearchableSelectOption[] | undefined

  if (entryTypeCategoryName === 'blood_group') {
    options = [
      { value: 'A', label: 'A' },
      { value: 'B', label: 'B' },
      { value: 'AB', label: 'AB' },
      { value: '0', label: '0' }
    ]
  }

  if (entryTypeCategoryName === 'organ_donor' || entryTypeCategoryName === 'hearing_aid') {
    options = [
      { value: 'true', label: t('MEDICAL_RECORD/YES') },
      { value: 'false', label: t('MEDICAL_RECORD/NO') }
    ]
  }

  if (entryTypeCategoryName === 'rh_factor') {
    options = [
      { value: '+', label: '+' },
      { value: '-', label: '-' }
    ]
  }

  if (options) {
    const selectedOption: SearchableSelectOption | undefined = options.find(
      (o) => o.value === value
    )
    return (
      <SearchableSelect
        name='description'
        value={selectedOption}
        placeholder={t('registration.not_selected')}
        // eslint-disable-next-line react/jsx-no-bind
        onChange={(o: SearchableSelectOption) => onChange(o && o.value.toString())}
        options={options}
        error={error}
        isClearable
      />
    )
  } else {
    const showGlassesHint =
      entryTypeCategoryName === 'glasses' || entryTypeCategoryName === 'contact_lenses'
    return (
      <>
        <input
          name='description'
          value={value || ''}
          type='text'
          className='string o-text-field'
          // eslint-disable-next-line react/jsx-no-bind
          onChange={(e) => onChange(e.currentTarget.value)}
        />
        {showGlassesHint && (
          <div className='o-form-field-note'>{t('MEDICAL_RECORD/GLASSES_HINT')}</div>
        )}
      </>
    )
  }
}
