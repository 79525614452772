import React from 'react'

import { CryptorUnlocker } from 'components/features/Cryptor'
import { CryptorContext } from 'components/features/Cryptor/CryptorContext'
import { PasswordForm, PasswordSubmissionFunction } from 'components/shared/PasswordForm'
import { t } from 'i18n'

import FormsList from './FormsList'

const FormsListContainer: React.FC = function () {
  return (
    <div>
      {/*eslint-disable-next-line @typescript-eslint/no-use-before-define*/}
      <CryptorUnlocker passwordForm={passwordForm}>
        {(cryptorWithKeys) => (
          <CryptorContext.Provider value={cryptorWithKeys}>
            <FormsList />
          </CryptorContext.Provider>
        )}
      </CryptorUnlocker>
    </div>
  )
}

interface PasswordProps {
  onSubmitPassword: PasswordSubmissionFunction
}

const passwordForm: React.FC<PasswordProps> = function ({ onSubmitPassword }) {
  return (
    <PasswordForm
      onSubmit={onSubmitPassword}
      title={t('forms.unlock')}
      lockReason={t('forms.locked_reason')}
      unlockErrorMessage={t('forms.unlock_error_incorrect_password')}
      descriptionHTML={t('forms.unlock_description')}
    />
  )
}

export default FormsListContainer
