import { isBefore, parseISO } from 'date-fns'

import React from 'react'

import { t } from 'i18n'

import FormsTable from './FormsTable'
import { Attributes } from './models/PatientForm'

interface Props {
  forms: Attributes[]
}

/*
 * There are three form types (regarding their positions in the table).
 * 1. Forms for upcoming appointments lie on the top of the table.
 *    They are sorted by appointment time (ASC), appointment id (DESC) and the form title (ASC)
 * 2. Forms that have no appointment related come next.
 *    They are sorted by form title (ASC)
 * 3. Forms for expired appointments are put on the bottom of the table.
 *    They are sorted by appointment time (DESC), appointment id (DESC) and the form title (ASC)
 */
export const sortForms = (forms: Attributes[]): Attributes[] => {
  const convertTimeForCompare = (startsAt: string | null | undefined): number => {
    if (startsAt) {
      const parsedStartsAt = parseISO(startsAt)
      const currentTime = new Date()

      if (isBefore(parsedStartsAt, currentTime)) {
        return parsedStartsAt.getTime() * -1
      } else {
        return parsedStartsAt.getTime()
      }
    }

    return 0
  }

  const appointmentTimeDiff = (
    startsAtA: string | null | undefined,
    startsAtB: string | null | undefined
  ) => {
    const appointmentStartsAtForA = convertTimeForCompare(startsAtA)
    const appointmentStartsAtForB = convertTimeForCompare(startsAtB)

    if (Math.sign(appointmentStartsAtForA * appointmentStartsAtForB) === 1) {
      return appointmentStartsAtForA - appointmentStartsAtForB
    }

    return appointmentStartsAtForB - appointmentStartsAtForA
  }

  const sortedForms = forms.sort((a: Attributes, b: Attributes) => {
    const appointmentIdForA = a.appointmentId || 0
    const appointmentIdForB = b.appointmentId || 0

    return (
      appointmentTimeDiff(a.appointmentStartsAt, b.appointmentStartsAt) ||
      appointmentIdForB - appointmentIdForA ||
      a.title.localeCompare(b.title)
    )
  })

  return sortedForms
}

const UncompletedFormsTable: React.FC<Props> = function ({ forms }) {
  return (
    <div className='f-forms h-margin-bottom--24'>
      <div className='h-margin-bottom--16'>
        <h4>
          {`${forms.length} ${
            forms.length > 1 ? t('forms.forms_to_be_filled') : t('forms.form_to_be_filled')
          }`}
        </h4>
        <div
          className='h-color--grey-50'
          dangerouslySetInnerHTML={{ __html: t('forms.signed_requiring_filled_html') }}
        ></div>
      </div>
      <FormsTable forms={sortForms(forms)} />
    </div>
  )
}

export default UncompletedFormsTable
