import { utf8StringToBytes } from '@samedi/crypto-js/utils'
import { fromByteArray as encode64 } from 'base64-js'
import React from 'react'

interface OrbeonFormProps {
  path: string
  encodedData: EncodedData | null
}

interface EncodedData {
  data: string
  defaults?: string
}

/**
 * Component responsible to render an Orbeon form based in the provided form data.
 */
export default class OrbeonForm extends React.Component<OrbeonFormProps> {
  constructor(props: OrbeonFormProps) {
    super(props)
    this.adjustIframeHeight = this.adjustIframeHeight.bind(this)
    this.adjustIframeHeightAndScrollToTop = this.adjustIframeHeightAndScrollToTop.bind(this)
  }

  render() {
    const { path, encodedData } = this.props

    return (
      <>
        <form
          id='editForm'
          target='orbeon-form'
          action={path}
          method='POST'
          style={{ display: 'none' }}
        >
          <input type='hidden' name='form_data' value={this.buildOrbeonFormPayload(encodedData)} />
        </form>
        <iframe
          id='orbeonFormIframe'
          className='patient-form__iframe'
          name='orbeon-form'
          width='100%'
          height='0'
        ></iframe>
      </>
    )
  }

  componentDidMount() {
    ;(document.getElementById('editForm') as HTMLFormElement).submit()

    const orbeonFormIframe = this.getIFrame()

    if (orbeonFormIframe) {
      orbeonFormIframe.onload = this.adjustIframeHeightAndScrollToTop
      window.addEventListener('resize', this.adjustIframeHeight)
    }
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.adjustIframeHeight)
  }

  adjustIframeHeightAndScrollToTop() {
    this.adjustIframeHeight()
    this.scrollOrbeonFormToTop()
  }

  adjustIframeHeight() {
    const orbeonFormIframe = this.getIFrame()
    const screenHeight: number = window.innerHeight
    const logo = document.getElementsByClassName('c-top-menu__logo').item(0) as HTMLElement | null
    let logoHeight = 0
    const formBottomMargin = 100

    if (!orbeonFormIframe) return

    if (logo && logo.offsetHeight) {
      logoHeight = logo.offsetHeight
    }

    orbeonFormIframe.style.height = `${screenHeight - logoHeight - formBottomMargin}px`
  }

  scrollOrbeonFormToTop() {
    const orbeonFormIframe = this.getIFrame()

    if (!orbeonFormIframe) return

    orbeonFormIframe.contentWindow?.scrollTo(0, 0)
  }

  async isValid() {
    await this.getCurrentFormDataPlain() // to trigger orbeon validation
    const iFrameWindow = this.getIFrameWindow()
    if (iFrameWindow) {
      const orbeonFormDocument = iFrameWindow.document
      const errorSummary = orbeonFormDocument.querySelector('.xbl-fr-error-summary')

      if (!errorSummary) return true

      const errors = errorSummary.querySelector('.fr-error-summary-body')
      return errors === null
    }
  }

  async getCurrentFormDataEncodedString(): Promise<string> {
    const encodedData = await this.getCurrentFormDataEncoded()

    return JSON.stringify(encodedData)
  }

  async getCurrentFormDataEncoded(): Promise<EncodedData> {
    const plainXmlData = await this.getCurrentFormDataPlain()

    return { data: encode64(utf8StringToBytes(plainXmlData)) }
  }

  async getCurrentFormDataPlain(): Promise<string> {
    return new Promise((resolve) => {
      this.subscribeToEvent('instanceUpdated', resolve)
      this.dispatchOrbeonEvent('si-update-instance')
    })
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  private subscribeToEvent(eventName: string, callback: (args: any) => void, scope = null) {
    const iWindow = this.getIFrameWindow()

    if (iWindow) {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const cb = function (_: any, args: any) {
        iWindow.si.events[eventName].unsubscribe(cb)
        callback.apply(scope, args)
      }

      if (!iWindow.si.events.hasOwnProperty(eventName)) {
        throw new Error(`Unkown orbeon event "${eventName}" in si.events`)
      }

      iWindow.si.events[eventName].subscribe(cb)
    }
  }

  private dispatchOrbeonEvent(eventName: string, targetId = 'fr-form-model') {
    const iWindow = this.getIFrameWindow()

    if (iWindow) {
      iWindow.ORBEON.xforms.Document.dispatchEvent({
        targetId: targetId,
        eventName: eventName
      })
    }
  }

  private getIFrameWindow() {
    const iFrame = this.getIFrame()

    if (!iFrame) return null

    return iFrame.contentWindow as OrbeonIframeWindow | null
  }

  private getIFrame() {
    return document.getElementById('orbeonFormIframe') as HTMLIFrameElement | null
  }

  private buildOrbeonFormPayload(encodedData: EncodedData | null): string {
    let data, defaults

    if (encodedData) {
      data = encodedData.data
      defaults = encodedData.defaults
    }

    let payload = `fr-form-data=${encodeURIComponent(data as string)}`

    if (defaults) {
      payload += `&defaults=${encodeURIComponent(defaults)}`
    }

    return payload
  }
}
