import React, { useEffect, useState } from 'react'

import {
  DecryptedMedicalRecordEntry,
  InstitutionBasicData,
  PublicKey
} from 'components/features/MedicalRecord/MedicalRecordEntry'
import { t } from 'i18n'

import { ContactInstitution, InstitutionSelectorItem } from './InstitutionSelectorItem'

export interface ShareSuccess {
  shared: boolean
  isSharing: boolean
  institutionId: number
  entryId: string | number
}

interface Props {
  institutions: ContactInstitution[]
  entry: DecryptedMedicalRecordEntry
  shareSuccess: ShareSuccess
  onSendButtonClick: (
    institutionId: number,
    institutionPublicKey: PublicKey,
    entryId: string | number
  ) => void
}

export function InstitutionSelector({
  institutions,
  entry,
  shareSuccess,
  onSendButtonClick
}: Props) {
  const alreadyHavingEntryInstitutionIds = entry.sent_to_institutions.map(
    (institution: InstitutionBasicData) => institution.id
  )

  if (entry.creator_institution) {
    alreadyHavingEntryInstitutionIds.push(entry.creator_institution.id)
  }

  const [currentSharedItems, setCurrentSharedItems] = useState<number[]>([])

  useEffect(() => {
    if (shareSuccess.shared) {
      setCurrentSharedItems((prevSharedItems) => [...prevSharedItems, shareSuccess.institutionId])
    }
  }, [shareSuccess, entry])

  if (institutions.length === 0) {
    return (
      <div className='h-margin-bottom--16 h-color--grey-40'>
        {t('MEDICAL_RECORD/SEND_ENTRY_MODAL/NO_CONTACT_INSTITUTIONS_MESSAGE')}
      </div>
    )
  }

  return (
    <div className='c-send-entry-modal__institution-selector'>
      {institutions.map((institution: ContactInstitution) => (
        <InstitutionSelectorItem
          key={institution.id}
          institution={institution}
          entryId={entry.id}
          isDisabled={
            (alreadyHavingEntryInstitutionIds &&
              alreadyHavingEntryInstitutionIds.includes(institution.practice_id)) ||
            currentSharedItems.includes(institution.id)
          }
          onSendButtonClick={onSendButtonClick}
          shareSuccess={shareSuccess}
        />
      ))}
    </div>
  )
}
