import axios from 'axios'

import PatientForm from '../models/PatientForm'

export default async function fetchPatientForm(patientFormId: string): Promise<PatientForm> {
  const result = await axios.get(`/patient_forms/${patientFormId}.json`)

  const {
    id,
    completed_at,
    form_data,
    form_id,
    form_title,
    form_type_id,
    practice_id,
    practice_name,
    sent_at,
    session_key,
    attachments_encryption_key_id,
    attachments_size_limit
  } = result.data

  return new PatientForm({
    id,
    completedAt: completed_at,
    formData: form_data,
    formId: form_id,
    title: form_title,
    formTypeId: form_type_id,
    practiceId: practice_id,
    practiceName: practice_name,
    sentAt: sent_at,
    sessionKey: session_key,
    attachmentsEncryptionKeyId: attachments_encryption_key_id,
    attachmentsSizeLimit: attachments_size_limit
  })
}
