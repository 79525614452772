import { FieldProps } from 'formik'

import React from 'react'

import { SearchableSelect, SearchableSelectOption } from 'components/shared/SearchableSelect'

import { ContactPractices } from '../models/ContactPractice'

interface PracticeSelectFieldProps {
  practices: ContactPractices | undefined
  fieldProps: FieldProps
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  setFieldValue: (field: string, value: any) => void
}

export const PracticeSelectField: React.FC<PracticeSelectFieldProps> = function ({
  practices,
  fieldProps,
  setFieldValue
}) {
  const practiceNames = practices?.names || {}
  const selectOptions = Object.keys(practiceNames).map((key) => ({
    value: key,
    label: practiceNames[key]
  }))
  const { value, name } = fieldProps.field

  return (
    <SearchableSelect
      name={name}
      // eslint-disable-next-line react/jsx-no-bind
      onChange={(newOption: SearchableSelectOption) => {
        setFieldValue(name, newOption.value)
      }}
      options={selectOptions}
      value={{ value: value, label: practiceNames[value] }}
    />
  )
}
