import { parseISO, isBefore } from 'date-fns'
import React from 'react'

import { t, dateFormat } from 'i18n'

import { Attributes } from '../models/PatientForm'

export type Props = Pick<Attributes, 'practiceName' | 'appointmentName' | 'appointmentStartsAt'>

const FormIssuer: React.FC<Props> = function ({
  practiceName,
  appointmentName,
  appointmentStartsAt
}) {
  const parsedStartsAt = appointmentStartsAt && parseISO(appointmentStartsAt)
  const formatedStartsAt =
    appointmentStartsAt && dateFormat(appointmentStartsAt, 'dateTimeLongWithWeekDay')
  const currentTime = new Date()

  return (
    <div className='f-forms__institution-wrapper h-word-break h-color--grey-40'>
      <div>
        <span>{t('forms.from')}&nbsp;</span>
        <span className='h-color--grey-30 h-font-weight--600'>{practiceName}</span>
      </div>
      {appointmentName && parsedStartsAt && formatedStartsAt && (
        <div>
          <span>
            {isBefore(parsedStartsAt, currentTime)
              ? t('forms.appointment_past')
              : t('forms.appointment')}
            &nbsp;
          </span>
          <span className='h-font-weight--600'>
            {formatedStartsAt} - {appointmentName}
          </span>
        </div>
      )}
    </div>
  )
}

export default FormIssuer
