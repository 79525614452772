import { Formik, Form, Field, FieldProps } from 'formik'
import React, { useCallback, useRef, useState } from 'react'

import { useNavigate } from 'react-router-dom'

import { NotificationBox } from 'components/shared/NotificationBox'
import { PasswordInput } from 'components/shared/PasswordInput'
import useLogin, { LoginFormValues, OnRejected } from 'hooks/useLogin'
import { t } from 'i18n'

import { isValidEmail } from 'util/isValidEmail'

import { ErrorResponse, isOtpRequiredError } from 'util/login'

function validateEmail(value: string) {
  let error
  if (!value) {
    error = t('formik.errors.empty')
  } else if (!isValidEmail(value)) {
    error = t('formik.errors.email.invalid_format')
  }
  return error
}

function validatePassword(value: string) {
  let error
  if (!value) {
    error = t('formik.errors.empty')
  }
  return error
}

export const LoginForm: React.FC<{}> = function () {
  const formElement: React.MutableRefObject<HTMLFormElement | null> = useRef(null)
  const [flashMessageType, setFlashMessageType] = useState<string | null>(null)
  const [flashMessage, setFlashMessage] = useState<string | null>(null)
  const [showNotificationBox, setShowNotificationBox] = useState<boolean>(false)
  const navigate = useNavigate()
  const { login } = useLogin()

  const handleSubmit = useCallback(
    (values: LoginFormValues) => {
      const handleRejected: OnRejected<ErrorResponse> = (error) => {
        if (error.response?.status === 401 || error.response?.status === 429) {
          const { data } = error.response
          if (!data?.message || !data?.message_type) return

          setFlashMessage(data.message)
          setFlashMessageType(data.message_type)
          setShowNotificationBox(true)
        }

        if (isOtpRequiredError(error)) {
          navigate('/verifycode', {
            state: values
          })
        }

        return error
      }

      login(values, handleRejected)
    },
    [login, navigate]
  )

  return (
    <>
      {showNotificationBox && (
        <NotificationBox
          type={flashMessageType as string}
          message={flashMessage as string}
          // eslint-disable-next-line react/jsx-no-bind
          onClose={() => setShowNotificationBox(false)}
        />
      )}
      <Formik
        initialValues={{
          login: {
            email: '',
            password: '',
            remember: false
          }
        }}
        onSubmit={handleSubmit}
      >
        {({ handleSubmit }) => {
          return (
            <Form
              action='/login'
              acceptCharset='UTF-8'
              method='post'
              ref={formElement}
              onSubmit={handleSubmit}
              noValidate
            >
              <Field name='login[email]' validate={validateEmail}>
                {({ field, meta }: FieldProps) => (
                  <div className={`s-form-group ${meta.error ? 'error' : ''}`}>
                    <label htmlFor='loginEmail' className='o-form-label'>
                      {t('login.email_field_label')}
                    </label>
                    <input
                      type='email'
                      id='loginEmail'
                      className='o-text-field'
                      required
                      {...field}
                    />
                    {meta.touched && meta.error && <span className='help-block'>{meta.error}</span>}
                  </div>
                )}
              </Field>
              <Field name='login[password]' validate={validatePassword}>
                {({ field, meta }: FieldProps) => (
                  <div className={`s-form-group ${meta.error ? 'error' : ''}`}>
                    <label htmlFor='loginPassword' className='o-form-label'>
                      {t('login.password_field_label')}
                    </label>
                    <PasswordInput
                      id='loginPassword'
                      className='o-text-field'
                      required
                      {...field}
                    />
                    {meta.touched && meta.error && <span className='help-block'>{meta.error}</span>}
                  </div>
                )}
              </Field>
              <div className='c-login__side-push h-margin-bottom--24'>
                <div className='control-group boolean optional login_remember o-form-label o-form-label--checkbox'>
                  <div className='controls'>
                    <label className='checkbox'>
                      <Field type='checkbox' name='login[remember]' className='h-margin-right--8' />
                      {t('login.remember_me')}
                    </label>
                  </div>
                </div>
                <div>
                  <a href='/password_resets/new' className='o-clickable c-login__secondary-link'>
                    {t('login.forgot_your_password')}
                  </a>
                </div>
              </div>
              <div>
                <button name='login-btn' type='submit' className='s-button s-button--medium'>
                  {t('login.submit_button_label')}
                </button>
              </div>
            </Form>
          )
        }}
      </Formik>
    </>
  )
}
