import React from 'react'

import { t } from 'i18n'

export const ComposeMessageHeader: React.FC<{ isReply: boolean }> = function ({ isReply }) {
  return (
    <h2 className='h2--default-margin'>
      {isReply ? t('messages.message.Antworten') : t('messages.new_message')}
    </h2>
  )
}
