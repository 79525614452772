import { ErrorMessage } from 'formik'

import React from 'react'

interface StyledErrorMessageProps {
  name: string
}

export const StyledErrorMessage: React.FC<StyledErrorMessageProps> = ({ name }) => (
  <ErrorMessage name={name}>{(msg) => <span className='help-block'>{msg}</span>}</ErrorMessage>
)
