// Needed for Edge (Polyfill)
import { TextDecoder } from 'text-encoding'

// This function turns a reader to a ReadableStream. This is needed because some browsers
// can not return a feature-complete ReadableStream, even though a Polyfill is in use.
// We need to explicitily transform e.g. the native Firefox Readable Stream (coming through new Response(file).body)
// to a complete ReadableStream. It then supports pipe and pipeTo. This can be removed when the Polyfill is smarter.
interface Options {
  decode?: boolean
  upload?: boolean
}

export default function readerToStream(
  reader: ReadableStreamDefaultReader<Uint8Array>,
  options?: Options
) {
  const decoder = new TextDecoder('utf-8')
  let closed = false
  const stream = new ReadableStream(
    {
      start(controller) {
        async function push(): Promise<void> {
          const { done, value } = await reader.read()
          if (closed) {
            await controller.close()
            return
          }

          if (value) {
            let decodedValue = (options || {}).decode ? decoder.decode(value) : value

            const decodedLength = (value: string | Uint8Array) => {
              return typeof value === 'string' ? value.length : value.byteLength
            }

            // In IE, Edge, Safari, Chrome and other chromium based browsers
            // everything comes as one piece.
            // The server requires chunks so we split them here.
            if ((options || {}).upload) {
              while (decodedLength(decodedValue) > 512000) {
                const chunk = decodedValue.slice(0, 512000)
                decodedValue = decodedValue.slice(512000)
                controller.enqueue(chunk)
              }
            }

            if (decodedLength(decodedValue) > 0) {
              controller.enqueue(decodedValue)
            }
          }

          if (done && !closed) {
            controller.close()
            closed = true
            return
          }
          return push()
        }

        push()
      }
    },
    new CountQueuingStrategy({ highWaterMark: 1 })
  )

  return stream
}
