import { useQuery } from '@tanstack/react-query'
import React, { useContext, useState, useEffect } from 'react'

import { CryptorContext } from 'components/features/Cryptor/CryptorContext'
import EntryForm, { FormType } from 'components/features/MedicalRecord/EntryForm'
import Header from 'components/features/MedicalRecord/EntryForm/Header'
import { MedicalRecordEntriesContainer } from 'components/features/MedicalRecord/MedicalRecordEntriesContainer'
import { SendRequestsList } from 'components/features/MedicalRecord/SendRequestsList/SendRequestsList'
import { DashboardContainer } from 'components/shared/DashboardContainer'
import { Empty, Loading } from 'components/shared/StatePages'
import { t } from 'i18n'

import { fetch as fetchEntryTypes } from './services/EntryType'
import { fetch as fetchEntries } from './services/MedicalRecordEntry'
import { fetch as fetchEntryShares } from './services/MedicalRecordEntryShare'

export const Dashboard: React.FC = function () {
  const cryptorWithKeys = useContext(CryptorContext)
  const [isInitializing, setIsInitializing] = useState(true)
  const [openedForm, setOpenedForm] = useState<FormType | undefined>(undefined)
  useEffect(() => setIsInitializing(false), [])

  const { data: entryTypes } = useQuery(['entry_types'], () => fetchEntryTypes(), {})

  const entries = useQuery(['entries'], () => fetchEntries(cryptorWithKeys.cryptor), {})

  const sharedEntries = useQuery(
    ['entry_shares'],
    () => fetchEntryShares(cryptorWithKeys.cryptor),
    {}
  )

  const showForm = (form: FormType) => {
    return () => setOpenedForm(form)
  }

  /* show loading page if fetching is not complete */

  if (isInitializing || entries.isLoading || sharedEntries.isLoading) {
    return (
      <DashboardContainer title={t('medical_record.title')}>
        <Loading label={t('MEDICAL_RECORD/LOADING')} />
      </DashboardContainer>
    )
  }

  const hasUndecryptableEntries = (): boolean | undefined =>
    entries.data?.some((entry) => entry.data_undecryptable) ||
    sharedEntries.data?.some((sharedEntry) => sharedEntry.target.data_undecryptable)

  /* show empty page if no entries are found and no forms are opened by clicking create button on the empty page*/

  if (entries.data?.length === 0 && sharedEntries.data?.length === 0 && !openedForm) {
    return (
      <DashboardContainer title={t('medical_record.title')}>
        <Empty
          label={t('medical_record.no_shared_found')}
          sublabel={t('medical_record.start_sharing')}
        >
          <Header onShowStandardForm={showForm('text')} onShowFileForm={showForm('file')} />
        </Empty>
      </DashboardContainer>
    )
  }

  /* show page of entries if any form is opened or entries exist*/
  return (
    <DashboardContainer
      title={t('medical_record.title')}
      {...(hasUndecryptableEntries() && {
        flashMessageType: 'notice',
        flashMessage: t('MEDICAL_RECORD/DECRYPTION_ERROR_MESSAGE')
      })}
    >
      <EntryForm
        entryTypes={entryTypes || []}
        currentEncryptionKeyId={cryptorWithKeys.encryptionKey.id}
        onSave={entries.refetch}
        {...(openedForm && {
          isFormOpen: true,
          openedFormType: openedForm,
          onCancelClick: () => setOpenedForm(undefined)
        })}
      />
      <SendRequestsList
        entries={sharedEntries.data || []}
        onAcceptSharedEntry={entries.refetch}
        onResolveSharedEntry={sharedEntries.refetch}
      />
      <MedicalRecordEntriesContainer
        entries={entries.data || []}
        // eslint-disable-next-line react/jsx-no-bind
        onEntriesChange={() => {
          entries.refetch()
          setOpenedForm(undefined)
        }}
      />
    </DashboardContainer>
  )
}
