import axios from 'axios'

export interface ContactPracticeAttributes {
  name: string
  id: string
}

export type ContactPracticeNames = { [key: string]: string }
export interface ContactPractices {
  names: ContactPracticeNames
  defaultContactId?: string
}

export async function fetchContactPractices(): Promise<ContactPractices> {
  const response = await axios.get(`/api/messages/v1/contact_practices/names.json`)
  const practices = response.data as ContactPracticeNames
  const defaultContactId = Object.keys(practices)[0]

  return { names: practices, defaultContactId: defaultContactId }
}
