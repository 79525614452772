import { encrypt, decrypt } from '@samedi/crypto-js/subtle/symmetric'
import { str2ab, utf8StringToBytes, bytesToUtf8String } from '@samedi/crypto-js/utils'
import { fromByteArray as encode64, toByteArray as decode64 } from 'base64-js'

import SessionKey from './SessionKey'

/**
 * A session key cryptor handles encrypting/decrypting of data using SessionKey as symetric keys.
 */
export default class SessionKeyCryptor {
  sessionKey: SessionKey

  constructor(sessionKey: SessionKey) {
    this.sessionKey = sessionKey
  }

  getDecryptedSessionKey(): SessionKey {
    return this.sessionKey
  }

  getDecryptedSessionKeyBytes(): Uint8Array {
    return this.sessionKey.bytes
  }

  /**
   * Encrypts a piece of UTF8 string data symetrically using the underlying session key and returns it Base 64 encoded.
   */
  async encryptUtf8String(data: string) {
    const encryptedData = await encrypt(utf8StringToBytes(data), this.sessionKey.key)

    return encode64(encryptedData)
  }

  /**
   * Encrypts a piece of binary string of raw bytes symetrically using the underlying session key and returns it Base 64 encoded.
   */
  async encryptBinaryString(data: string) {
    const encryptedData = await encrypt(str2ab(data), this.sessionKey.key)

    return encode64(encryptedData)
  }

  /**
   * Decrypts a piece of string data symetrically using the underlying session key.
   */
  async decrypt(data: string): Promise<string> {
    const decryptedDataBytes = await decrypt(decode64(data), this.sessionKey.key)

    return bytesToUtf8String(decryptedDataBytes)
  }
}
