import { parseISO, isValid } from 'date-fns'

export function combineDateAndTime(date?: string, time?: string): Date | undefined {
  let dateValue

  if (date && time) {
    const parts = time.split('T')
    dateValue = parseISO(`${date}T${parts[parts.length - 1]}`)
  } else if (date) {
    dateValue = parseISO(`${date}`)
  }

  return isValid(dateValue) ? dateValue : undefined
}
