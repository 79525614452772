import React from 'react'

import { t } from 'i18n'

interface ConfirmationModalProps {
  onConfirm?: () => void
  onCancel?: () => void
  title?: string
  cancelButtonLabel?: string
  confirmButtonLabel?: string
  isConfirmButtonDisabled?: boolean
  children?: JSX.Element
}

export const ConfirmationModal: React.FC<ConfirmationModalProps> = function (props) {
  const {
    onConfirm,
    onCancel,
    title,
    cancelButtonLabel,
    confirmButtonLabel,
    isConfirmButtonDisabled,
    children
  } = props

  return (
    <div className='s-modal'>
      <div className='s-modal__content'>
        <h2>{title || t('confirmation_modal.title')}</h2>
        {children}
        <div className='s-button-group'>
          {onCancel && (
            <button
              className='s-button s-button--secondary s-button-group__button'
              data-testid='s-modal__cancel-button'
              onClick={onCancel}
            >
              {cancelButtonLabel || t('confirmation_modal.cancel')}
            </button>
          )}
          {onConfirm && (
            <button
              className='s-button s-button--danger s-button-group__button s-modal__button'
              disabled={isConfirmButtonDisabled}
              data-testid='s-modal__confirm-button'
              onClick={onConfirm}
            >
              {confirmButtonLabel || t('confirmation_modal.confirm')}
            </button>
          )}
        </div>
      </div>
    </div>
  )
}
