/* eslint-disable @typescript-eslint/no-use-before-define */
import React from 'react'

import { CryptorContext } from 'components/features/Cryptor/CryptorContext'
import { CryptorWithKeys } from 'components/features/Cryptor/models/CryptorService'
import { DownloadAttachmentLink } from 'components/features/MedicalRecord/DownloadAttachmentLink'
import { downloadEncryptedAttachment } from 'components/features/MedicalRecord/downloadEncryptedAttachment'
import { t } from 'i18n'

import { DecryptedMedicalRecordEntry } from '../MedicalRecordEntry'
import { MedicalRecordValuePlaceholder } from '../MedicalRecordValuePlaceholder'

interface Props {
  entry: DecryptedMedicalRecordEntry
  cryptorWithKeys: CryptorWithKeys
}

export const Description: React.FC<Props> = function ({ entry }) {
  const isFile: boolean = entry.type === 'file'

  if (entry.data_undecryptable) {
    return <MedicalRecordValuePlaceholder isFile={isFile} />
  }

  return (
    <>
      {entry.description && (
        <span className='c-medical-record-entries__category-value'>
          {translateBooleanOrText(entry.description)} {entry.entry_type && entry.entry_type.unit}
        </span>
      )}
      {isFile && (
        <CryptorContext.Consumer>
          {(cryptorWithKeys) => (
            <>
              {entry.description && (
                <span className='c-medical-record-entries__category-value'>
                  &nbsp;&ndash;&nbsp;
                </span>
              )}
              <DownloadAttachmentLink
                name={entry.attachment_filename as string}
                size={entry.attachment_size as number}
                // eslint-disable-next-line react/jsx-no-bind
                onDownloadClick={() => downloadEncryptedAttachment(cryptorWithKeys.cryptor, entry)}
              />
            </>
          )}
        </CryptorContext.Consumer>
      )}
    </>
  )
}

function translateBooleanOrText(value: string) {
  return value === 'true' || value === 'false' ? t(`MEDICAL_RECORD/${value.toUpperCase()}`) : value
}
