import React from 'react'
import { CellProps } from 'react-table'

import { dateFormat } from 'i18n'

import { DecryptedMessageSummaryAttributes } from '../../models/Message'

export const CellTime: React.FC<CellProps<DecryptedMessageSummaryAttributes, string>> = function (
  props
) {
  const { cell } = props
  return (
    <div className='f-messages__cell f-messages__time-cell'>
      {dateFormat(cell.value, 'dateTimeDynamic')}
    </div>
  )
}
