import { faCircleInfo, faKeySkeleton } from '@fortawesome/pro-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Switch } from '@headlessui/react'
import cx from 'classnames'

import React, { useCallback, useState } from 'react'

import { CurrentPatientProfile } from 'app/javascript/types/currentPatientProfile'
import { Spinner } from 'components/shared/Spinner/Spinner'
import { useCurrentPatientProfileQuery } from 'hooks/useCurrentPatientProfileQuery'
import { t } from 'i18n'

import { Activation } from './Activation/Activation'
import { Deactivation } from './Deactivation/Deactivation'
import { EmailUnverified } from './EmailUnverified'

type SettingsAction = 'activate' | 'deactivate' | undefined

interface SettingsConfirmationModalProps {
  currentAction: SettingsAction
  patientProfile: CurrentPatientProfile
  onClose: () => void
  onReactivation: () => void
}

const SettingsConfirmationModal: React.FC<SettingsConfirmationModalProps> = ({
  currentAction,
  onClose,
  onReactivation,
  patientProfile
}) => {
  if (currentAction === 'activate' && !patientProfile.isEmailVerified) {
    return <EmailUnverified onClose={onClose} email={patientProfile.accountEmail} />
  } else if (currentAction === 'activate') {
    return <Activation onClose={onClose} patientProfile={patientProfile} />
  } else if (currentAction === 'deactivate') {
    return (
      <Deactivation
        onClose={onClose}
        onReactivation={onReactivation}
        patientProfile={patientProfile}
      />
    )
  }

  return null
}

export const SecondFactorSettings = () => {
  const [actionToConfirm, setActionToConfirm] = useState<SettingsAction>()
  const { isLoading, data: currentUserProfile } = useCurrentPatientProfileQuery()

  const toggleActivation = useCallback(() => {
    if (currentUserProfile?.hasActive2FA) {
      setActionToConfirm('deactivate')
    } else {
      setActionToConfirm('activate')
    }
  }, [currentUserProfile?.hasActive2FA])

  if (isLoading || !currentUserProfile) {
    return (
      <div data-testid='2fa-settings-loading' className='flex flex-col items-center py-12'>
        <Spinner className='min-h-[48px] min-w-[48px]' />
      </div>
    )
  }

  return (
    <>
      <SettingsConfirmationModal
        currentAction={actionToConfirm}
        onClose={() => setActionToConfirm(undefined)} // eslint-disable-line react/jsx-no-bind
        onReactivation={() => setActionToConfirm('activate')} // eslint-disable-line react/jsx-no-bind
        patientProfile={currentUserProfile}
      />
      <div data-testid='2fa-settings' className='max-w-5xl'>
        <h2 className='mb-3 text-hero'>{t('settings.2fa.title')}</h2>
        <div className='overflow-hidden rounded-lg bg-white p-4 shadow-sm'>
          <div className='mt-1 flex items-center text-primary-600'>
            <FontAwesomeIcon className='h-5 w-5 pr-2' icon={faKeySkeleton} />
            <div className='font-header grow font-semibold text-grey-800'>
              {t('settings.2fa.status')}{' '}
              <strong className='font-bold'>
                {t(
                  currentUserProfile.hasActive2FA
                    ? 'settings.2fa.status.active'
                    : 'settings.2fa.status.not_active'
                )}
              </strong>
            </div>
            <Switch
              data-testid='2fa-settings-toggle'
              checked={currentUserProfile.hasActive2FA}
              onChange={toggleActivation}
              className={cx(
                'relative inline-flex h-[31px] w-[51px] shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus-visible:ring-2  focus-visible:ring-white focus-visible:ring-opacity-75',
                {
                  'bg-secondary-500 hover:bg-primary-600 active:bg-navy-blue':
                    currentUserProfile.hasActive2FA,
                  'bg-grey-500 hover:bg-grey-300 hover:shadow-sm active:bg-secondary-500':
                    !currentUserProfile.hasActive2FA
                }
              )}
            >
              <span className='sr-only'>
                {t(
                  currentUserProfile.hasActive2FA
                    ? 'settings.2fa.status_toggle.deactivate'
                    : 'settings.2fa.status_toggle.activate'
                )}
              </span>
              <span
                aria-hidden='true'
                className={`${currentUserProfile.hasActive2FA ? 'translate-x-5' : 'translate-x-0'}
            pointer-events-none inline-block h-[27px] w-[27px] transform rounded-full bg-grey-100 shadow-md ring-0 transition duration-200 ease-in-out`}
              />
            </Switch>
          </div>
          <div className='mt-4 flex rounded-lg bg-grey-200 p-4 pb-3 text-grey-800'>
            <FontAwesomeIcon className='h-5 w-5 pr-3 pt-0.5' icon={faCircleInfo} />
            <div className='min-w-0 self-start'>
              <div className='font-bold'>{t('settings.2fa.info.header')}</div>

              <div
                className='mt-1 break-words'
                dangerouslySetInnerHTML={{
                  __html: t('settings.2fa.info.description_html', currentUserProfile.accountEmail)
                }}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
