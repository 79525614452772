import { faXmark } from '@fortawesome/pro-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { useEffect, useState } from 'react'

import { getCategoryText } from 'components/features/MedicalRecord/EntriesTable/CategoryName'
import {
  DecryptedMedicalRecordEntry,
  PublicKey
} from 'components/features/MedicalRecord/MedicalRecordEntry'

import { t } from 'i18n'

import { fetch as fetchContactPractices } from '../services/SendEntryModal'

import { InstitutionSelector, ShareSuccess } from './InstitutionSelector'

import { ContactInstitution } from './InstitutionSelectorItem'

interface SendEntryModalProps {
  entry: DecryptedMedicalRecordEntry
  shareSuccess: ShareSuccess
  onClose: () => void
  onSendButtonClick: (
    institutionId: number,
    institutionPublicKey: PublicKey,
    entryId: string | number
  ) => void
}

export function SendEntryModal({
  entry,
  shareSuccess,
  onClose,
  onSendButtonClick
}: SendEntryModalProps) {
  const [contactInstitutions, setContactInstitutions] = useState<ContactInstitution[]>([])
  const [contactInstitutionsLoaded, setContactInstitutionsLoaded] = useState<boolean>(false)

  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/no-use-before-define
    loadContactInstitutions()
  }, [])

  const loadContactInstitutions = async () => {
    const data: ContactInstitution[] = await fetchContactPractices()
    setContactInstitutions(data)
    setContactInstitutionsLoaded(true)
  }

  return (
    <div className={`s-modal${contactInstitutionsLoaded ? '' : ' s-modal--zero-opacity'}`}>
      <div className='s-modal__content c-send-entry-modal'>
        <h2>{t('MEDICAL_RECORD/SHARING_WITH_TITLE', getCategoryText(entry))}</h2>
        <div className='s-modal__close-button' onClick={onClose}>
          <FontAwesomeIcon icon={faXmark} />
        </div>
        <InstitutionSelector
          institutions={contactInstitutions}
          onSendButtonClick={onSendButtonClick}
          entry={entry}
          shareSuccess={shareSuccess}
        />
      </div>
    </div>
  )
}
