import { Key } from '@samedi/crypto-js/subtle/symmetric'

/**
 * A session key represents a symetric encryption key to be used to encrypt/decrypt
 * data that being shared between two parties (mostly Platform and PAPP).
 */
export default class SessionKey {
  key: Key
  bytes: Uint8Array

  constructor(key: Key, keyBytes: Uint8Array) {
    this.key = key
    this.bytes = keyBytes
  }
}
