import React from 'react'

import { Route, Routes } from 'react-router-dom'

import { CryptorUnlocker } from 'components/features/Cryptor'
import { CryptorContext } from 'components/features/Cryptor/CryptorContext'
import { PasswordForm, PasswordSubmissionFunction } from 'components/shared/PasswordForm'
import { t } from 'i18n'

import { ComposeView } from './ComposeView'
import { ListView } from './ListView'
import { ReadView } from './ReadView'

export const MessagingContainer: React.FC = function () {
  return (
    // eslint-disable-next-line @typescript-eslint/no-use-before-define
    <CryptorUnlocker passwordForm={passwordForm}>
      {(cryptorWithKeys) => (
        <CryptorContext.Provider value={cryptorWithKeys}>
          <Routes>
            <Route path='new' element={<ComposeView />} />
            <Route path='inbox/*' element={<ListView />} />
            <Route path='outbox/*' element={<ListView />} />
            <Route path=':messageID' element={<ReadView />} />
          </Routes>
        </CryptorContext.Provider>
      )}
    </CryptorUnlocker>
  )
}

interface PasswordProps {
  onSubmitPassword: PasswordSubmissionFunction
}

const passwordForm: React.FC<PasswordProps> = function ({ onSubmitPassword }) {
  return (
    <PasswordForm
      onSubmit={onSubmitPassword}
      title={t('messaging.unlock')}
      lockReason={t('messaging.locked_reason')}
      unlockErrorMessage={t('messaging.unlock_error_incorrect_password')}
      descriptionHTML={t('messaging.unlock_description')}
    />
  )
}
