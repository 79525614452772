import React from 'react'

import { CryptorUnlocker } from 'components/features/Cryptor'
import { CryptorContext } from 'components/features/Cryptor/CryptorContext'
import { Dashboard } from 'components/features/MedicalRecord/Dashboard'
import { PasswordForm, PasswordSubmissionFunction } from 'components/shared/PasswordForm'
import { t } from 'i18n'

export const MedicalRecordContainer: React.FC = function () {
  return (
    <div>
      {/*eslint-disable-next-line @typescript-eslint/no-use-before-define */}
      <CryptorUnlocker passwordForm={passwordForm}>
        {(cryptorWithKeys) => (
          <CryptorContext.Provider value={cryptorWithKeys}>
            <Dashboard />
          </CryptorContext.Provider>
        )}
      </CryptorUnlocker>
    </div>
  )
}
interface PasswordProps {
  onSubmitPassword: PasswordSubmissionFunction
}

const passwordForm: React.FC<PasswordProps> = function ({ onSubmitPassword }) {
  return (
    <PasswordForm
      onSubmit={onSubmitPassword}
      title={t('MEDICAL_RECORD/UNLOCK_HEALTH_RECORD')}
      lockReason={t('MEDICAL_RECORD/LOCKED_REASON')}
      unlockErrorMessage={t('MEDICAL_RECORD/UNLOCK_HEALTH_RECORD_INCORRECT_PASSWORD')}
      descriptionHTML={t('MEDICAL_RECORD/UNLOCK_HEALTH_RECORD_DESCRIPTION_NEW')}
    />
  )
}
