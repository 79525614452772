/* eslint-disable @typescript-eslint/no-use-before-define */
import React from 'react'
import styled from 'styled-components'

import { t } from 'i18n'
import humanDoctorImg from 'images/humanDoctor.png'

import { colors } from './styles'

export type NoMessagesProps = {
  from?: string | null
}

export const NoMessages: React.FC<NoMessagesProps> = ({ from }) => {
  return (
    <Container>
      <Image src={humanDoctorImg} />
      <StrongText>{t('conversation.questions')}</StrongText>
      <Text>
        {`${t('conversation.ask')} `}
        {from && <HighlightedText>{from}</HighlightedText>}
        {` ${t('conversation.diagnosis')}`}.
      </Text>
      <Text>{t('conversation.answer')}</Text>
    </Container>
  )
}

// styles
const Container = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  justify-content: center;
  align-items: center;
  height: 100%;
`

const Image = styled.img`
  width: 176px;
  height: 171px;
  margin-bottom: 12px;
`

const StrongText = styled.span`
  font-weight: 600;
  font-size: 18px;
  line-height: 24px;
  color: ${colors.grey8};
  text-align: center;
`

const Text = styled.span`
  font-weight: 400;
  font-size: 18px;
  line-height: 24px;
  color: ${colors.grey8};
  text-align: center;
`

const HighlightedText = styled.span`
  font-style: italic;
  font-weight: 300;
  font-size: 18px;
  line-height: 24px;
  color: ${colors.grey8};
  text-align: center;
  opacity: 0.5;
`
