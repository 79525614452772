import { useMutation, useQueryClient } from '@tanstack/react-query'
import React, { useEffect } from 'react'

import { CurrentPatientProfile } from 'app/javascript/types/currentPatientProfile'
import CodeVerification from 'components/features/CodeVerification'

import { FullscreenSpinner } from 'components/shared/FullscreenSpinner'
import Modal from 'components/shared/Modal/Modal'
import { SelfDestruct } from 'components/shared/SelfDestruct/SelfDestruct'
import { SuccessToast } from 'components/shared/SuccessToast/SuccessToast'
import { queryKey } from 'hooks/useCurrentPatientProfileQuery'
import { t } from 'i18n'

import { finish2FAActivation, start2FAActivation } from '../api'

import { Success } from './Success'

type Props = {
  onClose: () => void
  patientProfile: CurrentPatientProfile
}

export const Activation: React.FC<Props> = ({ patientProfile, onClose }) => {
  const queryClient = useQueryClient()

  const resendCodeMutation = useMutation(async () => {
    // Resolve after a bit of delay to let React unrender `SuccessToast`
    return new Promise((resolve) => setTimeout(() => resolve(null), 100))
  })
  const start2FAActivationMutation = useMutation(async (isResending: boolean = false) => {
    await start2FAActivation()
    if (isResending) {
      resendCodeMutation.mutate()
    }
  })
  const finish2FAActivationMutation = useMutation(finish2FAActivation, {
    onSuccess: () => {
      const currentProfile = queryClient.getQueryData<CurrentPatientProfile>([queryKey])

      queryClient.setQueryData([queryKey], { ...currentProfile, hasActive2FA: true })
    }
  })

  useEffect(() => {
    start2FAActivationMutation.mutate(false)
  }, []) // eslint-disable-line

  const isProcessing = start2FAActivationMutation.isLoading || finish2FAActivationMutation.isLoading

  const resendCodeSuccessToast = resendCodeMutation.isSuccess ? (
    <SelfDestruct expireAt={12000}>
      <SuccessToast className='left-0 top-16 z-[9999] md:top-9'>
        {t('login.verify-code.send-again-confirmation')}
      </SuccessToast>
    </SelfDestruct>
  ) : null

  if (start2FAActivationMutation.isError) {
    return (
      <Modal open onClose={onClose}>
        <div className='px-4'>{t('generic.loading_error')}</div>
      </Modal>
    )
  }

  return finish2FAActivationMutation.isSuccess ? (
    <Success onClose={onClose} patientProfile={patientProfile} />
  ) : (
    <>
      {resendCodeSuccessToast}
      {isProcessing && <FullscreenSpinner text={t('login.verify-code.loading')} />}
      <Modal open onClose={onClose}>
        <div className='px-4'>
          <h3 className='mb-4 text-hero'>{t('login.verify-code.title')}</h3>
          <CodeVerification
            disabled={isProcessing}
            email={patientProfile.accountEmail}
            // eslint-disable-next-line react/jsx-no-bind
            onSendCodeAgain={() => {
              resendCodeMutation.reset()
              finish2FAActivationMutation.reset()
              start2FAActivationMutation.mutate(true)
            }}
            // eslint-disable-next-line react/jsx-no-bind
            onSubmit={({ code }) => finish2FAActivationMutation.mutate(code)}
            isInvalidInput={finish2FAActivationMutation.isError}
          />
        </div>
      </Modal>
    </>
  )
}
