/* eslint-disable @typescript-eslint/no-use-before-define */
import { useQuery, useQueryClient } from '@tanstack/react-query'
import { isBefore } from 'date-fns/fp'
import React, { useContext, useState, useEffect } from 'react'
import styled from 'styled-components'

import { CryptorContext } from 'components/features/Cryptor/CryptorContext'

import { Spinner } from 'components/shared/Spinner/Spinner'

import { ConversationColumn } from '../components/ConversationColumn'
import useViewport from '../hooks'
import { Message } from '../types'
import { fetchContactMessages } from '../utils/fetchBaseMessages'
import { markMessagesRead } from '../utils/markMessagesRead'

// types
export type ConversationColumnContainerProps = {
  contactName?: string | null
  contactId?: string
  unreadMessagesCount?: number
  isActive: boolean
  handleSubmitMessage: (message: string) => void
  onReturnButtonClick: () => void
}

// components
export const ConversationColumnContainer: React.FC<ConversationColumnContainerProps> = ({
  contactId,
  unreadMessagesCount,
  isActive,
  ...props
}) => {
  const queryClient = useQueryClient()
  const cryptorWithKeys = useContext(CryptorContext)
  const { data: messages, isLoading } = useQuery(
    ['messages', contactId],
    fetchContactMessages(cryptorWithKeys, contactId),
    { enabled: !!contactId }
  )

  const { isDesktop } = useViewport()
  const [firstUnreadMessageId, setFirstUnreadMessageId] = useState<string | undefined>(() =>
    unreadMessagesCount === 0 ? 'none' : undefined
  )

  useEffect(() => {
    // Desktop: mark messages as read on mount
    // Tablet and mobile: mark messages as read, when the component is visible/active.
    if (contactId && (isDesktop || isActive) && areMessagesUnread(messages)) {
      const id = getFirstUnreadMessageId(messages, firstUnreadMessageId)
      setFirstUnreadMessageId(id)
      markMessagesRead(contactId, messages, queryClient)
    }
  }, [contactId, isActive, isDesktop, firstUnreadMessageId, queryClient, messages])

  if (isLoading) {
    return (
      <SpinnerContainer>
        <Spinner />
      </SpinnerContainer>
    )
  }

  return (
    <ConversationColumn
      messages={messages}
      isActive={isActive}
      unreadMessagesCount={unreadMessagesCount}
      firstUnreadMessageId={firstUnreadMessageId}
      {...props}
    />
  )
}

// styles
const SpinnerContainer = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`

// utils
const compareFn = (a: Message, b: Message) => {
  const msgDateA = new Date(a.created_at)
  const msgDateB = new Date(b.created_at)

  return isBefore(msgDateA)(msgDateB) ? -1 : 1
}

export const getFirstUnreadMessageId = (
  messages: Message[] | undefined = [],
  firstUnreadMessageId: string | undefined
): string | undefined => {
  if (firstUnreadMessageId) return firstUnreadMessageId

  const unreadMessages = messages.filter((msg) => !msg.is_read)
  const sortedUnreadMessages = unreadMessages.sort(compareFn)
  const firstUnreadMessage = sortedUnreadMessages.pop()
  return firstUnreadMessage?.id
}

// utils
export const areMessagesUnread = (messages: Message[] | undefined = []) =>
  messages.some(({ is_read }) => !is_read)
