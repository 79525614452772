import { faXmark } from '@fortawesome/pro-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { useCallback } from 'react'

import { t } from 'i18n'

interface Props {
  onRejectEntry?: () => void
}

export const RejectAction: React.FC<Props> = function ({ onRejectEntry }) {
  const handleRejectEntryClick = useCallback(() => {
    onRejectEntry && onRejectEntry()
  }, [onRejectEntry])

  return (
    <button
      onClick={handleRejectEntryClick}
      className='s-button s-button--danger-secondary'
      type='button'
    >
      <span className='h-margin-right--8'>
        <FontAwesomeIcon icon={faXmark} />
      </span>
      <span>{t('MEDICAL_RECORD/RECEIVED_ENTRIES/REJECT')}</span>
    </button>
  )
}
