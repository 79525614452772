import { faEnvelope, faPlus } from '@fortawesome/pro-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useQuery } from '@tanstack/react-query'
import URLSearchParams from '@ungap/url-search-params'

import React, { useCallback, useContext, useEffect } from 'react'

import { useLocation, useNavigate } from 'react-router-dom'

import { DashboardContainer } from 'components/shared/DashboardContainer'
import { Empty } from 'components/shared/StatePages/Empty'
import { Loading } from 'components/shared/StatePages/Loading'
import { t } from 'i18n'

import { CryptorContext } from '../../Cryptor/CryptorContext'
import { ComposeLink } from '../ComposeLink'
import { Pagination } from '../Pagination'
import { fetchMessages } from '../models/Message'

import { MessageTable } from './MessageTable'

export const ListView: React.FC = function () {
  const cryptorWithKeys = useContext(CryptorContext)

  const currentRoute = useLocation()
  const folder = currentRoute.pathname.includes('inbox') ? 'inbox' : 'outbox'

  const searchParams = new URLSearchParams(location.search)
  const page = parseInt(searchParams.get('page') || '1')
  const scrollToTop = useCallback(() => window.scrollTo(0, 0), [])

  const { data, isLoading } = useQuery(['messages', { folder, page }], () =>
    fetchMessages(folder, page, cryptorWithKeys)
  )

  const hasMessages = data?.messages && data.messages.length > 0

  // If there are no messages on the current page, user will be redirected to the last page that has messages.
  const navigate = useNavigate()
  useEffect(() => {
    if (data && !hasMessages && !isLoading && page !== 1) {
      navigate(currentRoute.pathname + `?page=${data.meta.total_pages}`)
    }
  })

  const title = folder === 'inbox' ? t('messages.index.inbox') : t('messages.index.outbox')
  const unreadCount = data?.meta.unread || 0

  return (
    <>
      <DashboardContainer title={title} hasInlineButton>
        {hasMessages && (
          <ComposeLink className='s-button'>
            <FontAwesomeIcon icon={faPlus} className='s-button__icon' />
            {t('messages.new_message')}
          </ComposeLink>
        )}
      </DashboardContainer>
      {isLoading ? (
        <Loading label={t('messaging.loading')} />
      ) : hasMessages ? (
        <div className='row'>
          <div className='col-md-12'>
            <div className='f-messages__unread-count'>
              {unreadCount > 0 && (
                <>
                  <FontAwesomeIcon icon={faEnvelope} /> {unreadCount} {t('messages.unread')}
                </>
              )}
            </div>
            <MessageTable folder={folder} messages={data?.messages} />
            <Pagination
              currentPage={page}
              totalPages={data?.meta.total_pages || 1}
              onClick={scrollToTop}
            />
          </div>
        </div>
      ) : (
        <Empty label={t('messages.index.empty')}>
          <div>
            <ComposeLink className='s-button'>
              <FontAwesomeIcon icon={faPlus} className='h-margin-right--8' />
              {t('messages.new_message')}
            </ComposeLink>
          </div>
        </Empty>
      )}
    </>
  )
}
