import { useQuery } from '@tanstack/react-query'

import { CurrentPatientProfile } from '../types/currentPatientProfile'

export const queryKey = 'currentUserProfile'

const fetchCurrentPatientProfileData = async (): Promise<CurrentPatientProfile | undefined> => {
  // @ts-ignore
  return Promise.resolve(window.currentPatientProfile || undefined)
}

export const useCurrentPatientProfileQuery = () => {
  // We never update `window.currentPatientProfile` for now, so to avoid
  // over-writting data updates on `setQueryData`, stale timer is set to never
  // expire.
  return useQuery([queryKey], fetchCurrentPatientProfileData, { staleTime: Infinity })
}
