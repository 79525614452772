import classNames from 'classnames'
import React, { useContext } from 'react'

import { CryptorContext } from 'components/features/Cryptor/CryptorContext'

import { DecryptedMedicalRecordEntryShare } from '../MedicalRecordEntryShare'

import { AcceptAction } from './AcceptAction'
import { EntryShareRowMobile } from './EntryShareRowMobile'
import { RejectAction } from './RejectAction'

export interface Props {
  entries: DecryptedMedicalRecordEntryShare[]
  onAcceptEntry: (entry: DecryptedMedicalRecordEntryShare) => void
  onRejectEntry: (entry: DecryptedMedicalRecordEntryShare) => void
}

export function RequestsTableMobile({ entries, onAcceptEntry, onRejectEntry }: Props) {
  const cryptorWithKeys = useContext(CryptorContext)

  return (
    <div>
      {entries.map((entry) => (
        <div
          key={entry.id}
          className={classNames('c-medical-record-shared-entries__wrapper', {
            'is-disabled': entry.target.data_undecryptable
          })}
        >
          <div className='h-margin-bottom--24'>
            <EntryShareRowMobile entry={entry} cryptorWithKeys={cryptorWithKeys} />
          </div>
          <div className='h-display--flex h-flex-end'>
            <AcceptAction
              isDisabled={entry.target.data_undecryptable}
              // eslint-disable-next-line react/jsx-no-bind
              onAcceptEntry={() => onAcceptEntry(entry)}
            />
            {/*eslint-disable-next-line react/jsx-no-bind*/}
            <RejectAction onRejectEntry={() => onRejectEntry(entry)} />
          </div>
        </div>
      ))}
    </div>
  )
}
