import React from 'react'

import { t, dateFormat } from 'i18n'

import { Attributes } from '../models/PatientForm'

export type Props = Pick<Attributes, 'id' | 'title' | 'completedAt' | 'sentAt'>

const FormTitle: React.FC<Props> = function ({ id, title, completedAt, sentAt }) {
  return (
    <div className='f-forms__title-wrapper'>
      <div className='f-forms__received_date h-color--grey-60'>{dateFormat(sentAt)}</div>
      <div className='f-forms__title h-font-weight--600'>
        {completedAt ? (
          <span>{title}</span>
        ) : (
          <a className='o-clickable' href={`/patient_forms/${id}`}>
            {title}
          </a>
        )}
      </div>
      <div className='f-forms__sent-date'>
        {completedAt && (
          <>
            <span>{t('forms.filled')}&nbsp;</span>
            <span className='h-font-weight--600'>
              {dateFormat(completedAt, 'dateTimeLongWithoutWeekDay')}
            </span>
          </>
        )}
      </div>
    </div>
  )
}

export default FormTitle
