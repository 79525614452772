import { Cryptor } from '@samedi/crypto-js'

import readerToStream from './readerToStream'

export default async function readChunkedEncryptedFile(
  path: string,
  cryptor: Cryptor
): Promise<ReadableStream<Uint8Array>> {
  const response = await fetch(path, {
    credentials: 'include' // needed for edge otherwise it will return a 401
  })
  if (!response.body) {
    throw new Error(`reading file failed: ${path}`)
  }

  const reader = response.body.getReader()
  const readableStream = readerToStream(reader, { decode: true })

  return cryptor.decryptStreamToBinary(readableStream)
}
