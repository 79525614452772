import parse from 'html-react-parser'
import React, { type ReactElement } from 'react'

export interface Props {
  message: string
}

/**
 * InjectLinksToTextMessage loops through a string finding url candidates,
 * replacing them with the proper <a> tag
 *
 * This component is copied and improved from the vc-backend project.
 * Eventually we need to extract it into a package and use it
 * across all projects
 *
 */
export const InjectLinksToTextMessage: React.FC<Props> = ({ message }) => {
  if (!message) {
    return null
  }

  // parentheses () are important, they "save" the tag for string.split or other functions,
  // otherwise tags will be ommitted from regexp results
  const htmlTags = /(<[a-z]*>[a-z]*[\s]*<\/[a-z][\s]*>)/gi

  // find urls not inside <a> tag
  // extended version of
  // https://regex101.com/r/pC0jR7/2
  const urlsNotInAnchorTagRegex =
    /(((https?:\/\/)|[a-z]{3,3}[.]?)[^"<\s]+)?([a-z]*[.][a-z]{2,}[.a-z0-9_/#]*)(?![^<>]*>|[^"]*?<\/a)/gi
  const result: ReactElement[] = []
  const stringTagsToHTML = (result: ReactElement[], htmlString: string) => {
    const splittedByTags = htmlString.split(htmlTags)
    splittedByTags.forEach((node) => {
      node && result.push(parse(node) as ReactElement)
    })
  }
  let url: string[] | null
  let currentPointer = 0
  let i = 0

  while ((url = urlsNotInAnchorTagRegex.exec(message)) !== null) {
    const startOfUrl = urlsNotInAnchorTagRegex.lastIndex - url[0].length
    const contentBeforeUrl = message.slice(currentPointer, startOfUrl)

    // convert string tags to ReactElements, otherwise they will be rendered as text
    stringTagsToHTML(result, contentBeforeUrl)

    // add protocol to URLs that done have it, e.g. "www.samedi.de"
    const urlHref = url[0].startsWith('http') ? url[0] : `https://${url[0]}`

    result.push(
      <a
        key={`link-fragment-${i++}`}
        target='_blank'
        rel='noreferrer nofollow noopener external'
        href={urlHref}
      >
        {url[0]}
      </a>
    )

    // move to end of last url detected
    currentPointer = urlsNotInAnchorTagRegex.lastIndex
  }

  // add the last part
  const contentAfterLastUrl = message.slice(currentPointer)
  // convert string tags to ReactElements, otherwise they will be rendered as text
  stringTagsToHTML(result, contentAfterLastUrl)

  return (
    <>
      {result.map((e, i) => (
        <React.Fragment key={i}>{e}</React.Fragment>
      ))}
    </>
  )
}
