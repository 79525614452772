/* eslint-disable react/jsx-no-bind */
/* eslint-disable react/forbid-dom-props */
import { faArrowsRotate } from '@fortawesome/pro-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import classNames from 'classnames'
import React, { useState, useRef } from 'react'

import 'iconPack'

import 'style/shared/loading_spinner.css'

interface Props {
  'data-testid'?: string
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onClick: () => Promise<any>
  type: 'button' | 'submit' | 'reset'
  loadingState?: boolean
  disabled?: boolean
  className?: string
}

const ProgressButton: React.SFC<Props> = (props) => {
  const { onClick, type, disabled, children, className } = props
  const [loading, setLoad] = useState(false)
  const classes = classNames('s-button', className, {
    's-loading-spinner is-active': loading
  })

  const buttonRef = useRef(null)

  return (
    <button
      // eslint-disable-next-line react/destructuring-assignment
      data-testid={props['data-testid']}
      disabled={disabled || loading}
      ref={buttonRef}
      className={classes}
      type={type}
      onClick={(event) => {
        setLoad(true)
        event.preventDefault()
        onClick().finally(() => buttonRef.current && setLoad(false))
      }}
    >
      <FontAwesomeIcon className='s-button__icon' icon={faArrowsRotate} />
      {children}
    </button>
  )
}

export default ProgressButton
