import { Cryptor } from '@samedi/crypto-js'
import axios from 'axios'

import { authenticityHeaders } from 'util/csrf'

import {
  MedicalRecordEntryShare,
  DecryptedMedicalRecordEntryShare,
  decryptEntryShare
} from '../SendRequestsList/MedicalRecordEntryShare'

async function decryptAll(entryShares: MedicalRecordEntryShare[], cryptor: Cryptor) {
  const decryptedEntries: DecryptedMedicalRecordEntryShare[] = []
  for (const share of entryShares) {
    decryptedEntries[decryptedEntries.length] = await decryptEntryShare(share, cryptor)
  }
  return decryptedEntries
}

/**
 * Fetches and decrypts pending medical record entry shares
 *
 * @param cryptor Cryptor instance for decrypting the entry shares
 */
export async function fetch(cryptor: Cryptor) {
  const response = await axios.get<MedicalRecordEntryShare[]>(
    '/api/medical_record/v1/entry_shares.json'
  )
  return decryptAll(response.data, cryptor)
}

interface UpdateOptions {
  share: MedicalRecordEntryShare
  status: 'accept' | 'reject'
}
/**
 * Updates an entry share
 */
export function update({ share, status }: UpdateOptions) {
  return axios.put(`/api/medical_record/v1/entry_shares/${share.id}/${status}.json`, undefined, {
    headers: authenticityHeaders()
  })
}
