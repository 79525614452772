/* eslint-disable react/forbid-dom-props */
/* eslint-disable react/forbid-component-props */
/* eslint-disable react/jsx-no-bind */
import React, { useMemo, useState, useContext } from 'react'

import { CryptorContext } from 'components/features/Cryptor/CryptorContext'
import { MedicalRecordEntryType as EntryType } from 'components/features/MedicalRecord/MedicalRecordEntry'
import {
  encryptAndSaveMedicalRecordTypeText,
  encryptAndSaveMedicalRecordTypeFile
} from 'components/features/MedicalRecord/services/encryptAndSaveMedicalRecord'

import FileForm from './FileForm'
import Header from './Header'
import TextForm from './TextForm'

export type FormType = 'text' | 'file'

export interface TextValues {
  formType: FormType
  entryTypeId: number | ''
  date: string
  time: string
  description: string
}

export interface FileValues {
  formType: FormType
  description: string
  fileName: string
  fileSize: number
  fileData: string
  file: File
}

interface Props {
  entryTypes: EntryType[]
  currentEncryptionKeyId: string
  onSave: () => void
  isFormOpen?: boolean
  openedFormType?: FormType
  onCancelClick?: () => void
}

export default function EntryForm({
  entryTypes,
  currentEncryptionKeyId,
  onSave,
  isFormOpen,
  openedFormType,
  onCancelClick
}: Props) {
  const standardEntryTypes = useMemo(() => entryTypes, [entryTypes])

  const [currentFormShowing, setCurrentFormShowing] = useState<FormType | undefined>(
    isFormOpen ? openedFormType : undefined
  )
  const [currentEntryTypes, setCurrentEntryTypes] = useState<EntryType[] | null>(
    isFormOpen ? standardEntryTypes : null
  )
  const cryptorWithKeys = useContext(CryptorContext)
  const isTextForm = currentFormShowing === 'text'
  const isFileForm = currentFormShowing === 'file'

  const onEntrySave = async () => {
    setCurrentFormShowing(undefined)
    setCurrentEntryTypes(null)
    onSave()
  }

  const encryptAndSaveTextEntry = async (entry: TextValues) => {
    await encryptAndSaveMedicalRecordTypeText(entry, currentEncryptionKeyId, cryptorWithKeys)
    onEntrySave()
  }

  const encryptAndSaveFileEntry = async (entry: FileValues) => {
    await encryptAndSaveMedicalRecordTypeFile(entry, currentEncryptionKeyId, cryptorWithKeys)
    onEntrySave()
  }

  const showForm = (form: FormType) => {
    setCurrentFormShowing(form)
    setCurrentEntryTypes(standardEntryTypes)
  }

  const hideForm = () => {
    setCurrentFormShowing(undefined)
    setCurrentEntryTypes(null)
    onCancelClick && onCancelClick()
  }

  const getForm = () => {
    if (isTextForm && currentEntryTypes && currentEntryTypes.length) {
      return (
        <TextForm
          entryTypes={currentEntryTypes}
          onSubmit={(values) => encryptAndSaveTextEntry(values as TextValues)}
          onCancel={hideForm}
        />
      )
    } else if (isFileForm) {
      return (
        <FileForm
          onSubmit={(values) => encryptAndSaveFileEntry(values as FileValues)}
          onCancel={hideForm}
        />
      )
    }
    return null
  }

  return (
    <div>
      <Header
        onShowStandardForm={() => {
          showForm('text')
        }}
        onShowFileForm={() => {
          showForm('file')
        }}
        currentFormShowing={currentFormShowing}
      />
      {getForm()}
    </div>
  )
}
