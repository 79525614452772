import { Cryptor } from '@samedi/crypto-js'

import { readChunkedEncryptedFile, downloadFileFromStream } from 'services/files'

export interface FileAttributes {
  id: string
  name: string
  decryptionSucceded?: boolean
}

export async function fetchDecryptedFileStream(
  messageID: string,
  file: FileAttributes,
  cryptor: Cryptor
): Promise<void> {
  readChunkedEncryptedFile(`/messages/${messageID}/files/${file.id}`, cryptor).then((stream) => {
    downloadFileFromStream(stream, { filename: file.name })
  })
}
