import { faEye, faEyeSlash, faLockKeyhole } from '@fortawesome/pro-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import cx from 'classnames'
import { Formik, Form, Field } from 'formik'

import React, { useCallback, useState } from 'react'

import { CryptorContext } from 'components/features/Cryptor/CryptorContext'
import { Spinner } from 'components/shared/Spinner/Spinner'
import { useCrypto } from 'hooks/useCrypto'
import { t } from 'i18n'

import { DecryptedSegment } from './DecryptedSegment'

interface FormValues {
  password: string
}

export const EncryptedSegment = () => {
  const [isPasswordRevealed, setIsPasswordRevealed] = useState(false)
  const { authorize, cryptor, isInitialized } = useCrypto()

  const initialValues: FormValues = { password: '' }

  const submit = useCallback(
    async ({ password }, { setFieldError }) => {
      const showPasswordError = () => setFieldError('password', t('login.wrong_password'))
      try {
        const authorization = await authorize(password)
        if (!authorization) {
          showPasswordError()
        }
      } catch {
        showPasswordError()
      }
    },
    [authorize]
  )

  if (!isInitialized) {
    return (
      <div data-testid='homescreen-loading' className='py- flex flex-col items-center'>
        <Spinner className='min-h-[32px] min-w-[32px]' />
      </div>
    )
  }

  if (cryptor !== null) {
    return (
      <CryptorContext.Provider value={cryptor}>
        <DecryptedSegment />
      </CryptorContext.Provider>
    )
  }

  return (
    <div data-testid='homescreen-encrypted-prompt'>
      <h2 className='text-h5'>{t('homescreen.locked.header')}</h2>
      <div className='mt-2 overflow-hidden rounded-lg border border-warning-500 bg-warning-100 px-4 pb-3 pt-4 shadow-sm'>
        <div className='flex'>
          <div className='mr-4'>
            <FontAwesomeIcon className='h-4 w-4 text-warning-900' icon={faLockKeyhole} />
          </div>
          <div>
            <h3 className='mb-1 text-base text-warning-900'>{t('homescreen.locked.title')}</h3>
            <p className='mt-0'>{t('homescreen.locked.message')}</p>
            <Formik
              initialValues={initialValues}
              onSubmit={submit}
              // eslint-disable-next-line react/jsx-no-bind
              validate={(values) => {
                const errors: { [key in keyof FormValues]?: string } = {}
                if (!values.password) {
                  errors.password = t('login.wrong_password')
                }
                return errors
              }}
            >
              {({ errors, isSubmitting }) => (
                <Form>
                  <div className='mt-4 flex flex-row'>
                    <div className='mr-3 grow'>
                      <label className='mb-2 block font-semibold'>
                        {t('homescreen.locked.password_input')}
                      </label>
                      <div className='relative'>
                        <Field
                          className={cx('h-12 w-full grow rounded-lg border px-2 py-3 pr-11', {
                            'border-grey-600': !errors.password,
                            'border-error-500': errors.password
                          })}
                          data-testid='homescreen-encrypted-prompt-password-input'
                          disabled={isSubmitting}
                          name='password'
                          type={isPasswordRevealed ? 'text' : 'password'}
                        />
                        <button
                          aria-label={
                            isPasswordRevealed
                              ? t('password.visibility_toggler.click_to_hide')
                              : t('password.visibility_toggler.click_to_show')
                          }
                          data-testid='visibility-toggler'
                          disabled={isSubmitting}
                          type='button'
                          // eslint-disable-next-line react/jsx-no-bind
                          onClick={() => setIsPasswordRevealed(!isPasswordRevealed)}
                        >
                          <FontAwesomeIcon
                            className='absolute right-2.5 top-4 w-5 text-grey-700'
                            icon={isPasswordRevealed ? faEye : faEyeSlash}
                          />
                        </button>
                      </div>
                    </div>
                    <button
                      className='h-12 self-end rounded-lg bg-primary-600 px-8 py-3 font-bold text-white hover:bg-navy-blue active:opacity-30'
                      disabled={isSubmitting}
                      type='submit'
                      data-testid='homescreen-encrypted-prompt-password-submit'
                    >
                      {t('homescreen.locked.submit')}
                    </button>
                  </div>
                  {errors.password ? (
                    <div
                      data-testid='homescreen-encrypted-prompt-error'
                      className='mt-2 text-s text-error-500'
                    >
                      {errors.password}
                    </div>
                  ) : null}
                </Form>
              )}
            </Formik>
          </div>
        </div>
      </div>
    </div>
  )
}
