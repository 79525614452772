import React from 'react'

import { Template } from './Template'
import { BaseContent as Content } from './styles'

export const Loading: React.FC = () => {
  return (
    <Template>
      <Content />
    </Template>
  )
}
