import { CryptorWithKeys } from 'components/features/Cryptor/models/CryptorService'
import { TextValues, FileValues } from 'components/features/MedicalRecord/EntryForm'
import {
  encryptMedicalRecordEntry,
  NewMedicalRecordEntry
} from 'components/features/MedicalRecord/MedicalRecordEntry'
import uploadAttachment from 'components/features/MedicalRecord/services/uploadAttachment'
import saveMedicalRecordEntry, {
  MedicalRecordEntry
} from 'components/services/saveMedicalRecordEntry'

export const encryptAndSaveMedicalRecordTypeText = async (
  entry: TextValues,
  encryptionKeyId: string,
  cryptorWithKeys: CryptorWithKeys
): Promise<MedicalRecordEntry> => {
  const dataToEncrypt: NewMedicalRecordEntry = {
    type: 'text',
    description: entry.description,
    entry_date: entry.date,
    entry_time: entry.time
  }

  const encryptionResult = await encryptMedicalRecordEntry(
    dataToEncrypt,
    encryptionKeyId,
    cryptorWithKeys.cryptor
  )

  const encryptedRecord = {
    type: entry.formType,
    session_key: encryptionResult.sessionKey,
    encrypted_data: encryptionResult.encryptedData,
    entry_date: entry.date,
    entry_time: entry.time,
    entry_type_id: entry.entryTypeId as number
  }

  await saveMedicalRecordEntry(encryptedRecord)
  return encryptedRecord
}

export const encryptAndSaveMedicalRecordTypeFile = async (
  entry: FileValues,
  encryptionKeyId: string,
  cryptorWithKeys: CryptorWithKeys
): Promise<MedicalRecordEntry> => {
  const dataToEncrypt: NewMedicalRecordEntry = {
    type: 'file',
    description: entry.description,
    entry_date: new Date().toString(),
    attachment_filename: entry.fileName,
    attachment_size: entry.fileSize,
    attachment_data: entry.fileData
  }

  const encryptionResult = await encryptMedicalRecordEntry(
    dataToEncrypt,
    encryptionKeyId,
    cryptorWithKeys.cryptor
  )

  const encryptedRecord = {
    type: entry.formType,
    session_key: encryptionResult.sessionKey,
    encrypted_data: encryptionResult.encryptedData,
    entry_date: dataToEncrypt.entry_date,
    entry_time: dataToEncrypt.entry_time
  }

  const { file_id, encryption_key_id } = await uploadAttachment(entry.file, {
    cryptor: cryptorWithKeys.cryptor,
    userPublicKey: {
      id: cryptorWithKeys.keyPair.id as string,
      public_exponent: cryptorWithKeys.keyPair.public_exponent,
      modulus: cryptorWithKeys.keyPair.modulus
    }
  })

  const encryptedRecordWithFile = Object.assign(encryptedRecord, { file_id, encryption_key_id })

  await saveMedicalRecordEntry(encryptedRecordWithFile)
  return encryptedRecordWithFile
}
