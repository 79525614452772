export function makeReplySubject(str?: string): string {
  const replyPrefix = 'Re:'
  if (!str) {
    return ''
  }
  if (str.startsWith(replyPrefix)) {
    return str
  }
  return `${replyPrefix} ${str}`
}
