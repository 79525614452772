import axios from 'axios'
import sanitizeHtml from 'sanitize-html'

import { CryptorWithKeys } from 'components/features/Cryptor/models/CryptorService'

import { AttachmentAttributes } from './Attachment'

type DecryptedValue = {
  decryptionSucceded: true
  value: string
}

type DecryptionError = {
  decryptionSucceded: false
}

export type DecryptedData = DecryptedValue | DecryptionError

interface CommonMessageAttributes {
  id: string
  sender_practice_id?: string
  sender_name: string
  created_at: string
  is_read: boolean
  receiving_practice_name: string
  is_encrypted: boolean
}

export interface DecryptedFile {
  id: string
  name: string
}

export interface DecryptedMessageAttributes extends CommonMessageAttributes {
  decrypted_sanitized_body_html: DecryptedData
  decrypted_subject: DecryptedData
  attachments: AttachmentAttributes[]
  files: DecryptedFile[]
}

export interface EncryptedMessageAttributes extends CommonMessageAttributes {
  subject: string
  encrypted_subject: string
  body: string
  encrypted_body: string
  attachments: AttachmentAttributes[]
  files: EncryptedFilesAttributes[]
}

interface EncryptedFilesAttributes {
  id: string
  encrypted_name: string
}

export interface DecryptedMessageSummaryAttributes extends CommonMessageAttributes {
  decrypted_subject: DecryptedData
}

interface EncryptedMessageSummaryAttributes extends CommonMessageAttributes {
  subject: string
  encrypted_subject: string
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
async function decryptData(encrypted_string: string, cryptor: CryptorWithKeys): Promise<any> {
  if (!encrypted_string) {
    return { decryptionSucceded: false }
  }

  return await cryptor.cryptor
    .decrypt(encrypted_string)
    .then((value) => {
      return { decryptionSucceded: true, value: value }
    })
    .catch(() => {
      return { decryptionSucceded: false }
    })
}

export async function decryptFileNames(
  files: EncryptedFilesAttributes[],
  cryptor: CryptorWithKeys
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
): Promise<any> {
  return Promise.all(
    files.map(async (file) => {
      const decryptedName = await decryptData(file.encrypted_name, cryptor)
      return {
        id: file.id,
        name: decryptedName.value,
        decryptionSucceded: decryptedName.decryptionSucceded
      }
    })
  )
}

async function decryptMessageSummary(
  attrs: EncryptedMessageSummaryAttributes,
  cryptor: CryptorWithKeys
): Promise<DecryptedMessageSummaryAttributes> {
  let subject = { decryptionSucceded: true, value: attrs.subject }
  if (attrs.encrypted_subject) {
    subject = await decryptData(attrs.encrypted_subject, cryptor)
  }

  return {
    ...attrs,
    decrypted_subject: subject
  }
}

export function sanitize(input: string): string {
  return sanitizeHtml(input, {
    allowedTags: ['b', 'i', 'em', 'strong', 'a', 'br', 'p'],
    allowedAttributes: { a: ['href'] },
    allowedSchemes: ['http', 'https']
  })
}

async function decryptMessageDetail(
  attrs: EncryptedMessageAttributes,
  cryptor: CryptorWithKeys
): Promise<DecryptedMessageAttributes> {
  let subject = { decryptionSucceded: true, value: attrs.subject }
  if (attrs.encrypted_subject) {
    subject = await decryptData(attrs.encrypted_subject, cryptor)
  }

  let body = { decryptionSucceded: true, value: attrs.body }
  if (attrs.encrypted_body) {
    body = await decryptData(attrs.encrypted_body, cryptor)
  }
  body.value = sanitize(body.value)

  return {
    ...attrs,
    decrypted_subject: subject,
    decrypted_sanitized_body_html: body,
    files: await decryptFileNames(attrs.files, cryptor)
  }
}

export function isIncomingMessage(options: DecryptedMessageSummaryAttributes): boolean {
  return options.receiving_practice_name === null && options.sender_practice_id != null
}

export async function setMessageReadStatus(
  messageID: string,
  newReadStatus: boolean
): Promise<boolean> {
  const path = `/messages/${messageID}/${newReadStatus ? 'mark_read' : 'mark_unread'}.json`
  await axios.post(path)
  return newReadStatus
}

export async function deleteMessage(messageID: string): Promise<void> {
  const path = `/api/messages/v1/messages/${messageID}.json`
  await axios.delete(path)
}

interface Pagination {
  total_pages: number
  unread: number
}

interface FetchMessagesResponse {
  meta: Pagination
  messages: EncryptedMessageSummaryAttributes[]
}

export async function fetchMessages(
  folder: 'inbox' | 'outbox',
  page: number,
  cryptor: CryptorWithKeys
): Promise<{ meta: Pagination; messages: DecryptedMessageSummaryAttributes[] }> {
  const response = await axios.get(`/api/messages/v1/${folder}.json`, { params: { page: page } })
  const responseData = response.data as FetchMessagesResponse
  const messageListDecrypted = Promise.all(
    responseData.messages.map(
      async (messageData) => await decryptMessageSummary(messageData, cryptor)
    )
  )
  return { meta: responseData.meta, messages: await messageListDecrypted }
}

export async function fetchMessage(
  messageID: string,
  cryptor: CryptorWithKeys
): Promise<DecryptedMessageAttributes> {
  const response = await axios.get(`/api/messages/v1/messages/${messageID}.json`)
  const responseData = response.data as EncryptedMessageAttributes
  return await decryptMessageDetail(responseData, cryptor)
}
