import * as React from 'react'

import { useState, useEffect } from 'react'

import { update as updateEntryShare } from '../services/MedicalRecordEntryShare'

import {
  MedicalRecordEntryShare,
  DecryptedMedicalRecordEntryShare
} from './MedicalRecordEntryShare'

import { SendRequestsListView } from './SendRequestsListView'

interface Props {
  entries: DecryptedMedicalRecordEntryShare[]
  onAcceptSharedEntry: () => void
  onResolveSharedEntry: () => void
}

const mobileWidth = 1200

export const SendRequestsList: React.FC<Props> = function ({
  entries,
  onAcceptSharedEntry,
  onResolveSharedEntry
}) {
  const [windowWidth, setWindowWidth] = useState(window.innerWidth)
  const [isMobile, setIsMobile] = useState<boolean>(false)

  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/no-use-before-define
    window.addEventListener('resize', handleWindowSizeChange)
    setWindowWidth(window.innerWidth)
    // eslint-disable-next-line @typescript-eslint/no-use-before-define
    return () => window.removeEventListener('resize', handleWindowSizeChange)
  }, [])

  useEffect(() => {
    setIsMobile(windowWidth <= mobileWidth)
  }, [windowWidth])

  const handleWindowSizeChange = () => {
    setWindowWidth(window.innerWidth)
  }

  const handleAcceptEntry = async (entry: MedicalRecordEntryShare) => {
    await updateEntryShare({ share: entry, status: 'accept' })
    onResolveSharedEntry()
    onAcceptSharedEntry()
  }

  const handleRejectEntry = async (entry: MedicalRecordEntryShare) => {
    await updateEntryShare({ share: entry, status: 'reject' })
    onResolveSharedEntry()
  }

  if (!entries || !entries.length) {
    return null
  }

  return (
    <SendRequestsListView
      entries={entries}
      onAcceptEntry={handleAcceptEntry} // eslint-disable-line react/jsx-no-bind
      onRejectEntry={handleRejectEntry} // eslint-disable-line react/jsx-no-bind
      isMobile={isMobile}
    />
  )
}
