import React from 'react'

import { t } from 'i18n'
import instituteImg from 'images/instituteImg.png'

import { Contact as IContact } from './types'

interface Props {
  contact: IContact
}

export const Contact: React.FC<Props> = ({ contact }) => (
  <li
    data-testid={`homescreen-recent-contacts-contact-${contact.id}`}
    className='flex items-center border-b border-grey-200 px-4 py-2'
  >
    <img className='mr-4 h-10 w-10' src={instituteImg} />
    <div className='flex grow flex-col justify-center pr-2'>
      <span className='text-h5 font-semibold'>{contact.practiceName}</span>
      {contact.personName && <span className='leading-5 text-grey-800'>{contact.personName}</span>}
    </div>
    <a
      data-testid={`homescreen-recent-contacts-contact-${contact.id}-book`}
      className='flex justify-center whitespace-nowrap rounded-md bg-light-blue px-8 py-2.5 font-bold leading-5 text-primary-600 hover:bg-light-blue-darker active:bg-light-blue-darker active:opacity-30'
      href={contact.bookingUrl}
    >
      {t('homescreen.recent_contacts.book_again')}
    </a>
  </li>
)
