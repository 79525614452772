/* eslint-disable @typescript-eslint/no-use-before-define */
import { faPaperPlane } from '@fortawesome/pro-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import autosize from 'autosize'
import React, { ChangeEventHandler, useCallback, useEffect, useRef, useState } from 'react'
import styled from 'styled-components'

import { t } from 'i18n'

import { colors } from './styles'

// types
type Styles = Partial<{
  height: number
}>

export type ConversationInputProps = {
  onSubmit: (text: string) => void
  deletedContact: boolean
  className?: string
}

// components
export const ConversationInput: React.FC<ConversationInputProps> = ({
  onSubmit,
  deletedContact,
  className
}) => {
  const [text, setText] = useState('')
  const ref = useRef<HTMLTextAreaElement>(null)

  useEffect(() => {
    updateTextareaHeight(ref.current)
  }, [text])

  const handleTextChange: ChangeEventHandler<HTMLTextAreaElement> = useCallback((e) => {
    setText(e.target.value)
  }, [])

  const handleButtonClick = useCallback(() => {
    onSubmit(text)
    setText('')
    resetTextareaHeight(ref.current)
  }, [onSubmit, text])

  const iconColor = text ? colors.white : colors.grey10

  return (
    <Container className={className} data-testid='chat-input-container'>
      <Textarea ref={ref} value={text} onChange={handleTextChange} data-testid='chat-input' />
      <Button
        onClick={handleButtonClick}
        disabled={!text.trim() || deletedContact}
        title={deletedContact ? t('conversation.deleted_contact') : ''}
        data-testid='send_message'
      >
        <FontAwesomeIcon icon={faPaperPlane} color={iconColor} />
      </Button>
    </Container>
  )
}

// styles
const Container = styled.div`
  display: flex;
  align-items: flex-end;
`

const Textarea = styled.textarea`
  outline: none;
  resize: none;
  display: flex;
  flex: 1;
  box-sizing: border-box;
  border-radius: 8px;
  width: 100%;
  margin-right: 12px;
  padding: 4px 16px 8px 16px;
  font-family: 'Source Sans Pro';
  font-style: normal;
  font-size: 16px;
  line-height: 24px;
  max-height: 90px;
  resize: none;
  background: ${colors.white};
  border: 1px solid ${colors.grey2};
  color: ${colors.grey8};
  ${({ height = 36 }: Styles) => `height: ${height}px`};
`

const Button = styled.button`
  border: none;
  width: 62px;
  height: 38px;
  background: ${colors.blue3};
  border-radius: 5px;

  &:disabled {
    background: ${colors.grey90};
  }
`

// utils
const updateTextareaHeight = (element: HTMLTextAreaElement | null) => {
  if (!element) {
    return
  }

  autosize(element)
}

const resetTextareaHeight = (element: HTMLTextAreaElement | null) => {
  if (!element) {
    return
  }

  autosize.destroy(element)
}
