import axios from 'axios'
const MAX_UPLOAD_RETRIES = 3
const CHUNK_SIZE_LIMIT = 5242880 // 5MB unencrypted

interface StoreChunkResponse {
  data: {
    id: string
  }
}

export default class ChunkedFileUploader {
  private currentChunk: string = ''
  private currentChunkSize: number = 0
  private uploadRetriesCount: number = 0
  private onChunkUploaded: (chunkSize: number, currentChunkPosition: number) => void
  private storedChunkIds: string[]
  private currentChunkPosition: number

  constructor(onChunkUploaded: (chunkSize: number, currentChunkPosition: number) => void) {
    this.storeChunk = this.storeChunk.bind(this)
    this.onChunkUploaded = onChunkUploaded
    this.currentChunkPosition = 1
    this.storedChunkIds = []
  }

  async createFile(encryptedName: string, encryptedMetaData: string) {
    const response = await axios.post('/files/file.json', {
      file: {
        encrypted_name: encryptedName,
        encrypted_meta_data: encryptedMetaData,
        chunk_ids: this.storedChunkIds
      }
    })

    return response.data.id
  }

  async storeChunk(chunkSize: number, encryptedChunk: string) {
    this.currentChunkSize += chunkSize
    this.currentChunk += encryptedChunk
    this.onChunkUploaded(chunkSize, this.currentChunkPosition)

    if (this.currentChunkSize >= CHUNK_SIZE_LIMIT) {
      const response = await this.performStoreChunkRequest()

      const chunkId = response.data.id
      this.storedChunkIds.push(chunkId)
      this.prepareForNewChunk()
    }
  }

  async finalize() {
    // store last chunk if it wasn't stored by storeChunk function
    // because it didn't reach our wanted chunk size
    if (this.currentChunk.length) {
      const response = await this.performStoreChunkRequest()

      const chunkId = response.data.id
      this.storedChunkIds.push(chunkId)
    }
  }

  private async performStoreChunkRequest(): Promise<StoreChunkResponse> {
    let response

    try {
      response = await axios.post(`/files/file_chunks.json`, {
        file_chunk: {
          encrypted_data: this.currentChunk,
          position: this.currentChunkPosition
        }
      })
    } catch (error) {
      if (this.shouldRetry(error)) {
        this.uploadRetriesCount += 1
        return this.performStoreChunkRequest()
      } else {
        throw error
      }
    }

    return response
  }

  private prepareForNewChunk() {
    this.currentChunk = ''
    this.currentChunkSize = 0
    this.uploadRetriesCount = 0
    this.currentChunkPosition += 1
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  private shouldRetry(error: any): boolean {
    return (
      error.response &&
      error.response.status !== 400 &&
      error.response.status !== 404 &&
      this.uploadRetriesCount < MAX_UPLOAD_RETRIES
    )
  }
}
