import { useQuery } from '@tanstack/react-query'
import React, { useContext } from 'react'

import { CryptorContext } from 'components/features/Cryptor/CryptorContext'
import { Spinner } from 'components/shared/Spinner/Spinner'

import { UnreadConversations } from '../../UnreadConversations'
import { fetchEncryptedData } from '../api'

export const DecryptedSegment = () => {
  const cryptor = useContext(CryptorContext)
  const { isLoading, data } = useQuery(['homescreen_encrypted'], fetchEncryptedData(cryptor))

  if (isLoading || !data) {
    return (
      <div data-testid='homescreen-loading' className='py- flex flex-col items-center'>
        <Spinner className='min-h-[32px] min-w-[32px]' />
      </div>
    )
  }

  return (
    <>
      {data.unreadConversations.length > 0 && (
        <UnreadConversations conversations={data.unreadConversations} />
      )}
    </>
  )
}
