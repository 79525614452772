import { faComments } from '@fortawesome/pro-regular-svg-icons'
import { differenceInHours } from 'date-fns'
import React from 'react'

import { dateFormat, t } from 'i18n'
import instituteImg from 'images/instituteImg.png'

import LinkToAll from '../LinkToAll'

import { Conversation } from './types'

interface Props {
  conversations: Conversation[]
}

const relativeDateText = (date: Date) => {
  const now = new Date()
  const hoursDiff = differenceInHours(now, date)
  if (hoursDiff < 24) {
    return dateFormat(date, 'HH:mm')
  } else if (hoursDiff < 48) {
    return t('yesterday')
  } else {
    return dateFormat(date)
  }
}

export const UnreadConversations: React.FC<Props> = ({ conversations }) => (
  <div data-testid='homescreen-conversations'>
    <h2 className='text-h5'>{t('homescreen.conversations.header')}</h2>
    <div className='mt-2 overflow-hidden rounded-lg bg-white shadow-sm'>
      {conversations.map(({ contactId, contactName, lastMessage, unreadCount }) => (
        <a
          className='group flex self-center border-b border-b-grey-200 p-4 hover:bg-light-blue hover:text-primary-600 active:bg-primary-600 active:text-white'
          data-testid={`homescreen-conversations-navigate-contact-${contactId}`}
          href={`/conversations/${contactId}`}
          key={['conversation', contactId].join('$')}
          title={t('homescreen.conversations.a11y.conversation_title')}
        >
          <img className='mr-4 h-10 w-10' src={instituteImg} />
          <div className='flex grow flex-col overflow-hidden'>
            <div className='flex w-full flex-row items-center'>
              <div className='grow overflow-hidden text-h5 font-semibold'>
                <div
                  data-testid={`homescreen-conversation-${contactId}-contact-name`}
                  className='overflow-hidden text-ellipsis whitespace-nowrap'
                >
                  {contactName}
                </div>
              </div>
              <div className='pl-2 text-label text-grey-700 group-hover:text-primary-600 group-active:text-white'>
                {relativeDateText(lastMessage.createdAt)}
              </div>
            </div>
            <div className='flex flex-row items-center'>
              <div className='grow overflow-hidden'>
                <div className='overflow-hidden text-ellipsis whitespace-nowrap'>
                  {lastMessage.content}
                </div>
              </div>
              <div className='ml-2 h-5 w-5 rounded-full bg-secondary-500 text-center text-s leading-[19px] text-white'>
                <span
                  data-testid={`homescreen-conversation-${contactId}-unread-counter`}
                  className='block w-5'
                >
                  {unreadCount > 9 ? '9+' : unreadCount}
                </span>
              </div>
            </div>
          </div>
        </a>
      ))}

      <LinkToAll
        href='/conversations'
        title={t('homescreen.conversations.see_all')}
        icon={faComments}
        text={t('homescreen.conversations.see_all')}
        testID='homescreen-conversations-navigate-all'
      />
    </div>
  </div>
)
