import React from 'react'

import { t } from 'i18n'

import { NextAppointment } from './NextAppointment'
import { NoUpcomingAppointment } from './NoUpcomingAppointment'
import { NextAppointment as INextAppointment } from './types'

interface Props {
  appointment: INextAppointment | null
}

export const UpcomingAppointment: React.FC<Props> = ({ appointment }) => (
  <div>
    <h2 className='text-h5'>{t('homescreen.upcoming_appointment.header')}</h2>
    {appointment ? <NextAppointment appointment={appointment} /> : <NoUpcomingAppointment />}
  </div>
)
