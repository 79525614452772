import { Form, Formik } from 'formik'

import React from 'react'
import styled from 'styled-components'

import { ProgressButton } from 'components/shared/Buttons'
import { PasswordInput } from 'components/shared/PasswordInput'
import { t } from 'i18n'

export type PasswordSubmissionFunction = (password: string) => Promise<void>

interface Props {
  onSubmit: PasswordSubmissionFunction
  title: string
  lockReason: string
  descriptionHTML: string
  unlockErrorMessage: string
}

export const PasswordForm: React.FC<Props> = function ({
  onSubmit,
  title,
  lockReason,
  descriptionHTML,
  unlockErrorMessage
}) {
  const handleSubmit = (
    values: { password: string },
    setSubmitting: (value: boolean) => void,
    setFieldValue: (field: string, value: string) => void
  ) => {
    return onSubmit(values.password).catch(() => {
      alert(unlockErrorMessage)
      setFieldValue('password', '')
      setSubmitting(false)
    })
  }

  return (
    // eslint-disable-next-line react/jsx-no-bind
    <Formik initialValues={{ password: '' }} onSubmit={() => {}}>
      {({ values, handleChange, handleBlur, isSubmitting, setSubmitting, setFieldValue }) => {
        return (
          <Form className='f-password-form' onSubmit={undefined}>
            {/*eslint-disable-next-line @typescript-eslint/no-use-before-define*/}
            <FormTitle>
              <h2>{title}</h2>
              <p>{lockReason}</p>
              <p
                dangerouslySetInnerHTML={{
                  __html: descriptionHTML
                }}
              />
            </FormTitle>

            <div className='s-form-group--with-columns'>
              <div className='s-form-group__column'>
                <label
                  htmlFor='unlock-password'
                  className='password required control-label o-form-label'
                >
                  {t('MEDICAL_RECORD/PASSWORD_PLACEHOLDER')}
                </label>
                <PasswordInput
                  className='o-text-field'
                  id='unlock-password'
                  name='password'
                  value={values.password}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  disabled={isSubmitting}
                />
              </div>

              <div className='s-form-group__column'>
                <ProgressButton
                  data-testid='unlock-password'
                  // eslint-disable-next-line react/jsx-no-bind
                  onClick={() => handleSubmit(values, setSubmitting, setFieldValue)}
                  type='submit'
                >
                  {t('MEDICAL_RECORD/UNLOCK')}
                </ProgressButton>
              </div>
            </div>
          </Form>
        )
      }}
    </Formik>
  )
}

const FormTitle = styled.div`
  margin-bottom: 30px;
`
