/* eslint-disable no-restricted-globals */
export interface FileResult {
  fileName: string
  fileSize: string
  fileData: string
}

export default function loadFile(file: File): Promise<FileResult> {
  const result = {
    fileName: file.name,
    fileSize: file.size.toString(),
    fileData: ''
  }
  return new Promise((resolve) => {
    const reader = new FileReader()
    reader.addEventListener('loadend', () => {
      // we leave the data empty if it's an empty file
      if (reader.result && reader.result !== 'data:') {
        result.fileData = (reader.result as string).replace(/^.*base64,/, '')
      }
      resolve(result)
    })
    reader.readAsDataURL(file)
  })
}
