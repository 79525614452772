import { t } from 'i18n'

export function fileUploadSizeLimitTranslation(): string {
  return t('medical_record/maximum_file_size_20')
}

export function fileUploadSizeExceededTranslation(): string {
  return t('medical_record/allowed_size_exceeded_20')
}

const ONE_MEGABYTE = 1024 * 1024
const TWENTY_MEGABYTES = 20 * ONE_MEGABYTE

export function fileUploadSizeLimit(): number {
  return TWENTY_MEGABYTES
}
