/* eslint-disable @typescript-eslint/no-use-before-define */
import React from 'react'
import styled from 'styled-components'

import { ContactWithMessage } from '../types'

import { ConversationListItem } from './ConversationListItem'
import { colors } from './styles'

// types
export type ConversationListColumnProps = {
  contacts: ContactWithMessage[]
  handleSelectConversation: (conversationId: string) => void
  selectedContactId?: string
  isActive: boolean
}

// components
export const ConversationListColumn: React.FC<ConversationListColumnProps> = ({
  contacts,
  handleSelectConversation,
  selectedContactId,
  isActive
}) => {
  return (
    <Container isActive={isActive} className='conversation-list-column'>
      <InnerContainer>
        {contacts?.map(({ practice_id, practice_name, latest_message, unread_messages_count }) => (
          // eslint-disable-next-line react/jsx-no-bind
          <li key={practice_id} onClick={() => handleSelectConversation(practice_id)}>
            <ConversationListItem
              contactName={practice_name}
              latestMessageBody={latest_message?.body}
              latestMessageDate={latest_message?.created_at}
              unreadMessageCount={unread_messages_count}
              isHighlighted={selectedContactId === practice_id}
            />
          </li>
        ))}
      </InnerContainer>
    </Container>
  )
}

type ContainerProps = {
  isActive: boolean
}

// styles
const Container = styled.div<ContainerProps>`
  border-right: 2px solid ${colors.grey1};
  padding: 0 8px;
  overflow: scroll;
  width: 100%;

  // only on small screen, we hide the column if it's not active
  ${({ isActive }) => (isActive ? '' : 'display: none')};

  @media (min-width: 1024px) {
    display: block;
    width: 334px;
  }
`

const InnerContainer = styled.ul`
  list-style: none;
  margin: 0;
  padding: 8px 0;
`
