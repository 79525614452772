import axios, { AxiosResponse } from 'axios'
import { parseISO } from 'date-fns'

import { EncryptedMessage } from 'components/features/Conversations/types'
import { decryptMessage } from 'components/features/Conversations/utils/decryptMessage'
import { CryptorWithKeys } from 'components/features/Cryptor/models/CryptorService'

import { transformInitialData } from './transform'
import { BasicDashboard, BasicDashboardServer, DecryptedData, EncryptedDataServer } from './types'

export const fetchInitialData = async (): Promise<BasicDashboard> =>
  transformInitialData((await axios.get<BasicDashboardServer>('/homescreen/initial_data')).data)

const decryptData =
  (cryptor: CryptorWithKeys) =>
  async (response: AxiosResponse<EncryptedDataServer>): Promise<DecryptedData> => {
    return {
      unreadConversations: await Promise.all(
        response.data.unread_contacts.map(async (item) => {
          const message = await decryptMessage(item.latest_message as EncryptedMessage, cryptor)
          return {
            contactId: item.practice_id,
            contactName: item.practice_name || '',
            unreadCount: item.unread_messages_count,
            lastMessage: {
              content: message.body,
              createdAt: parseISO(message.created_at)
            }
          }
        })
      )
    }
  }

export const fetchEncryptedData = (cryptor: CryptorWithKeys) => (): Promise<DecryptedData> =>
  axios.get<EncryptedDataServer>('/homescreen/encrypted_segment').then(decryptData(cryptor))

export const createTwoFactorModalSession: () => void = () =>
  axios.post('/homescreen/two_factor_sessions')
