import { faCalendarPlus } from '@fortawesome/pro-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React from 'react'

import { t } from 'i18n'
import doctorImage from 'images/no-upcoming-appointment.png'

export const NoUpcomingAppointment: React.FC = () => (
  <div
    data-testid='homescreen-no-upcoming-appointment'
    className='mt-2 rounded-lg border border-grey-400 bg-grey-200 p-8 text-center'
  >
    <img className='m-auto max-w-[165px] text-center' src={doctorImage} />
    <h3 className='mt-5 text-hero font-semibold text-grey-800'>
      {t('homescreen.no_upcoming_appointment.title')}
    </h3>
    <p className='mt-2 text-h5 text-grey-700'>
      {t('homescreen.no_upcoming_appointment.description')}
    </p>
    <h5 className='mb-0 mt-8 text-h5 text-grey-800'>
      {t('homescreen.no_upcoming_appointment.cta_title')}
    </h5>
    <p className='mt-2 text-h5 text-grey-700'>
      {t('homescreen.no_upcoming_appointment.cta_description')}
    </p>
    <a
      className='mt-6 flex justify-center rounded-lg bg-primary-600 py-4 font-bold leading-5 text-white hover:bg-navy-blue active:opacity-30'
      href='/searches'
      data-testid='homescreen-no-upcoming-appointment-navigate-all'
      title={t('homescreen.no_upcoming_appointment.cta_link')}
    >
      <FontAwesomeIcon className='h-5 w-5 pr-2' icon={faCalendarPlus} />
      {t('homescreen.no_upcoming_appointment.cta_link')}
    </a>
  </div>
)
