import {
  faPenToSquare,
  faArrowUpRightFromSquare,
  faDownload,
  faFilePlus
} from '@fortawesome/pro-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { useContext, useMemo, useState, ReactNode } from 'react'

import { ConfirmationModal } from 'components/features/ConfirmationModal'
import { CryptorContext } from 'components/features/Cryptor/CryptorContext'
import { Table } from 'components/shared/Tables/Table'
import { relativeTime, t } from 'i18n'

import FormIssuer from './Cells/FormIssuer'
import FormTitle from './Cells/FormTitle'
import { Attributes } from './models/PatientForm'
import addToMedicalRecordAsDocx from './services/addToMedicalRecordAsDocx'
import downloadAsDocx from './services/downloadAsDocx'

interface Props {
  forms: Attributes[]
}

export interface FormsColumn {
  id: string
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  accessor: (arg0: any) => string | JSX.Element | undefined | null | ReactNode
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  Cell: (cell: any) => JSX.Element
  Header?: string
  minWidth?: number
  className?: string
  borderLeft?: boolean
  borderBottom?: boolean
}

const FormsTable: React.FC<Props> = function ({ forms }) {
  const cryptorWithKeys = useContext(CryptorContext)
  const [addingToMedicalRecordFormId, setAddingToMedicalRecordFormId] = useState<false | string>(
    false
  )

  const columns: FormsColumn[] = useMemo(
    () => [
      {
        id: 'title',
        accessor: ({
          id,
          title,
          completedAt,
          sentAt,
          draftSavedAt,
          practiceName,
          appointmentName,
          appointmentStartsAt
        }: Attributes) => ({
          id,
          title,
          completedAt,
          sentAt,
          draftSavedAt,
          practiceName,
          appointmentName,
          appointmentStartsAt
        }),
        Cell: ({ cell }) => {
          const form = cell.value

          return (
            <div className='f-forms__info-container'>
              <FormTitle
                id={form.id}
                completedAt={form.completedAt}
                sentAt={form.sentAt}
                title={form.title}
              />
              <FormIssuer
                practiceName={form.practiceName}
                appointmentName={form.appointmentName}
                appointmentStartsAt={form.appointmentStartsAt}
              />
            </div>
          )
        },
        className: 'f-forms__cell f-forms__info'
      },
      {
        id: 'actions',
        accessor: ({ id, completedAt, draftSavedAt, hasPrintTemplate }) => ({
          id,
          completedAt,
          draftSavedAt,
          hasPrintTemplate
        }),
        Cell: ({ cell }) => {
          const { id, completedAt, draftSavedAt, hasPrintTemplate } = cell.value

          return (
            <>
              {completedAt && hasPrintTemplate && (
                <>
                  <button
                    className='o-clickable f-forms__action-button'
                    // eslint-disable-next-line react/jsx-no-bind
                    onClick={() => downloadAsDocx(id, cryptorWithKeys.cryptor)}
                  >
                    <FontAwesomeIcon icon={faDownload} className='h-margin-right--8' />
                  </button>
                  <button
                    className='o-clickable f-forms__action-button'
                    // eslint-disable-next-line react/jsx-no-bind
                    onClick={() => setAddingToMedicalRecordFormId(id)}
                  >
                    <FontAwesomeIcon icon={faFilePlus} className='h-margin-right--8' />
                  </button>
                </>
              )}
              <a className='o-clickable f-forms__action-button' href={`/patient_forms/${id}`}>
                {completedAt ? (
                  <FontAwesomeIcon icon={faArrowUpRightFromSquare} className='h-margin-right--8' />
                ) : (
                  <>
                    <FontAwesomeIcon icon={faPenToSquare} className='h-margin-right--8' />
                    <span>{draftSavedAt ? t('forms.complete_draft') : t('forms.fill')}</span>
                  </>
                )}
              </a>
              {!completedAt && draftSavedAt && (
                <div className='f-forms__draft_notification h-color--grey-60'>
                  {`${t(
                    'forms.draft_last_saved',
                    relativeTime(draftSavedAt, { addSuffix: true, notAfter: new Date() })
                  )}`}
                </div>
              )}
            </>
          )
        },
        className: 'f-forms__cell f-forms__action',
        borderLeft: false
      }
    ],
    [cryptorWithKeys]
  )

  const addCurrentFormToMedicalRecordAsDocx = async () => {
    if (addingToMedicalRecordFormId === false) {
      return
    }

    await addToMedicalRecordAsDocx(
      addingToMedicalRecordFormId,
      cryptorWithKeys,
      cryptorWithKeys.encryptionKey.id
    )
    setAddingToMedicalRecordFormId(false)
  }

  return (
    <>
      <Table columns={columns} data={forms} defaultFont noColorSwitch noHeader hasMarginBottom />
      {addingToMedicalRecordFormId && (
        <ConfirmationModal
          title={t('forms.add_to_medical_record_confirmation_window.title')}
          confirmButtonLabel={t(
            'forms.add_to_medical_record_confirmation_window.confirmation_button_label'
          )}
          // eslint-disable-next-line react/jsx-no-bind
          onCancel={() => setAddingToMedicalRecordFormId(false)}
          // eslint-disable-next-line react/jsx-no-bind
          onConfirm={() => addCurrentFormToMedicalRecordAsDocx()}
        >
          <p>{t('forms.add_to_medical_record_confirmation_window.body')}</p>
        </ConfirmationModal>
      )}
    </>
  )
}

export default FormsTable
