import { Cryptor } from '@samedi/crypto-js'
import axios from 'axios'

import { CryptorWithKeys } from 'components/features/Cryptor/models/CryptorService'
import { t } from 'i18n'

import { fetchPractice } from './Practice'

interface UnsavedMessage {
  subject: string
  body_html: string
  receiving_practice_id: string
}

interface UnsavedEncryptedMessage {
  message: {
    encrypted_subject: string
    encrypted_body: string
    subject: 'encrypted message'
    body: 'encrypted message'
    receiving_practice_id: string
  }
  recipient_attributes: {
    encrypted_subject: string
    encrypted_body: string
  }
}

interface PublicKeyPair {
  public_exponent: string
  modulus: string
  id?: string | number
}

async function encryptWithKeyPair(
  cryptor: Cryptor,
  message: string,
  keyPair: PublicKeyPair
): Promise<string> {
  return cryptor.asymmetricEncrypt(
    message,
    keyPair.id ? keyPair.id.toString() : undefined,
    keyPair.public_exponent,
    keyPair.modulus
  )
}

async function encryptUnsavedMessage(
  message: UnsavedMessage,
  recipientKeyPair: PublicKeyPair,
  senderKeyPair: PublicKeyPair,
  cryptor: Cryptor
): Promise<UnsavedEncryptedMessage> {
  return {
    message: {
      receiving_practice_id: message.receiving_practice_id,
      subject: 'encrypted message',
      body: 'encrypted message',
      encrypted_subject: await encryptWithKeyPair(cryptor, message.subject, senderKeyPair),
      encrypted_body: await encryptWithKeyPair(cryptor, message.body_html, senderKeyPair)
    },
    recipient_attributes: {
      encrypted_subject: await encryptWithKeyPair(cryptor, message.subject, recipientKeyPair),
      encrypted_body: await encryptWithKeyPair(cryptor, message.body_html, recipientKeyPair)
    }
  }
}

interface CreateMessageResult {
  success: boolean
  id: string
}

export async function encryptAndSave(
  message: UnsavedMessage,
  cryptor: CryptorWithKeys
): Promise<string> {
  const practice = await fetchPractice(message.receiving_practice_id)
  const recipientKeyPair = practice.key_pair

  if (!recipientKeyPair) {
    throw new Error(t('messaging.no_key_pair'))
  }

  const encryptedMessage = await encryptUnsavedMessage(
    message,
    recipientKeyPair,
    cryptor.keyPair,
    cryptor.cryptor
  )
  const result = await axios.post('/api/messages/v1/messages.json', encryptedMessage)
  return (result.data as CreateMessageResult).id
}
