import { Cryptor } from '@samedi/crypto-js'

import { PublicKey, generateSharedEncryptionKey } from 'services/encryption'
import { uploadThroughEncryptedStream } from 'services/files'

interface EncryptionProps {
  cryptor: Cryptor
  userPublicKey: PublicKey
}

export default async function uploadAttachment(file: File, encryption: EncryptionProps) {
  const encryptionKeyId = await generateSharedEncryptionKey(
    encryption.cryptor,
    encryption.userPublicKey
  )

  const fileId = await uploadThroughEncryptedStream(file, encryptionKeyId, encryption.cryptor)

  return {
    file_id: fileId,
    encryption_key_id: encryptionKeyId
  }
}
