import axios from 'axios'

export interface PublicKeyPairAttributes {
  id: number
  public_exponent: string
  modulus: string
}

export interface PracticeAttributes {
  name: string
  id: string
  key_pair: PublicKeyPairAttributes | null
  medical_record_package_active: boolean
}

export async function fetchPractice(id: string): Promise<PracticeAttributes> {
  const response = await axios.get(`/practices/${id}.json`)
  const practice = response.data as PracticeAttributes

  return practice
}
