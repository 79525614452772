import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
import React from 'react'
import { createBrowserRouter, Routes, Route, RouterProvider } from 'react-router-dom'

import { ConversationsCryptorContainer } from 'components/features/Conversations/containers/ConversationsCryptorContainer'
import { FormsListContainer } from 'components/features/Forms'

import { Login } from './Authentication/Login'
import CodeVerificationContainer from './CodeVerification/CodeVerificationContainer'
import { Dashboard } from './Homescreen/Dashboard'
import { MedicalRecordContainer } from './MedicalRecord/MedicalRecordContainer'
import { MessagingContainer } from './Messaging'

import { SecondFactorSettings } from './Settings/SecondFactor'

const RouterComponent = () => (
  <Routes>
    <Route path='login' element={<Login />} />
    <Route path='verifycode' element={<CodeVerificationContainer />} />
    <Route path='medical_record/*' element={<MedicalRecordContainer />} />
    <Route path='messages/*' element={<MessagingContainer />} />
    <Route path='conversations' element={<ConversationsCryptorContainer />} />
    <Route path='conversations/:selected' element={<ConversationsCryptorContainer />} />
    <Route path='patient_forms/*' element={<FormsListContainer />} />
    <Route path='homescreen/*' element={<Dashboard />} />
    <Route path='settings/second_factor' element={<SecondFactorSettings />} />
  </Routes>
)

export const ReactDashboard: React.FC<{}> = () => {
  const queryClient = new QueryClient()

  const router = createBrowserRouter([{ path: '*', Component: RouterComponent }])

  return (
    <QueryClientProvider client={queryClient}>
      <RouterProvider router={router} />
    </QueryClientProvider>
  )
}
