/* eslint-disable react/forbid-dom-props */
import React from 'react'

import { t } from 'i18n'

interface Props {
  onCancel: () => void
  disableSaving?: boolean
}

export const FormButtons: React.FC<Props> = function ({ onCancel, disableSaving }) {
  return (
    <div className='s-form-group'>
      <div className='s-button-group s-button-group--left-aligned'>
        <button type='submit' className='s-button s-button-group__button' disabled={disableSaving}>
          {t('medical_record/save')}
        </button>
        <button className='s-button s-button--secondary s-button-group__button' onClick={onCancel}>
          {t('MEDICAL_RECORD/CANCEL')}
        </button>
      </div>
    </div>
  )
}
