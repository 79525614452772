import React from 'react'

import { CryptorUnlocker } from 'components/features/Cryptor'
import { CryptorContext } from 'components/features/Cryptor/CryptorContext'
import { PasswordForm, PasswordSubmissionFunction } from 'components/shared/PasswordForm'
import { t } from 'i18n'

import { ConversationsDataContainer } from './ConversationsDataContainer'

interface PasswordProps {
  onSubmitPassword: PasswordSubmissionFunction
}

export const ConversationsCryptorContainer: React.FC = function () {
  return (
    // eslint-disable-next-line @typescript-eslint/no-use-before-define
    <CryptorUnlocker passwordForm={passwordForm}>
      {(cryptorWithKeys) => (
        <CryptorContext.Provider value={cryptorWithKeys}>
          <ConversationsDataContainer />
        </CryptorContext.Provider>
      )}
    </CryptorUnlocker>
  )
}

const passwordForm: React.FC<PasswordProps> = function ({ onSubmitPassword }) {
  return (
    <PasswordForm
      onSubmit={onSubmitPassword}
      title={t('messaging.unlock')}
      lockReason={t('messaging.locked_reason')}
      unlockErrorMessage={t('messaging.unlock_error_incorrect_password')}
      descriptionHTML={t('messaging.unlock_description')}
    />
  )
}
