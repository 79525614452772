/* eslint-disable @typescript-eslint/no-use-before-define */
import React from 'react'
import styled from 'styled-components'

import { t } from 'i18n'

import completedFormImg from './CompletedFormImage.svg'

export default function CompletedForm() {
  return (
    <div className='o-container'>
      <div className='patient-forms__completed-message'>
        <h1 className='h1--default-margin'>{t('patient_forms.patient_form.completed_message')}</h1>
        <a href='/patient_forms' className='o-clickable'>
          {t('patient_forms.patient_form.navigate_back')}
        </a>
      </div>
      <Image alt='form completed' src={completedFormImg} />
    </div>
  )
}

const Image = styled.img`
  width: 168px;

  @media (min-width: 768px) {
    width: auto;
  }
`
