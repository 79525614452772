import { Dialog } from '@headlessui/react'
import React from 'react'

import PlainButton from 'components/shared/Buttons/PlainButton'
import PrimaryButton from 'components/shared/Buttons/PrimaryButton'
import SecondaryButton from 'components/shared/Buttons/SecondaryButton'

import { BaseButtonProps } from '../Buttons/BaseButton'

// types
export type ModalProps = {
  children?: React.ReactNode
  onClose: () => void
  open: boolean
}

// components
const Modal: React.FC<ModalProps> = ({ onClose, children, ...props }) => {
  return (
    <Dialog className='relative z-[101]' onClose={onClose} {...props}>
      <div
        data-testid='modal-backdrop'
        className='fixed inset-0 bg-grey-800/40'
        aria-hidden='true'
      />
      <div className='fixed inset-0 flex w-screen items-center justify-center p-4'>
        <Dialog.Panel>
          <div className='max-w-[584px] rounded-lg bg-white py-4'>{children}</div>
        </Dialog.Panel>
      </div>
    </Dialog>
  )
}

const Title: React.FC = ({ children }) => {
  return <h4 className='flex items-center px-4 text-h4 font-semibold'>{children}</h4>
}

const Body: React.FC = ({ children }) => {
  return <div className='mt-2 px-4 pb-4 text-base text-grey-800'>{children}</div>
}

const ButtonGroup: React.FC = ({ children }) => {
  return <div className='border-t border-grey-200 px-4 pt-4'>{children}</div>
}

const StyledPrimaryButton: React.FC<BaseButtonProps> = (props) => {
  return <PrimaryButton {...props} className='mt-2' />
}

const StyledSecondaryButton: React.FC<BaseButtonProps> = (props) => {
  return <SecondaryButton {...props} className='mt-2' />
}

const StyledPlainButton: React.FC<BaseButtonProps> = (props) => {
  return <PlainButton {...props} className='mt-2' />
}

export default Object.assign(Modal, {
  Title,
  Body,
  ButtonGroup,
  PrimaryButton: StyledPrimaryButton,
  SecondaryButton: StyledSecondaryButton,
  PlainButton: StyledPlainButton
})
