import React from 'react'

import { dateFormat } from 'i18n'

import { combineDateAndTime } from './combineDateAndTime'

interface Props {
  entryDate: string
  entryTime?: string
}

export const EntryDateTime: React.FC<Props> = ({ entryDate, entryTime }) => {
  const dateAndTimeCombined = combineDateAndTime(entryDate, entryTime)

  if (dateAndTimeCombined === undefined) {
    return null
  }

  return (
    <span>
      {dateFormat(dateAndTimeCombined, 'P')}
      {entryTime && ' ' + dateFormat(dateAndTimeCombined, 'time')}
    </span>
  )
}
