/* eslint-disable @typescript-eslint/no-use-before-define */
import { format, isValid, parseISO } from 'date-fns/fp'
import React from 'react'
import styled from 'styled-components'

import { InjectLinksToTextMessage } from 'components/shared/InjectLinksToTextMessage'

import { Message } from '../types'

import { FileAttachments } from './FileAttachments'
import { colors } from './styles'

// types
export type Variant = 'received' | 'sent'

type Styles = Partial<{
  variant: Variant
  fontWeight: string
  fontSize: string
  opacity: string
  alignSelf: string
}>

type MessageTextProps = Styles & {
  text: string
}

export type MessageCardProps = Styles & {
  message: Message
  variant: Variant
  className?: string
}

// components
export const MessageCard: React.FC<MessageCardProps> = function ({
  message,
  variant = 'sent',
  className
}) {
  const parsedDate = getDate(message.created_at)
  const dateAlignment = variant === 'sent' ? 'flex-end' : 'flex-start'

  return (
    <Container className={`${className} message-card`}>
      <InnerContainer variant={variant}>
        <TextContainer>
          {message.subject && (
            <MessageText
              text={message.subject}
              fontWeight='600'
              data-testid='message-card-subject'
            />
          )}
          {message.body && <HtmlBody body={message.body} />}
          <MessageText
            text={parsedDate}
            fontSize='14px'
            opacity='0.6'
            alignSelf={dateAlignment}
            data-testid='message-card-time'
          />
        </TextContainer>
        {Boolean(message.files?.length || message.attachments?.length) && (
          <FileAttachments
            messageID={message.id}
            attachments={message.attachments}
            files={message.files}
          />
        )}
      </InnerContainer>
    </Container>
  )
}

const MessageText: React.FC<MessageTextProps> = ({ text, ...styles }) => {
  if (!text) {
    return null
  }

  return <Content {...styles}>{text}</Content>
}

// styles
const getReceivedVariant = ({ variant }: Styles) => {
  if (variant === 'received') {
    return ''
  }

  return `
    align-self: flex-end;
    background-color: ${colors.grey2};
    border: 1px solid ${colors.grey6};
    margin-left: 32px;
    margin-right: 0;
  `
}

const Container = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
`

const InnerContainer = styled.div`
  display: flex;
  flex-direction: column;
  border-radius: 8px;
  border: 1px solid ${colors.grey2};
  background-color: ${colors.grey7};
  margin-right: 32px;
  align-self: flex-start;
  max-width: 75%;
  ${getReceivedVariant};
`

const TextContainer = styled.div`
  overflow-wrap: break-word;
  padding: 8px 16px;

  a {
    color: var(--mid-blue);
  }
`

const HtmlBodyInner: React.FC<{ className?: string; body: string }> = ({ className, body }) => (
  <div className={className} data-testid='message-card-text'>
    <InjectLinksToTextMessage message={body} />
  </div>
)

const HtmlBody = styled(HtmlBodyInner)`
  line-height: 24px;
  letter-spacing: 0.02em;
  color: ${colors.grey8};
  overflow-wrap: break-word;
`

const Content = styled.span`
  line-height: 24px;
  letter-spacing: 0.02em;
  color: ${colors.grey8};
  ${({ fontWeight = '400' }: Styles) => `font-weight: ${fontWeight}`};
  ${({ fontSize = '16px' }: Styles) => `font-size: ${fontSize}`};
  ${({ opacity = '1' }: Styles) => `opacity: ${opacity}`};
  ${({ alignSelf = 'flex-start' }: Styles) => `align-self: ${alignSelf}`};
`

// utils
const getDate = (input: string) => {
  const date = parseISO(input)

  if (!isValid(date)) {
    return ''
  }

  return `${format('HH:mm')(date)}`
}
