import { faMagnifyingGlass, faUserDoctor } from '@fortawesome/pro-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React from 'react'

import { t } from 'i18n'

import { Contact } from './Contact'
import { NoContacts } from './NoContacts'
import { Contact as IContact } from './types'

interface Props {
  contacts: IContact[]
}

export const RecentContacts: React.FC<Props> = ({ contacts }) => (
  <div data-testid='homescreen-recent-contacts'>
    <h2 className='text-h5'>{t('homescreen.recent_contacts.header')}</h2>
    <div className='mt-2 overflow-hidden rounded-lg bg-white shadow-sm'>
      {contacts.length > 0 ? (
        <>
          <ul>
            {contacts.map((c) => (
              <Contact key={c.id} contact={c} />
            ))}
          </ul>
          <div className='w-full border-b border-grey-200 p-4'>
            <a
              className='flex justify-center rounded-md bg-light-blue py-2.5 font-bold leading-5 text-primary-600 hover:bg-light-blue-darker active:bg-light-blue-darker active:opacity-30'
              href='/contact_practices'
              data-testid='homescreen-recent-contacts-all-contacts'
              title={t('homescreen.recent_contacts.see_all_contacts')}
            >
              <FontAwesomeIcon className='h-5 w-5 pr-2' icon={faUserDoctor} />
              {t('homescreen.recent_contacts.see_all_contacts')}
            </a>
          </div>
          <div className='w-full p-4 text-center text-grey-800'>
            {t('homescreen.recent_contacts.search.description')}
          </div>
          <a
            className='flex justify-center bg-primary-600 py-4 font-bold leading-5 text-white hover:bg-navy-blue active:opacity-30'
            href='/searches'
            data-testid='homescreen-recent-contacts-search-new'
            title={t('homescreen.recent_contacts.search.link')}
          >
            <FontAwesomeIcon className='h-5 w-6 pr-2' icon={faMagnifyingGlass} />
            {t('homescreen.recent_contacts.search.link')}
          </a>
        </>
      ) : (
        <NoContacts />
      )}
    </div>
  </div>
)
