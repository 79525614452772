import React from 'react'

import { CryptorContext } from 'components/features/Cryptor/CryptorContext'
import { CryptorWithKeys } from 'components/features/Cryptor/models/CryptorService'
import { DownloadAttachmentLink } from 'components/features/MedicalRecord/DownloadAttachmentLink'
import { downloadEncryptedAttachment } from 'components/features/MedicalRecord/downloadEncryptedAttachment'

import { MedicalRecordValuePlaceholder } from '../../MedicalRecordValuePlaceholder'
import { DecryptedMedicalRecordEntryShare } from '../MedicalRecordEntryShare'

interface Props {
  entry: DecryptedMedicalRecordEntryShare
  cryptorWithKeys: CryptorWithKeys
}

export const Description: React.FC<Props> = function ({ entry }) {
  const isFile: boolean = entry.target.type === 'file'

  if (entry.target.data_undecryptable) {
    return <MedicalRecordValuePlaceholder isFile={isFile} />
  }

  return (
    <span className='c-medical-record-entries__category-value'>
      {isFile ? (
        <CryptorContext.Consumer>
          {(cryptorWithKeys) => (
            <React.Fragment>
              {entry.target.description}
              {' - '}
              <DownloadAttachmentLink
                name={entry.target.attachment_filename as string}
                size={entry.target.attachment_size as number}
                // eslint-disable-next-line react/jsx-no-bind
                onDownloadClick={() =>
                  downloadEncryptedAttachment(cryptorWithKeys.cryptor, entry.target)
                }
              />
            </React.Fragment>
          )}
        </CryptorContext.Consumer>
      ) : (
        <span>{entry.target.description}</span>
      )}
    </span>
  )
}
