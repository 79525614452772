import { faChevronDown } from '@fortawesome/pro-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React from 'react'

interface Props {
  onClick: () => void
}

export const ExpandIcon: React.FC<Props> = ({ onClick }) => {
  return (
    <span
      onClick={onClick}
      className='o-clickable  c-medical-record-entries__icon c-medical-record-entries__expand-collapse-icon'
    >
      <FontAwesomeIcon icon={faChevronDown} />
    </span>
  )
}
