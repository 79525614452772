import { faArrowsRotate } from '@fortawesome/pro-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React from 'react'

interface Props {
  label?: string
}

export const Loading: React.FC<Props> = ({ label }) => {
  return (
    <div className='f-user-area-state-page f-user-area-state-page--loading'>
      <span className='s-loading-spinner is-active f-user-area-state-page__spinner'>
        <FontAwesomeIcon icon={faArrowsRotate} />
      </span>
      {label && <span className='f-user-area-state-page__label'>{label}</span>}
    </div>
  )
}
