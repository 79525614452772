import classNames from 'classnames'
import React, { useMemo } from 'react'
import { Column, useTable } from 'react-table'

import { t } from 'i18n'

import { DecryptedMessageSummaryAttributes } from '../../models/Message'

import { CellActions } from './CellActions'
import { CellFromTo } from './CellFromTo'
import { CellSubject } from './CellSubject'
import { CellTime } from './CellTime'

interface MessageTableProps {
  folder: 'inbox' | 'outbox'
  messages: DecryptedMessageSummaryAttributes[] | undefined
}

export const MessageTable: React.FC<MessageTableProps> = function ({ folder, messages }) {
  const columns: Array<Column<DecryptedMessageSummaryAttributes>> = useMemo(
    () => [
      {
        id: 'time',
        Cell: CellTime,
        accessor: (message: DecryptedMessageSummaryAttributes): string => message.created_at,
        className: 'f-messages__time-cell'
      },
      {
        id: 'from',
        Cell: CellFromTo,
        accessor: (message: DecryptedMessageSummaryAttributes): string =>
          folder === 'inbox' ? message.sender_name : message.receiving_practice_name
      },
      {
        id: 'subject',
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        Cell: (props: any) => <CellSubject folder={folder} {...props} />,
        accessor: (message: DecryptedMessageSummaryAttributes) => {
          if (message.decrypted_subject.decryptionSucceded) {
            return message.decrypted_subject.value || t('messages.no_subject')
          }

          return t('messages.decryption_error.subject')
        }
      },
      {
        id: 'actions',
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        Cell: (props: any) => <CellActions folder={folder} {...props} />
      }
    ],
    [folder]
  )

  const { rows, prepareRow } = useTable({ columns, data: messages || [] })

  rows.forEach((row) => prepareRow(row))

  return (
    <div className='f-messages'>
      {rows.map((row) => {
        const rowClasses = classNames('f-messages__row', {
          'f-messages__row--unread': !row.original.is_read
        })
        return (
          // row.getRowProps sets keys
          // eslint-disable-next-line react/jsx-key
          <div className={rowClasses} {...row.getRowProps()}>
            {row.cells.map((cell) => cell.render('Cell', { key: cell.getCellProps().key }))}
          </div>
        )
      })}
    </div>
  )
}
