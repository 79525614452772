/* eslint-disable react/destructuring-assignment */
/* eslint-disable @typescript-eslint/no-use-before-define */
import { faChevronLeft, faArrowDown } from '@fortawesome/pro-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { format } from 'date-fns'
import { isSameDay, isValid, parseISO } from 'date-fns/fp'
import { sortBy } from 'lodash'
import { compose } from 'lodash/fp'
import React from 'react'
import styled from 'styled-components'

import { t } from 'i18n'

import { Message } from '../types'

import { Chip } from './Chip'
import { ConversationInput } from './ConversationInput'
import { MessageCard } from './MessageCard'
import { NoMessages } from './NoMessages'
import { SectionList, SectionListRenderItem } from './SectionList'
import { colors, HorizontalRule } from './styles'

// types
type ContainerProps = {
  isActive: boolean
}

type MessageGroup = {
  date: string
  messages: Message[]
}

type RenderItem = (
  numberOfUnreadMessages?: number,
  firstUnreadMessageId?: string
) => SectionListRenderItem<Message>

export type ConversationColumnProps = {
  messages?: Message[]
  contactName?: string | null
  handleSubmitMessage: (message: string) => void
  onReturnButtonClick: () => void
  isActive: boolean
  unreadMessagesCount?: number
  firstUnreadMessageId?: string
}

// components
export const ConversationColumn: React.FC<ConversationColumnProps> = ({
  messages = [],
  contactName,
  unreadMessagesCount,
  firstUnreadMessageId,
  isActive,
  handleSubmitMessage,
  onReturnButtonClick
}) => {
  const filteredMessages = messages.filter((m) => compose(isValid, parseISO)(m.created_at))
  const groups = groupMessagesBySameDay(filteredMessages)
  const sortedGroups = sortMessagesAndGroups(groups)
  const sections = getSections(sortedGroups)

  return (
    <Container isActive={isActive} className='conversation-column'>
      <TitleContainer>
        <ReturnButton onClick={onReturnButtonClick}>
          <FontAwesomeIcon icon={faChevronLeft} />
        </ReturnButton>
        {contactName && <Title data-testid='contact_chat_title'>{contactName}</Title>}
      </TitleContainer>
      <SectionList
        sections={sections}
        ListEmptyComponent={<NoMessages from={contactName} />}
        renderItem={renderItem(unreadMessagesCount, firstUnreadMessageId)}
      />
      <HorizontalRule borderWidth='2' />
      <StyledConversationInput onSubmit={handleSubmitMessage} deletedContact={!contactName} />
    </Container>
  )
}

// styles
const Container = styled.div<ContainerProps>`
  flex: 1;
  display: flex;
  flex-direction: column;

  // only on small screen, we hide the column if it's not active
  ${({ isActive }) => (isActive ? '' : 'display: none')};

  @media (min-width: 1024px) {
    display: flex;
  }
`

const TitleContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  border-bottom: 2px solid ${colors.grey2};
  position: relative;
`

const Title = styled.h5`
  padding: 12px;
  text-align: center;
`

const ReturnButton = styled.div`
  position: absolute;
  left: 0;
  cursor: pointer;
  padding: 12px;

  svg:hover {
    color: ${colors.blue3};
  }
  @media (min-width: 1024px) {
    display: none;
  }
`

const StyledMessageCard = styled(MessageCard)`
  margin-top: 12px;
`

const StyledConversationInput = styled(ConversationInput)`
  padding: 12px;
`

const ChipContainer = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 16px;
`

// utils
// eslint-disable-next-line react/display-name
const renderItem: RenderItem = (unreadMessagesCount, firstUnreadMessageId) => (message) => {
  const unreadMessageText =
    unreadMessagesCount === 1
      ? t('conversation.unread-message')
      : t('conversation.unread-messages', String(unreadMessagesCount))
  const isFirstUnreadMessage = message.id === firstUnreadMessageId

  return (
    <div key={message.id}>
      {isFirstUnreadMessage && (
        <ChipContainer>
          <Chip
            text={unreadMessageText}
            textTestId='chat-unread-messages-counter'
            icon={faArrowDown}
          />
        </ChipContainer>
      )}
      <StyledMessageCard
        key={message.id}
        message={message}
        variant={message.is_received ? 'received' : 'sent'}
      />
    </div>
  )
}

const isMessageInGroup = (msg: Message, messageGroup: MessageGroup) => {
  const date1 = parseISO(msg.created_at)
  const date2 = parseISO(messageGroup.date)
  return isSameDay(date1)(date2)
}

const groupMessagesBySameDay = (messages: Message[]) =>
  messages.reduce<MessageGroup[]>((messageGroups, msg) => {
    const sameDayGroup = messageGroups.find((messageGroup) => isMessageInGroup(msg, messageGroup))
    if (sameDayGroup) {
      sameDayGroup.messages.push(msg)
      return messageGroups
    }

    return [...messageGroups, { date: msg.created_at, messages: [msg] }]
  }, [])

const sortMessagesAndGroups = (messageGroups: MessageGroup[]): MessageGroup[] => {
  // we sort groups in reverse order because they are displayed in SectionListContainer
  // with flex-direction: column-reverse
  const sortedGroups = sortBy(messageGroups, (messageGroup) => -parseISO(messageGroup.date))

  return sortedGroups.map((messageGroup) => ({
    date: messageGroup.date,
    messages: sortBy(messageGroup.messages, (message) => parseISO(message.created_at))
  }))
}

const getSections = (messageGroup: MessageGroup[]) =>
  messageGroup.map(({ date, messages }) => ({
    title: format(parseISO(date), 'iiii d. LLLL yyyy'),
    data: messages
  }))
