import React from 'react'

import { AttachmentAttributes } from '../../models/Attachment'
import { FileAttributes } from '../../models/File'

import { Attachment } from './Attachment'
import { EncryptedFile } from './EncryptedFile'

export const AttachmentsLine: React.FC<{
  messageID: string
  attachments: AttachmentAttributes[]
  files: FileAttributes[]
}> = function ({ messageID, attachments = [], files = [] }) {
  if (attachments.length === 0 && files.length === 0) {
    return null
  }

  return (
    <div className='f-read-message__attachments'>
      {attachments.map((attachment, i) => (
        <span key={i}>
          {i > 0 && ', '}
          <Attachment messageID={messageID} attachment={attachment} />
        </span>
      ))}
      {files.map((file, i) => (
        <span key={i}>
          {i > 0 && ', '}
          <EncryptedFile messageID={messageID} file={file} />
        </span>
      ))}
    </div>
  )
}
