import { format, isThisWeek, isToday } from 'date-fns'
import { isValid, parseISO } from 'date-fns/fp'

// utils
export const getDate = (input: string) => {
  const date = parseISO(input)

  if (!isValid(date)) {
    return ''
  }

  if (isToday(date)) {
    return `${format(date, 'HH:mm')}`
  }

  if (isThisWeek(date)) {
    return `${format(date, 'E')}.`
  }

  return `${format(date, 'dd.MM.yyyy')}`
}
