import { faClipboardList, faAngleRight } from '@fortawesome/pro-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React from 'react'

import { dateFormat, t } from 'i18n'

import { Form } from './types'

interface Props {
  forms: Form[]
}

export const FormsToFill: React.FC<Props> = ({ forms }) => (
  <div data-testid='homescreen-forms'>
    <h2 className='text-h5'>{t('homescreen.forms.header')}</h2>
    <div className='mt-2 overflow-hidden rounded-lg bg-white shadow-sm'>
      {forms.map(({ id, practiceName, formTitle, formUrl, sentAt }) => (
        <a
          className='group flex self-center border-b border-b-grey-200 px-4 py-2 hover:bg-light-blue hover:text-primary-600 active:bg-primary-600 active:text-white'
          data-testid={`homescreen-forms-navigate-form-${id}`}
          href={formUrl}
          key={['form', id].join('$')}
          title={t('homescreen.forms.a11y.form_link', formTitle, practiceName)}
        >
          <div className='mr-2 flex h-7 items-center justify-center rounded-full bg-light-blue'>
            <div className='h-7 w-7 text-center'>
              <FontAwesomeIcon className='h-3 w-3 text-dark' icon={faClipboardList} />
            </div>
          </div>
          <div className='flex w-full flex-col pr-2'>
            <div
              className='text-h5 font-semibold'
              data-testid={`homescreen-forms-form-${id}-title`}
            >
              {formTitle}
            </div>
            <div
              className='grow font-semibold text-grey-800 group-active:text-white'
              data-testid={`homescreen-forms-form-${id}-practice`}
            >
              {practiceName}
            </div>
            <div
              className='font-label text-grey-700 group-active:text-white'
              data-testid={`homescreen-forms-form-${id}-received-at`}
            >
              {t('homescreen.forms.received_at', dateFormat(sentAt, 'dateTimeLong') as String)}
            </div>
          </div>
          <div className='self-center'>
            <FontAwesomeIcon
              className='h-6 w-6 text-dark group-active:text-white'
              icon={faAngleRight}
            />
          </div>
        </a>
      ))}
      <a
        className='flex justify-center py-4 font-bold leading-5 text-primary-600 hover:bg-light-blue hover:text-primary-600 active:bg-primary-600 active:text-white'
        href='/patient_forms'
        data-testid='homescreen-forms-navigate-all'
        title={t('homescreen.forms.see_all')}
      >
        <FontAwesomeIcon className='h-5 w-6 pr-2' icon={faClipboardList} />
        {t('homescreen.forms.see_all')}
      </a>
    </div>
  </div>
)
