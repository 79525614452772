import {
  faAngleRight,
  faCalendarDay,
  faClipboardList,
  faVideo
} from '@fortawesome/pro-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import cx from 'classnames'
import React from 'react'

import { dateFormat, t } from 'i18n'

import { locationAddress, locationMapsQuery } from './helpers'
import { NextAppointment as INextAppointment } from './types'

interface Props {
  appointment: INextAppointment
}

export const NextAppointment: React.FC<Props> = ({ appointment }) => (
  <div
    className='mt-2 overflow-hidden rounded-md bg-white shadow-sm'
    data-testid='homescreen-upcoming-appointment'
  >
    <div className='border-b border-b-grey-200 p-4'>
      <div className='flex w-full'>
        <div
          className='grow text-h2 text-grey-800'
          data-testid='homescreen-upcoming-appointment-datetime-line-1'
        >
          {dateFormat(appointment.startsAt, 'EEEE')}
        </div>
        {appointment.videoConsultationLink && (
          <div className='ml-2'>
            <a
              className='flex h-7 w-7 items-center justify-center rounded-full bg-light-blue text-center hover:bg-light-blue-darker active:opacity-30'
              data-testid='homescreen-upcoming-appointment-video-consultation'
              href={appointment.videoConsultationLink}
            >
              <FontAwesomeIcon className='h-3 w-3' icon={faVideo} />
            </a>
          </div>
        )}
      </div>
      <div
        className='text-hero-big font-semibold text-grey-900'
        data-testid='homescreen-upcoming-appointment-datetime-line-2'
      >
        {dateFormat(appointment.startsAt, 'dateLong')}
      </div>
      <div className='mt-2 flex'>
        <div
          className='whitespace-nowrap text-h5 font-semibold text-grey-800'
          data-testid='homescreen-upcoming-appointment-datetime-line-3'
        >
          {dateFormat(appointment.startsAt, 'time')}
        </div>
        <div
          className='ml-2 text-h5 text-grey-800'
          data-testid='homescreen-upcoming-appointment-appointment-name'
        >
          {appointment.appointmentName}
        </div>
      </div>
    </div>
    <div className='px-2 pt-5'>
      <h3
        className='mb-2 px-2 text-h2 font-normal text-grey-800'
        data-testid='homescreen-upcoming-appointment-practice-name'
      >
        {appointment.location.name}
      </h3>
      <div className='mt-0 flex w-full rounded-lg bg-light-blue p-2'>
        <div className='flex w-full flex-row'>
          <div
            className='grow whitespace-pre text-grey-900'
            data-testid='homescreen-upcoming-appointment-practice-address'
          >
            {locationAddress(appointment)}
          </div>
          <a
            className='self-center font-bold text-primary-600 hover:text-secondary-500 active:text-navy-blue'
            href={`https://maps.google.com/?q=${locationMapsQuery(appointment)}`}
            target='_blank'
            rel='noreferrer'
          >
            {t('homescreen.upcoming_appointment.map_card')}
          </a>
        </div>
      </div>
    </div>
    {appointment.forms.length > 0 && (
      <div className='px-2 pt-5'>
        <h3 className='mb-2 px-2 text-h5 font-semibold text-grey-800'>
          {t('homescreen.upcoming_appointment.forms')}
        </h3>
        <div className='mt-0 flex w-full overflow-hidden rounded-lg'>
          <ul className='w-full' data-testid='homescreen-upcoming-appointment-forms'>
            {appointment.forms.map((form, i) => (
              <li
                key={form.id}
                className='flex w-full'
                data-testid={`homescreen-upcoming-appointment-form-${form.id}`}
              >
                <a
                  className={cx(
                    'flex w-full bg-light-blue p-2 hover:bg-light-blue-darker active:opacity-30',
                    { 'border-t': i > 0, 'border-t-grey-300': i > 0 }
                  )}
                  href={form.formPath}
                >
                  <div className='mr-2 flex h-7 items-center justify-center rounded-full bg-white'>
                    <div className='h-7 w-7 text-center'>
                      <FontAwesomeIcon className='h-3 w-3 text-dark' icon={faClipboardList} />
                    </div>
                  </div>
                  <div className='grow pt-0.5 font-semibold text-dark'>{form.name}</div>
                  <div className='ml-2 self-center'>
                    <FontAwesomeIcon
                      className='h-6 w-6 align-bottom text-dark group-active:text-white'
                      icon={faAngleRight}
                    />
                  </div>
                </a>
              </li>
            ))}
          </ul>
        </div>
      </div>
    )}
    <a
      className='mt-4 flex justify-center border-t border-grey-200 py-4 font-bold leading-5 text-primary-600 hover:bg-light-blue hover:text-primary-600 active:bg-primary-600 active:text-white'
      data-testid='homescreen-upcoming-appointment-navigate-all'
      href='/appointments/upcoming'
    >
      <FontAwesomeIcon className='h-5 w-6 pr-2' icon={faCalendarDay} />
      {t('homescreen.upcoming_appointment.all_appointments_link')}
    </a>
  </div>
)
