/* eslint-disable @typescript-eslint/no-use-before-define */
import React from 'react'
import styled from 'styled-components'

import { colors, HorizontalRule } from './styles'

export type SectionT<T extends unknown> = {
  title: string
  data: T[]
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export type SectionListRenderItem<T extends any> = (item: T) => React.ReactElement | null

export type SectionListProps<T extends unknown> = {
  renderItem: SectionListRenderItem<T>
  sections: SectionT<T>[]
  className?: string
  ListEmptyComponent?: React.ComponentType | React.ReactElement | null
}

export const SectionList = <T extends unknown>({
  sections,
  renderItem,
  ListEmptyComponent,
  className
}: SectionListProps<T>): React.ReactElement => {
  const isShowListEmpty = sections.length === 0 && ListEmptyComponent

  return (
    <SectionListContainer className={className}>
      {isShowListEmpty && <ListEmptyContainer>{ListEmptyComponent}</ListEmptyContainer>}
      {sections.map(({ title, data }) => (
        <Section key={title}>
          <TitleContainer>
            <HorizontalRule flex='1' />
            <Title data-testid='chat-date'>{title}</Title>
            <HorizontalRule flex='1' />
          </TitleContainer>
          {data.map(renderItem)}
        </Section>
      ))}
    </SectionListContainer>
  )
}

// styles
const SectionListContainer = styled.div`
  flex: 1;
  overflow: scroll;
  display: flex;
  flex-direction: column-reverse;
`

const Section = styled.div`
  margin: 16px;
`

const TitleContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`

const Title = styled.span`
  font-weight: 600;
  line-height: 20px;
  color: ${colors.grey8};
  padding: 0 16px;
`

const ListEmptyContainer = styled.div`
  flex: 1;
`
