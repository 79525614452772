import { FormikErrors } from 'formik'

import { t } from 'i18n'

export interface MessageFormFields {
  subject: string
  body_text: string
  practiceId?: string
}

export const validateFields = (values: MessageFormFields): FormikErrors<MessageFormFields> => {
  const errors: FormikErrors<MessageFormFields> = {}
  const isEmpty = (str: string): boolean => (str.match(/^\s*$/) ? true : false)
  if (isEmpty(values.subject)) {
    errors.subject = `${t('activerecord.attributes.message.subject')} ${t(
      'activerecord.errors.messages.empty'
    )}`
  }
  if (isEmpty(values.body_text)) {
    errors.body_text = `${t('activerecord.attributes.message.body')} ${t(
      'activerecord.errors.messages.empty'
    )}`
  }
  return errors
}
