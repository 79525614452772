/* eslint-disable @typescript-eslint/no-use-before-define */
import { useQuery } from '@tanstack/react-query'
import { isBefore } from 'date-fns'
import React, { useContext } from 'react'

import { CryptorContext } from 'components/features/Cryptor/CryptorContext'

import { Loading } from '../components/Loading'
import { ContactWithMessage } from '../types'
import { fetchContacts } from '../utils/fetchBaseMessages'

import { ConversationsStateContainer } from './ConversationsStateContainer'

export const ConversationsDataContainer: React.FC = () => {
  const cryptorWithKeys = useContext(CryptorContext)
  const { data: contacts } = useQuery(['contacts'], fetchContacts(cryptorWithKeys))

  if (!contacts) {
    return <Loading />
  }

  return <ConversationsStateContainer contacts={contacts.concat().sort(compareFn)} />
}

// utils
const compareFn = (a: ContactWithMessage, b: ContactWithMessage) => {
  const latestMessageDateA = a.latest_message?.created_at
  const latestMessageDateB = b.latest_message?.created_at

  if (!latestMessageDateA && !latestMessageDateB) {
    return 0
  }
  if (!latestMessageDateA) {
    return 1
  }
  if (!latestMessageDateB) {
    return -1
  }
  const dateA = new Date(latestMessageDateA)
  const dateB = new Date(latestMessageDateB)

  return isBefore(dateA, dateB) ? 1 : -1
}
