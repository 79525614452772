import axios from 'axios'

import PatientForm, { BackendAttributes } from '../models/PatientForm'

export default async function fetchAllPatientForms(): Promise<PatientForm[]> {
  const result = await axios.get(`/patient_forms.json`)

  return result.data.map((attributes: BackendAttributes) => {
    const {
      id,
      completed_at,
      form_data,
      form_id,
      form_title,
      form_type_id,
      practice_id,
      practice_name,
      sent_at,
      draft_saved_at,
      session_key,
      attachments_encryption_key_id,
      has_print_template,
      appointment_id,
      appointment_name,
      appointment_starts_at
    } = attributes

    return new PatientForm({
      id,
      completedAt: completed_at,
      formData: form_data,
      formId: form_id,
      title: form_title,
      formTypeId: form_type_id,
      practiceId: practice_id,
      practiceName: practice_name,
      sentAt: sent_at,
      draftSavedAt: draft_saved_at,
      sessionKey: session_key,
      attachmentsEncryptionKeyId: attachments_encryption_key_id,
      hasPrintTemplate: has_print_template,
      appointmentId: appointment_id,
      appointmentName: appointment_name,
      appointmentStartsAt: appointment_starts_at
    })
  })
}
