/* eslint-disable @typescript-eslint/no-use-before-define */
import { compose } from 'lodash/fp'
import { useEffect, useState } from 'react'

// types
export type useViewportProps = {
  isMobile: boolean
  isTablet: boolean
  isDesktop: boolean
}

// hooks
const useViewport = () => {
  const [width, setWidth] = useState(window.innerWidth)
  const [tabletMinWidth, setTabletMinWidth] = useState(NaN)
  const [desktopMinWidth, setDesktopMinWidth] = useState(NaN)

  useEffect(() => {
    compose(setTabletMinWidth, Number, getCssVariable)('--tablet-min-width')
    compose(setDesktopMinWidth, Number, getCssVariable)('--desktop-min-width')

    const handleWindowSizeChange = () => {
      setWidth(window.innerWidth)
    }

    window.addEventListener('resize', handleWindowSizeChange)
    return () => {
      window.removeEventListener('resize', handleWindowSizeChange)
    }
  }, [])

  const isMobile = width < tabletMinWidth
  const isTablet = width >= tabletMinWidth && width < desktopMinWidth
  const isDesktop = width >= desktopMinWidth

  return { isMobile, isTablet, isDesktop }
}

// utils
const getCssVariable = (cssVariableName: string) => {
  const computedStyle = getComputedStyle(document.documentElement)
  const cssVariable = computedStyle.getPropertyValue(cssVariableName)

  return cssVariable
}

export default useViewport
