import { Cryptor } from '@samedi/crypto-js'
import {
  loadSessionKey,
  generateSessionKey,
  decrypt,
  encrypt
} from '@samedi/crypto-js/subtle/symmetric'

import { str2ab, bytesToUtf8String, utf8StringToBytes } from '@samedi/crypto-js/utils'
import axios from 'axios'
import { toByteArray as decode64, fromByteArray as encode64 } from 'base64-js'

import { shareKeyWithPractice } from 'services/encryption'

export interface MedicalRecordEntryType {
  id: number
  category_name: string
  grouping?: string
  regex?: string
  unit?: string
}

interface MedicalRecordEntryBase {
  id: string | number
  created_at: string
  created_by_doctor: boolean
  creator_field: string[]
  creator_institution_type?: string

  creator_practice: string
  creator_name: string
  encrypted_data: string
  entry_date: string
  entry_time?: string
  ais_creator?: string
  ais_field?: string
  permanent: boolean
  sent_to_institutions: InstitutionBasicData[]
  creator_institution?: InstitutionBasicData
  events: string[]

  // encrypted_data and encrypted_attachment_data are encrypted with this key
  // the key itself is encrypted with the patient user's public key
  session_key?: string

  // Set only when data is not decryptable
  data_undecryptable?: boolean | undefined

  // some entries may have their files stored on minio
  file_id?: string | null
  // encryption key id for aboves file decryption
  encryption_key_id?: string | null
}

export interface MedicalRecordTextEntry extends MedicalRecordEntryBase {
  type: 'text'
  encrypted_attachment_data: undefined
  entry_type: MedicalRecordEntryType
}

export interface MedicalRecordFileEntry extends MedicalRecordEntryBase {
  type: 'file'
  encrypted_attachment_data: string
  entry_type: undefined
  file_id?: string
  file_encrypted_meta_data?: string
  file_encrypted_name?: string
}
export interface MedicalRecordICD10Entry extends MedicalRecordEntryBase {
  type: 'icd10'
  encrypted_attachment_data: undefined
  entry_type: MedicalRecordEntryType
}

export type MedicalRecordEntry =
  | MedicalRecordTextEntry
  | MedicalRecordFileEntry
  | MedicalRecordICD10Entry

export type DecryptedMedicalRecordEntry = MedicalRecordEntry & {
  category?: string
  attachment_filename?: string
  attachment_size?: number
  attachment_data?: string
  description?: string
  unit?: string
  category_name?: string
}

export interface PublicKey {
  id: number
  public_exponent: string
  modulus: string
}

export interface InstitutionBasicData {
  id: string
  name: string
}

export interface NewMedicalRecordEntry {
  type: 'file' | 'text'
  description: string
  permanent?: boolean
  entry_date: string
  entry_time?: string
  attachment_filename?: string
  attachment_size?: number
  attachment_data?: string
}

interface MedicalRecordEntryExpansionRow {
  id: string
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  Cell: (row: any) => JSX.Element
  minWidth?: number
  className?: string
  borderLeft?: boolean
  borderBottom?: boolean
}

interface MedicalRecordEntryWithHeader {
  Header: string
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  accessor: (entry: any) => string | JSX.Element | undefined
  id?: string
  minWidth?: number
  className?: string
  borderLeft?: boolean
  borderBottom?: boolean
}

interface MedicalRecordEntryWithoutHeader {
  id: string
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  accessor: (entry: any) => string | JSX.Element | undefined
  Header?: string
  minWidth?: number
  className?: string
  borderLeft?: boolean
  borderBottom?: boolean
}

export type MedicalRecordEntryColumns =
  | MedicalRecordEntryExpansionRow
  | MedicalRecordEntryWithHeader
  | MedicalRecordEntryWithoutHeader

export async function decryptMedicalRecordEntry(
  entry: MedicalRecordEntry,
  cryptor: Cryptor
): Promise<DecryptedMedicalRecordEntry> {
  const result: DecryptedMedicalRecordEntry = entry

  try {
    if (entry.encrypted_data) {
      if (entry.session_key) {
        const decryptedData = entry.session_key
          ? await decryptData(entry.encrypted_data, entry.session_key, cryptor) // eslint-disable-line @typescript-eslint/no-use-before-define
          : await cryptor.decrypt(entry.encrypted_data)
        const parsedData = JSON.parse(decryptedData)
        Object.assign(result, parsedData)
      }
    }
    if (entry.type === 'file' && entry.file_encrypted_name) {
      const decryptedFileName = await cryptor.decrypt(entry.file_encrypted_name)
      result.attachment_filename = decryptedFileName
    }
    if (entry.type === 'file' && entry.file_encrypted_meta_data) {
      const decryptedFileMetadata = await cryptor.decrypt(entry.file_encrypted_meta_data)
      const parsedMetadata = JSON.parse(decryptedFileMetadata)
      result.attachment_filename = parsedMetadata['file_name']
      result.attachment_size = parsedMetadata['file_size']
    }
  } catch (error) {
    console.error(`decryption error: ${error}`)
    result.data_undecryptable = true
  }
  return result
}

export async function decryptAttachment(
  encryptedAttachmentData: string,
  encryptedSessionKey: string | null = null,
  cryptor: Cryptor
): Promise<Blob> {
  const blobType = 'application/octet-stream'
  let decryptedAttachmentData: Uint8Array = new Uint8Array()
  let decodedData: Uint8Array = new Uint8Array()

  if (encryptedAttachmentData && encryptedAttachmentData.length > 0) {
    if (encryptedSessionKey) {
      // eslint-disable-next-line @typescript-eslint/no-use-before-define
      const decryptedSessionKey = await decryptSessionKey(encryptedSessionKey, cryptor)
      const sessionKey = await loadSessionKey(decryptedSessionKey)
      decryptedAttachmentData = await decrypt(decode64(encryptedAttachmentData), sessionKey)
    } else {
      decryptedAttachmentData = await cryptor.decryptToBuffer(encryptedAttachmentData)
    }

    decodedData = decode64(bytesToUtf8String(decryptedAttachmentData))
  }

  return new Blob([decodedData], { type: blobType })
}

async function decryptData(
  encryptedData: string,
  encryptedSessionKey: string,
  cryptor: Cryptor
): Promise<string> {
  // eslint-disable-next-line @typescript-eslint/no-use-before-define
  const decryptedSessionKey = await decryptSessionKey(encryptedSessionKey, cryptor)
  const sessionKey = await loadSessionKey(decryptedSessionKey)

  return bytesToUtf8String(await decrypt(decode64(encryptedData), sessionKey))
}

async function decryptSessionKey(sessionKey: string, cryptor: Cryptor): Promise<Uint8Array> {
  const decryptedSessionKey = await cryptor.decryptToBuffer(sessionKey)

  // SI-7180, we have such a weird utf-8 encoded key
  // decode bytes as utf-8
  // drop the high bytes (i think)
  if (decryptedSessionKey.byteLength > 32) {
    const decoder = new TextDecoder()
    const string = decoder.decode(decryptedSessionKey)
    return str2ab(string)
  }
  return decryptedSessionKey
}

async function encryptSessionKey(
  sessionKey: Uint8Array,
  keyId: string,
  cryptor: Cryptor
): Promise<string> {
  return cryptor.symmetricEncryptBuffer(sessionKey, keyId)
}

export async function encryptMedicalRecordEntry(
  entry: NewMedicalRecordEntry,
  keyId: string,
  cryptor: Cryptor
): Promise<{ sessionKey: string; encryptedData: string; encryptedAttachmentData?: string }> {
  const sessionKeyBytes = generateSessionKey()
  const sessionKey = await loadSessionKey(sessionKeyBytes)

  const encryptedSessionKey = await encryptSessionKey(sessionKeyBytes, keyId, cryptor)
  // eslint-disable-next-line @typescript-eslint/no-use-before-define
  const encryptedData = await encrypt(utf8StringToBytes(serializeForEncryption(entry)), sessionKey)
  let encryptedAttachmentData: Uint8Array = new Uint8Array()

  if (entry.attachment_data) {
    encryptedAttachmentData = await encrypt(str2ab(entry.attachment_data), sessionKey)
  }

  return {
    sessionKey: encryptedSessionKey,
    encryptedData: encode64(encryptedData),
    encryptedAttachmentData: encode64(encryptedAttachmentData)
  }
}

export async function sendToInstitution(
  cryptor: Cryptor,
  entry: DecryptedMedicalRecordEntry,
  contactPracticeId: number | string,
  institutionPublicKey: PublicKey
) {
  if (!entry.session_key) {
    throw 'trying to send an entry, that does not have a session_key'
  }

  if (entry.file_id && entry.encryption_key_id) {
    const practice = await axios.get(`/medical_record/contact_practices/${contactPracticeId}.json`)

    await shareKeyWithPractice(entry.encryption_key_id, practice.data.practice_id, cryptor)
  }

  const presentSessionKey = entry.session_key as string
  const sessionKeyBytes = await decryptSessionKey(presentSessionKey, cryptor)

  const encryptedSessionKeyForInstitution = await cryptor.asymmetricEncryptBuffer(
    sessionKeyBytes,
    institutionPublicKey.id.toString(),
    institutionPublicKey.public_exponent,
    institutionPublicKey.modulus
  )

  return axios.post('/api/medical_record/v1/entry_shares', {
    contact_practice_id: contactPracticeId,
    source_entry_id: entry.id,
    entry_share: {
      session_key: encryptedSessionKeyForInstitution
    }
  })
}

function serializeForEncryption(entry: NewMedicalRecordEntry): string {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const data: { [key: string]: any } = {}
  const serializedFields: (keyof NewMedicalRecordEntry)[] = [
    'description',
    'attachment_filename',
    'attachment_size'
  ]
  for (const field of serializedFields) {
    data[field] = entry[field]
  }
  return JSON.stringify(data)
}

export async function deleteEntry(
  entryId: string | number
): Promise<{ data: { success: boolean } }> {
  return axios.delete(`/api/medical_record/v1/entries/${entryId}`)
}
