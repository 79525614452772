import React from 'react'
import ReactDOM from 'react-dom'

import { ReactDashboard } from 'components/features/ReactDashboard'

const container = document.getElementById('react_dashboard')

if (!container) {
  throw 'Container for react content is not found'
}

document.addEventListener('DOMContentLoaded', () => {
  ReactDOM.render(<ReactDashboard />, container)
})
