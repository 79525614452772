/**
 * Formats a size in bytes into a human-readable size (kb, mb, gb, etc.)
 *
 * @param bytes size in bytes
 */
export function humanFileSize(bytes: number): string {
  if (Math.abs(bytes) < 1024) return bytes + ' B'

  const units = ['kB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']
  let u = -1

  while (true) {
    bytes /= 1024
    ++u
    if (!(Math.abs(bytes) >= 1024 && u < units.length - 1)) break
  }

  return bytes.toFixed(1) + ' ' + units[u]
}
