import { faMagnifyingGlass } from '@fortawesome/pro-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React from 'react'

import { t } from 'i18n'

interface Props {
  searchQuery: string
  setSearchQuery: (value: string) => void
}

export const SearchField: React.FC<Props> = function ({ searchQuery, setSearchQuery }) {
  return (
    <div className='h-margin-bottom--40'>
      <span className='c-medical-record-entries__search-field-icon'>
        <FontAwesomeIcon icon={faMagnifyingGlass} />
      </span>
      <input
        type='text'
        value={searchQuery}
        className='c-medical-record-entries__search-field'
        // eslint-disable-next-line react/jsx-no-bind
        onChange={(e) => setSearchQuery(e.target.value)}
        placeholder={t('MEDICAL_RECORD/PLACEHOLDER_SEARCHING')}
      />
    </div>
  )
}
