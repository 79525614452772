import React from 'react'

import { t } from 'i18n'

interface Props {
  isFile: boolean
}

export const MedicalRecordValuePlaceholder: React.FC<Props> = function ({ isFile }) {
  return (
    <span className='c-medical-record-entries__category-value'>
      {isFile ? (
        <span>{t('MEDICAL_RECORD/FILE_ENTRY/DESCRIPTION_PLACEHOLDER')}</span>
      ) : (
        <span>{t('MEDICAL_RECORD/TEXT_ENTRY/DESCRIPTION_PLACEHOLDER')}</span>
      )}
    </span>
  )
}
