import React, { useState, useEffect, useContext, useMemo, useCallback } from 'react'

import { CryptorContext } from 'components/features/Cryptor/CryptorContext'

import { CryptorWithKeys } from 'components/features/Cryptor/models/CryptorService'

import { Table } from 'components/shared/Tables/Table'

import { t } from 'i18n'

import { EntryDateTime } from '../EntryDateTime'
import { DecryptedMedicalRecordEntry, MedicalRecordEntryColumns } from '../MedicalRecordEntry'

import { CategoryName } from './CategoryName'
import { DeleteIcon } from './DeleteIcon'
import { Description } from './Description'
import { EntryRowMobile } from './EntryRowMobile'
import { ExpandIcon } from './ExpandIcon'
import { ExpandedRow } from './ExpandedRow'
import { SearchField } from './SearchField'
import { SentToInstitutionsList } from './SentToInstitutionsList'
import { ShareIcon } from './ShareIcon'

export interface Props {
  entries: DecryptedMedicalRecordEntry[]
  onSendEntry: (entry: DecryptedMedicalRecordEntry) => void
  onDeleteEntry: (entryId: string | number) => void
}

export function EntriesTable({ entries, onSendEntry, onDeleteEntry }: Props) {
  const [searchQuery, setSearchQuery] = useState('')
  const [filteredEntries, setFilteredEntries] = useState(entries)
  const cryptorWithKeys = useContext(CryptorContext)

  useEffect(() => {
    if (searchQuery === '') {
      setFilteredEntries(entries)
      return
    }
    setFilteredEntries(
      entries.filter((entry) => {
        return [entry.description, entry.attachment_filename].find(
          (value) => value && value.toLowerCase().startsWith(searchQuery.toLowerCase())
        )
      })
    )
  }, [entries, searchQuery])

  const columns: MedicalRecordEntryColumns[] = useMemo(
    () => [
      {
        Header: t('MEDICAL_RECORD/TYPE'),
        accessor: (entry: DecryptedMedicalRecordEntry) => (
          <CategoryName entry={entry} isClickable />
        ),
        minWidth: 150,
        className: 'c-medical-record-entries__cell'
      },
      {
        Header: t('MEDICAL_RECORD/VALUE'),
        accessor: (entry: DecryptedMedicalRecordEntry) => (
          <Description entry={entry} cryptorWithKeys={cryptorWithKeys} />
        ),
        className: 'c-medical-record-entries__cell h-word-break'
      },
      {
        Header: t('MEDICAL_RECORD/DATE_ADDED_TO_ENTRY'),
        accessor: (entry: DecryptedMedicalRecordEntry) => (
          <EntryDateTime entryDate={entry.created_at} />
        ),
        minWidth: 80,
        className: 'c-medical-record-entries__cell'
      },
      {
        Header: t('MEDICAL_RECORD/ADDED_BY'),
        accessor: (entry: DecryptedMedicalRecordEntry) => (
          <span>{!entry.created_by_doctor ? t('MEDICAL_RECORD/ME') : entry.creator_name}</span>
        ),
        minWidth: 160,
        className: 'c-medical-record-entries__cell h-word-break'
      },
      {
        Header: t('MEDICAL_RECORD/SHARE_STATUS_FOR_ENTRY'),
        accessor: (entry: DecryptedMedicalRecordEntry) => <SentToInstitutionsList entry={entry} />,
        minWidth: 70,
        className: 'c-medical-record-entries__cell'
      },
      {
        id: 'share',
        accessor: (entry: DecryptedMedicalRecordEntry) => (
          <ShareIcon entry={entry} onSendEntry={onSendEntry} />
        ),
        minWidth: 30,
        className: 'c-medical-record-entries__cell c-medical-record-entries__action_icons'
      },
      {
        id: 'expander',
        Cell: ({ row }) => <ExpandIcon onClick={row.toggleRowExpanded} />,
        minWidth: 30,
        className: 'c-medical-record-entries__cell c-medical-record-entries__action_icons'
      },
      {
        id: 'delete',
        accessor: (entry: DecryptedMedicalRecordEntry) => (
          <DeleteIcon entryId={entry.id} onDeleteEntry={onDeleteEntry} />
        ),
        minWidth: 30,
        className: 'c-medical-record-entries__cell c-medical-record-entries__action_icons'
      },
      {
        id: 'mobile-row',
        accessor: (entry: DecryptedMedicalRecordEntry, cryptorWithKeys: CryptorWithKeys) => ({
          entry: entry,
          cryptorWithKeys: cryptorWithKeys
        }),
        Cell: ({ cell, row }) => (
          <EntryRowMobile
            entry={cell.value.entry}
            cryptorWithKeys={cell.value.cryptorWithKeys}
            toggleExpanded={row.toggleRowExpanded}
          />
        ),
        className: 'c-medical-record-entries__cell--mobile'
      }
    ],
    [cryptorWithKeys, onDeleteEntry, onSendEntry]
  )

  const data: DecryptedMedicalRecordEntry[] = useMemo(() => filteredEntries, [filteredEntries])

  const renderRowSubComponent = useCallback(
    ({ row }) => {
      const { original: entry } = row

      return (
        <ExpandedRow
          entry={entry}
          onSendEntry={onSendEntry}
          toggleExpanded={row.toggleRowExpanded}
          cryptorWithKeys={cryptorWithKeys}
          onDeleteEntry={onDeleteEntry}
        />
      )
    },
    [cryptorWithKeys, onDeleteEntry, onSendEntry]
  )

  const isRowDisabled = (entry: DecryptedMedicalRecordEntry): boolean | undefined =>
    entry.data_undecryptable

  return (
    <div className='c-medical-record-entries__container'>
      {entries.length > 0 && (
        <SearchField searchQuery={searchQuery} setSearchQuery={setSearchQuery} />
      )}
      <Table
        columns={columns}
        data={data}
        renderRowSubComponent={renderRowSubComponent}
        wholeRowClickable
        {...{ isRowDisabled }}
      />
    </div>
  )
}
