import styled from 'styled-components'

export type Styles = Partial<{
  borderWidth: string
  flex: string
}>

// constants
// @todo remove usage of colors object and use css vaiables.
export const colors = {
  white: '#ffffff',
  grey1: '#F3F5F7',
  grey2: '#edf0f3',
  grey3: '#1b2128',
  grey4: '#071e3c',
  grey5: '#8093a9',
  grey6: '#DAE0E7',
  grey7: '#f9fafb',
  grey8: '#4e6074',
  grey90: '#e6e6e6',
  grey10: '#cccccc',
  grey11: '#E6F0F7',
  blue1: '#e5f4ff',
  blue2: '#00a3e2',
  blue3: '#0067b2'
}

// styles
const getBorder = ({ borderWidth = '1' }: Styles) => {
  return `border-top: ${borderWidth}px solid ${colors.grey2};`
}

const getFlex = ({ flex = 'initial' }: Styles) => {
  return `flex: ${flex};`
}

export const BaseContent = styled.div`
  border-radius: 16px;
  background-color: ${colors.white};
  display: flex;
  flex: 1;
  overflow: hidden;
`

export const HorizontalRule = styled.div`
  ${getBorder}
  ${getFlex}
`
