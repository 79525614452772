import { Cryptor } from '@samedi/crypto-js'

import { PublicKey, generateSharedEncryptionKey } from 'services/encryption'

export interface EncryptedAttachment {
  id: string
  encrypted_meta_data: string
}

export interface Attributes {
  id: string
  practiceId: string
  practiceName: string
  formId: number
  formTypeId: number
  title: string
  formData: string | null
  sessionKey: string | null
  sentAt: string
  completedAt?: string
  draftSavedAt?: string
  attachmentsEncryptionKeyId?: string
  attachmentsSizeLimit?: number
  attachments?: EncryptedAttachment[]
  hasPrintTemplate?: boolean
  appointmentId?: number | null
  appointmentName?: string | null
  appointmentStartsAt?: string | null
  patientSignature?: string
}

export interface BackendAttributes {
  id: string
  practice_id: string
  practice_name: string
  form_id: number
  form_type_id: number
  form_title: string
  form_data: string | null
  session_key: string | null
  sent_at: string
  completed_at?: string
  draft_saved_at?: string
  attachments_encryption_key_id?: string
  attachments_size_limit: number
  attachments?: EncryptedAttachment[]
  has_print_template?: boolean
  appointment_id: number | null
  appointment_name: string | null
  appointment_starts_at: string | null
}

export default class PatientForm {
  private attributes: Attributes

  constructor(attributes: Attributes) {
    this.attributes = attributes
  }

  getAttributes(): Attributes {
    return this.attributes
  }

  get id(): string {
    return this.attributes.id
  }

  get practiceId(): string {
    return this.attributes.practiceId
  }

  get practiceName(): string {
    return this.attributes.practiceName
  }

  get patientSignature(): string | undefined {
    return this.attributes.patientSignature
  }

  get formData(): string | null {
    return this.attributes.formData
  }

  get formId(): number {
    return this.attributes.formId
  }

  get attachments() {
    return this.attributes.attachments || []
  }

  get attachmentsEncryptionKeyId(): string | undefined {
    return this.attributes.attachmentsEncryptionKeyId
  }

  isEncrypted(): boolean {
    const { sessionKey } = this.getAttributes()

    return sessionKey !== null && sessionKey !== undefined
  }

  isCompleted(): boolean {
    const { completedAt } = this.getAttributes()

    return completedAt !== null && completedAt !== undefined
  }

  async createAttachmentsEncryptionKey(
    cryptor: Cryptor,
    userPublicKey: PublicKey
  ): Promise<string> {
    this.attributes.attachmentsEncryptionKeyId = await generateSharedEncryptionKey(
      cryptor,
      userPublicKey
    )

    return this.attachmentsEncryptionKeyId as string
  }
}
