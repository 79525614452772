import axios from 'axios'
/**
 * Delegates our user event collection infrastructure to platform.
 *
 * @param category Event Category (Videos, Music, Games...)
 * @param action Action (Play, Pause, Duration, Add Playlist, Downloaded, Clicked...)
 * @param name Optional Event name
 * @param value Optional Event value
 */
export function trackEvent(category: string, action: string, name?: string, value?: number) {
  axios.post(
    '/api/monitoring/v1/events.json',
    {
      event: {
        category,
        action,
        name,
        value
      }
    },
    { headers: { 'Content-Type': 'application/json' } }
  )
}
