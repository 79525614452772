import { useQueryClient } from '@tanstack/react-query'
import React, { useCallback, useContext } from 'react'
import { useNavigate, useParams } from 'react-router-dom'

import { CryptorContext } from 'components/features/Cryptor/CryptorContext'

import { ConversationsScreen } from '../components/ConversationsScreen'
import { ContactWithMessage } from '../types'
import { encryptAndSaveMessage } from '../utils/encryptAndSaveMessage'

export type ActiveColumn = 'conversationListColumn' | 'conversationColumn'

export const ConversationsStateContainer: React.FC<{
  contacts: ContactWithMessage[]
}> = ({ contacts }) => {
  const cryptorWithKeys = useContext(CryptorContext)
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const { selected } = useParams<any>()
  const navigate = useNavigate()
  const queryClient = useQueryClient()

  // the activeColumn state is used to determine which column to show on mobile view.
  // (it doesn't have any effect on desktop where both columns are visible at all time)
  const activeColumn: ActiveColumn = !selected ? 'conversationListColumn' : 'conversationColumn'
  const isContactAvailable =
    !!selected && contacts.some((contact) => contact.practice_id === selected)
  const selectedContactId = isContactAvailable ? selected : contacts[0]?.practice_id

  const selectedContact =
    contacts.find((contact) => contact.practice_id === selectedContactId) || undefined

  const handleSelectConversation = useCallback(
    (conversationId: string) => {
      navigate(`/conversations/${conversationId}`)
    },
    [navigate]
  )

  const handleReturnToConversationList = useCallback(() => {
    navigate(`/conversations`)
  }, [navigate])

  const handleSubmitMessage = useCallback(
    async (text: string) => {
      if (!selectedContact) return

      await encryptAndSaveMessage(
        {
          body: text,
          receiving_practice_id: selectedContact.practice_id
        },
        selectedContact.key_pair,
        cryptorWithKeys
      )
      queryClient.invalidateQueries(['messages', selectedContact.practice_id])
      queryClient.invalidateQueries(['contacts'])
    },
    [cryptorWithKeys, queryClient, selectedContact]
  )

  return (
    <ConversationsScreen
      key={selectedContactId}
      contacts={contacts}
      selectedContact={selectedContact}
      handleSelectConversation={handleSelectConversation}
      handleSubmitMessage={handleSubmitMessage}
      handleReturnToConversationList={handleReturnToConversationList}
      activeColumn={activeColumn}
    />
  )
}
