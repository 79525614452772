import { getYear, getMonth, getDate } from 'date-fns'

/**
 * Returns a shortest pattern necessary to represent a date relative to another date.
 *
 * Usually the `referenceDate` will be current time.
 *
 * If both dates are on the same day, only time is shown
 * If both dates are in the same year, only day and month is shown
 * If dates are in different years, full date is shown
 * @param date
 * @param referenceDate
 * @param locale
 */
export function getShortestPattern(date: Date, referenceDate: Date, locale: string) {
  const sameYear = getYear(referenceDate) === getYear(date)
  const sameMonth = sameYear && getMonth(referenceDate) === getMonth(date)
  const sameDate = sameMonth && getDate(referenceDate) === getDate(date)

  if (sameDate && locale === 'de') {
    return "H:mm 'Uhr'"
  } else if (sameDate) {
    return 'H:mm'
  } else if (sameYear) {
    return 'd. MMM'
  } else {
    return 'dd.MM.yyyy'
  }
}
