import { Cryptor } from '@samedi/crypto-js'
import { EncryptedKeyPair as EncryptedKeyPairBody } from '@samedi/crypto-js/cryptor'
import { EncryptedSigningKey, Signer } from '@samedi/crypto-js/signer'
import { generatePWClientHash } from '@samedi/crypto-js/utils'
import { captureException } from '@sentry/react'
import axios from 'axios'

import { authenticityHeaders } from 'util/csrf'

export interface EncryptionKey {
  id: string
  data: string
}

export interface EncryptedKeyPair extends EncryptedKeyPairBody {
  id?: string
}

export interface CryptorWithKeys {
  keyPair: EncryptedKeyPair
  encryptionKey: EncryptionKey
  cryptor: Cryptor
  signer: Promise<Signer>
}

type AccessibleEncryptionKeysData = {
  data: string
}
class EncryptionKeyDataNotFoundError extends Error {
  constructor(message: string) {
    super(message)
    this.name = 'EncryptionKeyDataNotFoundError'
  }
}

export class CryptorService {
  private keyPair?: EncryptedKeyPair
  private encryptionKey?: EncryptionKey
  private signingKey?: EncryptedSigningKey
  private cryptor: Cryptor
  private pseudonym: string

  constructor(
    pseudonym: string,
    keyPair?: EncryptedKeyPair,
    encryptionKey?: EncryptionKey,
    signingKey?: EncryptedSigningKey
  ) {
    this.pseudonym = pseudonym
    this.keyPair = keyPair
    this.encryptionKey = encryptionKey
    this.signingKey = signingKey
    this.cryptor = new Cryptor()
    this.cryptor.fetchAESKey = this.fetchAccessibleKey
  }

  async setUpFromSession(): Promise<CryptorWithKeys | null> {
    if (!this.keyPair || !this.sessionStored()) {
      return null
    }

    await this.loadKeys(window.sessionStorage.pwclienthash)
    return this.cryptorWithKeys()
  }

  async setUpFromPassword(password: string): Promise<CryptorWithKeys | null> {
    const pwclienthash = generatePWClientHash(this.pseudonym, password)

    if (this.keyPair) {
      await this.loadKeys(pwclienthash)
    } else {
      await this.initializeKeys(pwclienthash, password)
    }
    return this.cryptorWithKeys()
  }

  private async cryptorWithKeys(): Promise<CryptorWithKeys> {
    if (!this.keyPair || !this.encryptionKey) {
      throw 'Encryption keys are not loaded'
    }

    const signer = this.cryptor.getSigner(this.postGeneratedSigningKey, this.signingKey)

    return {
      keyPair: this.keyPair,
      encryptionKey: this.encryptionKey,
      cryptor: this.cryptor,
      signer: signer
    }
  }

  private async postGeneratedSigningKey(privateKey: string, publicKey: string) {
    const response = await axios.post<{ key_id: string }>('/signing_keys', {
      signing_key: { private_key_encrypted: privateKey, public_key_jwk: JSON.parse(publicKey) }
    })
    return { keyId: response.data.key_id }
  }

  private async fetchAccessibleKey(key: string): Promise<string> {
    let response

    try {
      response = await axios.get<AccessibleEncryptionKeysData>(
        `/accessible_encryption_keys/${key}.json`,
        { headers: authenticityHeaders() }
      )
    } catch (e) {
      if (axios.isAxiosError(e) && e.response?.status === 404) {
        throw new EncryptionKeyDataNotFoundError(
          `encrytion key data for encryption_key_id ${key} not found`
        )
      } else {
        throw e
      }
    }

    return response.data.data
  }

  private loadKeys(pwclienthash: string) {
    return this.cryptor
      .initialize(this.pseudonym.toLowerCase(), pwclienthash)
      .then(() => this.loadKeyPair())
      .then(() => this.loadDefaultEncryptionKey())
      .then(() => this.storeSession(pwclienthash))
  }

  private initializeKeys(pwclienthash: string, currentPassword: string) {
    return this.cryptor
      .initialize(this.pseudonym.toLowerCase(), pwclienthash)
      .then(() => this.cryptor.generateUserKeyPair())
      .then(() => this.cryptor.exportUserKeyPair(this.pseudonym.toLowerCase(), pwclienthash))
      .then((exportedKeyPair) => {
        return axios
          .post(
            '/key_pair.json',
            { key_pair_data: JSON.stringify(exportedKeyPair), key_pair_password: currentPassword },
            { headers: authenticityHeaders() }
          )
          .then((response) => (this.keyPair = response.data.key_pair))
      })
      .then(() => this.loadKeyPair())
      .then(() => this.loadDefaultEncryptionKey())
      .then(() => this.storeSession(pwclienthash))
  }

  private loadKeyPair() {
    if (!this.keyPair || !this.keyPair.id) {
      throw 'KeyPair is not loaded!'
    }

    const keyPair = this.keyPair as EncryptedKeyPair

    return this.cryptor
      .setUserKeyPair(
        keyPair.public_exponent,
        keyPair.modulus,
        keyPair.private_exponent,
        keyPair.p,
        keyPair.q,
        keyPair.dp,
        keyPair.dq,
        keyPair.qinv
      )
      .then(() =>
        // this says "practice keypair" but in fact is just a naming mistake.
        // for sharing, we need to know our personal key pair under its ID
        // and this is how we get it there.
        this.cryptor.addPracticeKeyPair(
          keyPair.id as string,
          keyPair.public_exponent,
          keyPair.modulus,
          keyPair.private_exponent,
          keyPair.p,
          keyPair.q,
          keyPair.dp,
          keyPair.dq,
          keyPair.qinv
        )
      )
  }

  private async loadDefaultEncryptionKey(): Promise<void> {
    if (this.encryptionKey && this.encryptionKey.id) {
      try {
        return await this.cryptor.addEncryptionKey(this.encryptionKey.id, this.encryptionKey.data)
      } catch (error) {
        console.warn('Failed to load default encryption key, creating a new one', error)
        captureException(error, { tags: { section: 'encryption_key' } })
      }
    }

    const keyId = await this.cryptor.generateEncryptionKey()
    const keyData = await this.cryptor.exportEncryptionKey(keyId, null, null, undefined)

    const response = await axios.post('/encryption_keys.json', {
      encryption_key: { internal: true, data: keyData }
    })
    this.encryptionKey = response.data
    return await this.loadDefaultEncryptionKey()
  }

  private sessionStored() {
    return (
      window.sessionStorage &&
      window.sessionStorage.pwclienthash &&
      window.sessionStorage.userName === this.pseudonym
    )
  }

  private storeSession(pwclienthash: string) {
    if (window.sessionStorage) {
      window.sessionStorage.pwclienthash = pwclienthash
      window.sessionStorage.userName = this.pseudonym
    }
  }
}
