import cx from 'classnames'
import React from 'react'

import BaseButton, { BaseButtonProps } from './BaseButton'

const PlainButton: React.FC<BaseButtonProps> = ({ className, ...props }) => {
  return (
    <BaseButton
      {...props}
      className={cx(
        'text-primary-600 hover:text-primary-800 focus:text-primary-800 active:text-primary-800',
        className
      )}
    />
  )
}

export default PlainButton
