import { Cryptor } from '@samedi/crypto-js'
import axios from 'axios'

import fetchPracticePublicKey from './fetchPracticePublicKey'

export default async function shareKeyWithPractice(
  encryptionKeyId: string,
  practiceId: string,
  cryptor: Cryptor
): Promise<void> {
  const practicePublicKey = await fetchPracticePublicKey(practiceId)
  const data = await cryptor.exportEncryptionKey(
    encryptionKeyId,
    practicePublicKey.public_exponent,
    practicePublicKey.modulus,
    practicePublicKey.id.toString()
  )

  return axios.post(`/encryption_keys/${encryptionKeyId.toString()}/encryption_key_data.json`, {
    encryption_key_data: {
      hashed_practice_id: practiceId,
      data: data
    }
  })
}
