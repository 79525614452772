import escapeHtml from 'escape-html'

import { t } from 'i18n'

import { CryptorWithKeys } from '../../Cryptor/models/CryptorService'
import { MessageFormFields } from '../models/MessageFormFields'
import { encryptAndSave } from '../models/UnsavedMessage'

export const handleFormSubmit = async function (
  values: MessageFormFields,
  cryptorWithKeys: CryptorWithKeys
): Promise<string> {
  if (!values.practiceId) {
    throw new Error(t('messaging.practice_is_required'))
  }

  // We do not currently support any formatting for outgoing messages, even though platform accepts basic
  // formatting. (<b>, <i>, <a href=...>, <br>)

  const messageId = await encryptAndSave(
    {
      receiving_practice_id: values.practiceId,
      body_html: escapeHtml(values.body_text).replace(/\n/g, '<br />'),
      subject: values.subject
    },
    cryptorWithKeys
  )
  return messageId
}
