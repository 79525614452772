import React from 'react'
import Select from 'react-select'
import styled from 'styled-components'

export interface Option {
  value: number
  label: number
}

interface Props {
  value: Option
  options: Option[]
  className: string
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onChange: (arg0: any) => void
}

export const DatePickerSelectField: React.FC<Props> = (props) => {
  return (
    // eslint-disable-next-line @typescript-eslint/no-use-before-define
    <SelectWrapper data-testid='datepicker-year-selector'>
      <Select classNamePrefix='react-datepicker-select' {...props} />
    </SelectWrapper>
  )
}

const SelectWrapper = styled.div`
  width: 25%;
`
