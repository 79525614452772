import { Cryptor } from '@samedi/crypto-js'
import axios from 'axios'

import { CryptorWithKeys } from 'components/features/Cryptor/models/CryptorService'
import { t } from 'i18n'

import { PublicKey, UnsavedEncryptedMessage, UnsavedMessage } from '../types'

async function encryptWithKeyPair(
  cryptor: Cryptor,
  message: string,
  keyPair: PublicKey
): Promise<string> {
  return cryptor.asymmetricEncrypt(
    message,
    keyPair.id ? keyPair.id.toString() : undefined,
    keyPair.public_exponent,
    keyPair.modulus
  )
}

async function encryptUnsavedMessage(
  message: UnsavedMessage,
  recipientKeyPair: PublicKey,
  senderKeyPair: PublicKey,
  cryptor: Cryptor
): Promise<UnsavedEncryptedMessage> {
  return {
    message: {
      receiving_practice_id: message.receiving_practice_id,
      body: 'encrypted message',
      encrypted_body: await encryptWithKeyPair(cryptor, message.body, senderKeyPair)
    },
    recipient_attributes: {
      encrypted_body: await encryptWithKeyPair(cryptor, message.body, recipientKeyPair)
    }
  }
}

export async function encryptAndSaveMessage(
  message: UnsavedMessage,
  recipientKeyPair: PublicKey | null,
  cryptor: CryptorWithKeys
): Promise<void> {
  if (!recipientKeyPair) {
    throw new Error(t('messaging.no_key_pair'))
  }

  const encryptedMessage = await encryptUnsavedMessage(
    message,
    recipientKeyPair,
    cryptor.keyPair,
    cryptor.cryptor
  )

  await axios.post('/api/messages/v2/messages.json', encryptedMessage)
}
