import compact from 'lodash/compact'

import { NextAppointment } from './types'

export const locationAddress = ({ location }: NextAppointment): string => {
  const lines: string[] = [location.address, compact([location.zipCode, location.city]).join(' ')]
  return compact(lines).join(',\n')
}

export const locationMapsQuery = ({ location }: NextAppointment): string =>
  compact([
    location.address ? `${location.address},` : null,
    compact([location.zipCode, location.city]).join(' ')
  ]).join(' ')
