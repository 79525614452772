import { Cryptor } from '@samedi/crypto-js'
import axios from 'axios'

import { saveAs } from 'file-saver'

import { fetchById } from 'components/features/MedicalRecord/services/MedicalRecordEntry'
import { readChunkedEncryptedFileFromURLs, downloadFileFromStream } from 'services/files'

import {
  decryptAttachment,
  decryptMedicalRecordEntry,
  DecryptedMedicalRecordEntry,
  MedicalRecordFileEntry
} from './MedicalRecordEntry'

export async function downloadEncryptedAttachment(
  cryptor: Cryptor,
  entry: DecryptedMedicalRecordEntry
) {
  const entryWithAttachmentData = (await fetchById(entry.id)) as MedicalRecordFileEntry

  const isEntryOnMinio = !!entryWithAttachmentData.file_id

  if (isEntryOnMinio) {
    // eslint-disable-next-line @typescript-eslint/no-use-before-define
    await downloadMinioStoredAttachment(cryptor, entryWithAttachmentData)
  } else {
    // eslint-disable-next-line @typescript-eslint/no-use-before-define
    const blob = await downloadDatabaseStoredAttachment(cryptor, entryWithAttachmentData)

    return saveAs(blob, entry.attachment_filename)
  }
}

async function downloadDatabaseStoredAttachment(
  cryptor: Cryptor,
  entry: MedicalRecordFileEntry
): Promise<Blob> {
  let encryptedAttachmentData = entry.encrypted_attachment_data

  if (!encryptedAttachmentData) {
    encryptedAttachmentData = ''
  }

  return decryptAttachment(encryptedAttachmentData, entry.session_key, cryptor)
}

async function downloadMinioStoredAttachment(
  cryptor: Cryptor,
  entry: MedicalRecordFileEntry
): Promise<void> {
  const baseUrl = '/files'
  const urlParams = `medical_record_entry_id=${entry.id}`
  const { data } = await axios.get(`${baseUrl}/file_chunks?${urlParams}`)
  const urls = data.chunk_ids.map((id: string) => `${baseUrl}/file_chunks/${id}?${urlParams}`)

  const stream = readChunkedEncryptedFileFromURLs(urls, cryptor)

  const decryptedEntry = await decryptMedicalRecordEntry(entry, cryptor)

  downloadFileFromStream(stream, {
    filename: decryptedEntry.attachment_filename || 'unkown-file-name',
    size: decryptedEntry.attachment_size
  })
}
