import React from 'react'
import { Link } from 'react-router-dom'

import { InReplyTo } from './ComposeView'

interface ComposeLinkProps {
  className?: string
  inReplyTo?: InReplyTo
}

export const ComposeLink: React.FC<ComposeLinkProps> = ({ inReplyTo, ...props }) => (
  <Link to='/messages/new' state={{ inReplyTo: inReplyTo }} {...props} />
)
