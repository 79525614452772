import React, { useEffect, useState, useCallback } from 'react'

import { Loading } from 'components/shared/StatePages'

import { CryptorWithKeys } from './models/CryptorService'
import fetchCryptorService from './services/fetchCryptorService'

interface Props {
  passwordForm: React.FC<{ onSubmitPassword: (password: string) => Promise<void> }>
  loadingMessage?: string
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  children: (arg: CryptorWithKeys) => any
}

/**
 * Component responsible to unlock the cryptor in order to
 * be allowed to decrypt sensitive patient data.
 */
export default function CryptorUnlocker({ loadingMessage, passwordForm, children }: Props) {
  const [needsPassword, setNeedsPassword] = useState<boolean>(false)
  const [cryptorWithKeys, setCryptorWithKeys] = useState<CryptorWithKeys | null>(null)

  const setCryptor = (f: CryptorWithKeys | null) => {
    setNeedsPassword(!f)
    setCryptorWithKeys(f || null)
  }

  const unlockWithPassword = async (password: string) => {
    const cryptorService = await fetchCryptorService()
    const cryptor = await cryptorService.setUpFromPassword(password)
    setCryptor(cryptor)
  }

  const setUpCryptor = useCallback(async () => {
    const cryptorService = await fetchCryptorService()
    const cryptor = await cryptorService.setUpFromSession()
    setCryptor(cryptor)
  }, [])

  useEffect(() => {
    setUpCryptor()
  }, [setUpCryptor])

  if (needsPassword) {
    return passwordForm({ onSubmitPassword: unlockWithPassword })
  }
  if (!cryptorWithKeys) {
    return <Loading label={loadingMessage} />
  }

  return children(cryptorWithKeys)
}
