import { faPlus, faReply, faTrashCan, faChevronLeft } from '@fortawesome/pro-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import { useQuery } from '@tanstack/react-query'
import React, { useContext, useState } from 'react'
import { Link, Navigate, useParams } from 'react-router-dom'

import { CryptorContext } from 'components/features/Cryptor/CryptorContext'

import { Loading } from 'components/shared/StatePages/Loading'
import { t } from 'i18n'

import { ConfirmationModal } from '../../ConfirmationModal'
import { ComposeLink } from '../ComposeLink'
import {
  deleteMessage,
  fetchMessage,
  isIncomingMessage,
  setMessageReadStatus
} from '../models/Message'

import { DisplayMessage } from './MessageContent'

export const ReadView: React.FC = function () {
  const params = useParams()
  const messageID = (params && params.messageID) || ''

  const cryptorWithKeys = useContext(CryptorContext)
  const [isDeleting, setIsDeleting] = useState<false | 'confirm' | 'deleting' | 'deleted'>(false)
  const [messageFolder, setMessageFolder] = useState<'inbox' | 'outbox'>('inbox')
  const { data, isLoading } = useQuery(
    ['message', { id: messageID }],
    async () => {
      const message = await fetchMessage(messageID, cryptorWithKeys)
      setMessageFolder(isIncomingMessage(message) ? 'inbox' : 'outbox')
      if (!message.is_read) {
        setMessageReadStatus(message.id, true)
      }
      return message
    },
    { onError: () => setIsDeleting('deleted'), refetchOnWindowFocus: false, refetchInterval: false }
  )

  const onDeleteMessage = async (messageID: string) => {
    setIsDeleting('deleting')
    await deleteMessage(messageID)
    setIsDeleting('deleted')
  }

  if (isDeleting == 'deleted') {
    return <Navigate to={`/messages/${messageFolder}`} />
  }

  if (isLoading || !data) {
    return <Loading label={t('messaging.loading')} />
  }

  return (
    <>
      <div className='f-read-message__actions'>
        <div className='f-read-message__back'>
          {isIncomingMessage(data) ? (
            <Link to='/messages/inbox' className='o-clickable'>
              <FontAwesomeIcon icon={faChevronLeft} className='h-margin-right--8' />
              {t('messages.index.inbox')}
            </Link>
          ) : (
            <Link to='/messages/outbox' className='o-clickable'>
              <FontAwesomeIcon icon={faChevronLeft} className='h-margin-right--8' />
              {t('messages.index.outbox')}
            </Link>
          )}
        </div>
        <div>
          <ComposeLink aria-label='new message' className='o-clickable h-margin-right--24'>
            <FontAwesomeIcon icon={faPlus} />
            <span className='f-read-message__action-label'>{t('messages.new_message')}</span>
          </ComposeLink>
          {isIncomingMessage(data) && (
            <ComposeLink
              inReplyTo={data}
              aria-label='reply'
              className='o-clickable h-margin-right--24'
            >
              <FontAwesomeIcon icon={faReply} />
            </ComposeLink>
          )}
          <FontAwesomeIcon
            aria-label='delete'
            icon={faTrashCan}
            className='o-clickable'
            onClick={() => setIsDeleting('confirm')} // eslint-disable-line react/jsx-no-bind
          />
        </div>
      </div>

      <DisplayMessage message={data} />

      {isDeleting && (
        <ConfirmationModal
          title={t('messages.delete_confirmation.title')}
          confirmButtonLabel={t('messages.delete_confirmation.confirm')}
          onCancel={() => setIsDeleting(false)} // eslint-disable-line react/jsx-no-bind
          onConfirm={() => onDeleteMessage(data?.id)} // eslint-disable-line react/jsx-no-bind
        />
      )}

      {isIncomingMessage(data) && (
        <ComposeLink inReplyTo={data} className='s-button'>
          <FontAwesomeIcon icon={faReply} className='s-button__icon' /> {t('messages.show.reply')}
        </ComposeLink>
      )}
    </>
  )
}
