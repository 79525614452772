import React from 'react'

import BaseButton, { BaseButtonProps } from './BaseButton'

const SecondaryButton: React.FC<BaseButtonProps> = ({ className, ...props }) => {
  return (
    <BaseButton
      className={`bg-primary-100 text-primary-600 hover:bg-light-blue-darker focus:bg-light-blue-darker active:bg-light-blue-darker ${className}`}
      {...props}
    />
  )
}

export default SecondaryButton
