import { faCircleCheck } from '@fortawesome/pro-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React from 'react'

import Modal from 'components/shared/Modal/Modal'
import { t } from 'i18n'

type Props = {
  onClose: () => void
  onReactivation: () => void
}

export const Success: React.FC<Props> = ({ onClose, onReactivation }) => (
  <Modal open onClose={onClose} data-testid='2fa-deactivation-success'>
    <Modal.Title>
      <FontAwesomeIcon icon={faCircleCheck} className='h-5 w-5 pr-2 text-success-800' />
      <span className='text-success-700'>{t('settings.2fa.deactivate_modal.title')}</span>
    </Modal.Title>
    <Modal.Body>
      <p
        className='whitespace-pre-line'
        dangerouslySetInnerHTML={{
          __html: t('settings.2fa.deactivate_modal.content')
        }}
      />
    </Modal.Body>
    <Modal.ButtonGroup>
      <Modal.PrimaryButton onClick={onClose}>
        {t('settings.2fa.modal.continue')}
      </Modal.PrimaryButton>
      <Modal.SecondaryButton onClick={onReactivation}>
        {t('settings.2fa.deactivate_modal.activate_again')}
      </Modal.SecondaryButton>
    </Modal.ButtonGroup>
  </Modal>
)
