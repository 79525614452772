/* eslint-disable @typescript-eslint/no-use-before-define */
import { IconProp } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React from 'react'
import styled from 'styled-components'

// types
export type ChipProps = {
  text: string
  textTestId?: string
  icon: IconProp
}

export const Chip: React.FC<ChipProps> = ({ text, icon, textTestId = undefined }) => {
  return (
    <Container>
      <Text data-testid={textTestId}>{text}</Text>
      <IconContainer>
        <Icon icon={icon} />
      </IconContainer>
    </Container>
  )
}

// styles
const Container = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  flex-shrink: 1;
  border-radius: 9999px;
  background-color: var(--sky-blue);
  padding: 0 12px;
`

const Text = styled.span`
  font-weight: 700;
  font-size: 13px;
  line-height: 16px;
  color: white;
`

const Icon = styled(FontAwesomeIcon)`
  color: white;
`

const IconContainer = styled.div`
  padding-left: 8px;
`
