/**
 * Checks if email is in a valid format and returns true or false
 *
 * @param email string
 */
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function isValidEmail(email?: any): boolean {
  const parts = String(email).trim().split('@')
  if (parts.length !== 2) {
    return false
  }
  if (parts[0].length === 0) {
    return false
  }
  const domainParts = parts[1].split('.')
  if (domainParts.length < 2) {
    return false
  }
  return domainParts.every((part) => part.length > 0)
}
