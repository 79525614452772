import React, { useContext, useState, useEffect } from 'react'

import { CryptorContext } from 'components/features/Cryptor/CryptorContext'
import { DeleteEntryModal } from 'components/features/MedicalRecord/DeleteEntryModal'
import { EntriesTable } from 'components/features/MedicalRecord/EntriesTable'
import {
  DecryptedMedicalRecordEntry,
  PublicKey,
  sendToInstitution,
  deleteEntry
} from 'components/features/MedicalRecord/MedicalRecordEntry'
import { SendEntryModal } from 'components/features/MedicalRecord/SendEntryModal'
import { ShareSuccess } from 'components/features/MedicalRecord/SendEntryModal/InstitutionSelector'

interface Props {
  entries: DecryptedMedicalRecordEntry[]
  onEntriesChange: () => void
}

export function MedicalRecordEntriesContainer({ entries, onEntriesChange }: Props) {
  const [shareSuccess, setShareSuccess] = useState<ShareSuccess>({
    shared: false,
    isSharing: false,
    institutionId: -1,
    entryId: ''
  })
  const cryptorWithKeys = useContext(CryptorContext)
  const [entryToSend, setEntryToSend] = useState<DecryptedMedicalRecordEntry | undefined>(undefined)
  const [entryIdToDelete, setEntryIdToDelete] = useState<string | number | undefined>(undefined)

  const onDeleteEntry = (entryId: string | number): void => {
    setEntryIdToDelete(entryId)
  }

  const onDeleteEntryCancel = (): void => {
    setEntryIdToDelete(undefined)
  }

  const onDeleteConfirm = async (entryId: string | number): Promise<void> => {
    const response = await deleteEntry(entryId)
    if (response.data.success) {
      onEntriesChange()
      setEntryIdToDelete(undefined)
    }
  }

  const onSendEntry = (entry: DecryptedMedicalRecordEntry) => {
    setEntryToSend(entry)
  }

  const onSendEntryModalClose = () => {
    setEntryToSend(undefined)
  }

  useEffect(() => {
    setShareSuccess({ shared: false, isSharing: false, institutionId: -1, entryId: '' })
  }, [entryToSend])

  const onSendEntryModalSendButtonClick = async (
    institutionId: number,
    institutionPublicKey: PublicKey,
    entryId: string | number
  ) => {
    setShareSuccess({ shared: false, isSharing: true, institutionId: -1, entryId: '' })
    try {
      await sendToInstitution(
        cryptorWithKeys.cryptor,
        entryToSend as DecryptedMedicalRecordEntry,
        institutionId,
        institutionPublicKey
      )

      onEntriesChange()
      setShareSuccess({ shared: true, isSharing: false, institutionId, entryId })
    } catch (e) {
      setShareSuccess({ shared: false, isSharing: false, institutionId, entryId })
      throw e
    }
  }

  return (
    <div>
      <EntriesTable
        entries={entries}
        onSendEntry={(entry: DecryptedMedicalRecordEntry) => onSendEntry(entry)} // eslint-disable-line react/jsx-no-bind
        onDeleteEntry={(entryId: string | number) => onDeleteEntry(entryId)} // eslint-disable-line react/jsx-no-bind
      />
      {entryToSend && (
        <SendEntryModal
          entry={entryToSend as DecryptedMedicalRecordEntry}
          onClose={() => onSendEntryModalClose()} // eslint-disable-line react/jsx-no-bind
          // eslint-disable-next-line react/jsx-no-bind
          onSendButtonClick={(
            institutionId: number,
            institutionPublicKey: PublicKey,
            entryId: string | number
          ) => onSendEntryModalSendButtonClick(institutionId, institutionPublicKey, entryId)}
          shareSuccess={shareSuccess}
        />
      )}
      {entryIdToDelete && (
        <DeleteEntryModal
          entryId={entryIdToDelete}
          onCancel={onDeleteEntryCancel} // eslint-disable-line react/jsx-no-bind
          onConfirm={onDeleteConfirm} // eslint-disable-line react/jsx-no-bind
        />
      )}
    </div>
  )
}
