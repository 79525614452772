import { Cryptor } from '@samedi/crypto-js'

import SessionKeyCryptor from './SessionKeyCryptor'
import decryptSessionKey from './decryptSessionKey'

/**
 * Builds a new SessionKeyCryptor using a provided encrypted session key.
 */
export default async function buildSessionKeyCryptor(
  encryptedSessionKey: string,
  cryptor: Cryptor
): Promise<SessionKeyCryptor> {
  const sessionKey = await decryptSessionKey(encryptedSessionKey, cryptor)

  return new SessionKeyCryptor(sessionKey)
}
