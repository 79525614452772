import { faXmark } from '@fortawesome/pro-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React from 'react'

interface Props {
  type: string
  message: string
  onClose: () => void
}

export const NotificationBox: React.FC<Props> = function ({ type, message, onClose }) {
  return (
    <div className={`o-notification o-notification--${type} is-toggle-active`}>
      <div dangerouslySetInnerHTML={{ __html: message }} />
      <div className={`o-notification__close o-notification__close--${type}`}>
        <FontAwesomeIcon icon={faXmark} onClick={onClose} />
      </div>
    </div>
  )
}
