import React from 'react'

import { dateFormat, t } from 'i18n'

import { DecryptedMessageAttributes, isIncomingMessage } from '../models/Message'

import { AttachmentsLine } from './AttachmentsLine'

interface DisplayMessageProps {
  message: DecryptedMessageAttributes
}

/**
 * This component renders full contents of a message.
 */
export const DisplayMessage: React.FC<DisplayMessageProps> = function ({ message }) {
  const subject = message.decrypted_subject.decryptionSucceded
    ? message.decrypted_subject.value
    : t('messages.decryption_error.subject')

  const body = message.decrypted_sanitized_body_html.decryptionSucceded
    ? message.decrypted_sanitized_body_html.value
    : t('messages.decryption_error.body')

  return (
    <div>
      <div>
        <h2>{subject}</h2>
        <div className='f-read-message__from-to'>
          {isIncomingMessage(message) ? message.sender_name : message.receiving_practice_name}
        </div>
        <div className='f-read-message__time'>{dateFormat(message.created_at, 'dateTime')}</div>
      </div>
      <div className='f-read-message__content' dangerouslySetInnerHTML={{ __html: body || '' }} />
      <AttachmentsLine
        messageID={message.id}
        attachments={message.attachments}
        files={message.files}
      />
    </div>
  )
}
