import React from 'react'

export interface RowKeyValueData {
  key: string
  value: string | JSX.Element
}

interface Props {
  data: RowKeyValueData[]
}

export const RowKeyValue: React.FC<Props> = function ({ data }) {
  return (
    <>
      {data.map((obj: RowKeyValueData) => (
        <div key={obj.key} className='c-medical-record-entries__expanded-row-key-values-container'>
          <div className='c-medical-record__expanded-row-keys'>{obj.key}</div>
          <div className='c-medical-record__expanded-row-values'>{obj.value}</div>
        </div>
      ))}
    </>
  )
}
